import {Box} from '@mui/material';
import {arrayOf, node, bool, oneOfType, string} from 'prop-types';

import {FONT_FAMILIES} from '../../../../const';
import MarkdownText from '../../MarkdownText';

const MessageContent = ({isMessageMine, messageContent, formattedContent}) => {
  return (
    <Box
      sx={{
        textAlign: isMessageMine ? 'right' : 'left',
        fontFamily: FONT_FAMILIES.BODY,
        fontSize: 14,
        lineHeight: 1.5,
        whiteSpace: 'pre-line'
      }}
    >
      {isMessageMine ? formattedContent : <MarkdownText message={messageContent} />}
    </Box>
  );
};

MessageContent.propTypes = {
  isMessageMine: bool.isRequired,
  messageContent: string.isRequired,
  formattedContent: oneOfType([node, arrayOf(node)]).isRequired
};

export default MessageContent;
