import {useState} from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {Grid, IconButton} from '@mui/material';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {APP_ROUTES, BREAKPOINTS} from '../../../const';
import useBreakpoint from '../../../hooks/dom/useBreakpoint';
import LogoSvg from '../../../img/company-logo';
import HeaderDesktopIcons from '../HeaderDesktopIcons/HeaderDesktopIcons';
import HeaderMobileIconsMenu from '../HeaderMobileIconsMenu';
import HeaderReportsSelect from '../HeaderReportsSelect';
import UserComponent from '../UserComponent';

const LogoContainer = styled.div`
  img {
    min-height: 50px;
    max-height: 64px;
  }

  top: 0;
  left: 40px;
  display: flex;

  @media screen and (max-width: 900px) {
    left: 12px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 20px;

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${process.env.REACT_APP_MAIN_COLOR};
`;

const SelectAndIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 100%;

  @media screen and (max-width: 360px) {
    max-width: 100px;
  }

  @media screen and (min-width: 360px) and (max-width: 400px) {
    max-width: 160px;
  }

  @media screen and (min-width: 400px) and (max-width: 600px) {
    max-width: 200px;
  }
`;

const MobileMenuContainer = styled.div`
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  padding: 0 10px;
`;

const AppHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [anchorElMobileMenu, setAnchorElMobileMenu] = useState(null);

  const currentBreakpoint = useBreakpoint();

  const isSmallScreen = currentBreakpoint === BREAKPOINTS.xs || currentBreakpoint === BREAKPOINTS.sm;

  return (
    <HeaderContainer>
      <Header>
        {isSmallScreen && (
          <>
            <HeaderMobileIconsMenu
              isOpen={isMobileMenuOpen}
              onClose={() => {
                setIsMobileMenuOpen(false);
                setAnchorElMobileMenu(null);
              }}
              anchorEl={anchorElMobileMenu}
            />
            <MobileMenuContainer>
              <IconButton
                aria-label="menu"
                sx={{background: '#ffffff15'}}
                onClick={e => {
                  setIsMobileMenuOpen(true);
                  setAnchorElMobileMenu(e.currentTarget);
                }}
              >
                <MenuIcon color="white" sx={{fontSize: 20}} />
              </IconButton>
            </MobileMenuContainer>
          </>
        )}

        <Grid container alignItems="center" flex={1} flexWrap="nowrap">
          <LogoContainer>
            <Link to={APP_ROUTES.home}>
              <LogoSvg isSymbol />
            </Link>
          </LogoContainer>
          <SelectAndIconsContainer>
            <HeaderReportsSelect />
            {!isSmallScreen && <HeaderDesktopIcons />}
          </SelectAndIconsContainer>
        </Grid>

        <UserComponent />
      </Header>
    </HeaderContainer>
  );
};

export default AppHeader;
