import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Divider, Grid, Typography} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {bool, string} from 'prop-types';

import {FONT_FAMILIES} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';

const ConnectorsCategoryText = muiStyled(Typography)(({theme}) => ({
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'center',
  font: `normal normal normal 20px/69px ${FONT_FAMILIES.TITLE}`,
  lineHeight: 1.3
}));

const ConnectorsCategoryTitle = ({category, withArrow = false}) => {
  const {collapsedCategories, setCollapsedCategories} = useProjects();

  const isCategoryOpen = !collapsedCategories.includes(category);
  const ArrowIcon = isCategoryOpen ? ExpandMoreIcon : KeyboardArrowRightIcon;

  const handleCategoryOpenOrCollapse = () => {
    if (!isCategoryOpen) {
      setCollapsedCategories(currentCategories => [...currentCategories].filter(c => c !== category));
    } else {
      setCollapsedCategories(currentCategories => [...currentCategories, category]);
    }
  };

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item xs>
        <Divider width="95%" />
      </Grid>
      <Grid item>
        <ConnectorsCategoryText>{category}</ConnectorsCategoryText>
      </Grid>
      {withArrow ? <ArrowIcon onClick={handleCategoryOpenOrCollapse} sx={{cursor: 'pointer', ml: 0.5, mt: 0.5}} fontSize="small" color="primary" /> : null}
      <Grid item xs justifyContent="flex-end" display="flex">
        <Divider width="95%" />
      </Grid>
    </Grid>
  );
};

ConnectorsCategoryTitle.defaultProps = {
  withArrow: false
};

ConnectorsCategoryTitle.propTypes = {
  category: string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  withArrow: bool
};

export default ConnectorsCategoryTitle;
