import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, SNACKBAR_ACTIONS} from '../const';
import ProjectCustomMappingsContext from '../contexts/ProjectCustomMappingsContext';
import useHttp from '../hooks/misc/useHttp';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';

const ProjectsCustomMappingsProvider = ({children}) => {
  const [customizeMappingModalOpen, setCustomizeMappingModalOpen] = useState(false);
  const [projectCustomMappings, setProjectCustomMappings] = useState(new Map());

  const {_post} = useHttp();
  const {showSnackbar, closeSnackbar} = useSnackbar();
  const {selectedReport} = useReport();

  const getProjectCustomMappings = async siren => {
    const url = API_ENDPOINTS.mappings.get;
    try {
      const {response, responseJson: data} = await _post(url, {siren});

      if (response.status === 200) {
        setProjectCustomMappings(prev => new Map(prev).set(siren, data));

        return {
          status: 200,
          success: true
        };
      }

      setProjectCustomMappings(new Map());
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setProjectCustomMappings(new Map());
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const resetProjectCustomMappings = async siren => {
    const url = API_ENDPOINTS.mappings.reset;
    try {
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.RESET_CUSTOM_MAPPING_IN_PROGRESS, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const {response, responseJson: data} = await _post(url, {siren});

      if (response.status === 200) {
        setProjectCustomMappings(prev => new Map(prev).set(siren, data));
        await selectedReport.refresh();
        closeSnackbar(snackbarId);
        showSnackbar(SNACKBAR_ACTIONS.RESET_CUSTOM_MAPPING_SUCCESS);

        return {
          status: 200,
          success: true
        };
      }

      return {
        success: false,
        status: response.status
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e
      };
    }
  };

  const updateProjectCustomMappings = async (siren, newMappings) => {
    const url = API_ENDPOINTS.mappings.update;
    try {
      // Remove the old idx prop and replace by the new order chosen by user
      const formattedData = newMappings.map(({idx, ...rest}, index) => ({
        ...rest,
        mapping_order: index + 1
      }));

      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.UPDATE_CUSTOM_MAPPING_IN_PROGRESS, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const {response, responseJson: data} = await _post(url, {
        siren,
        mapping: formattedData
      });

      closeSnackbar(snackbarId);

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_CUSTOM_MAPPING_SUCCESS);
        selectedReport.refresh();

        return {
          status: 200,
          success: true
        };
      }

      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const useMemoDeps = [customizeMappingModalOpen, projectCustomMappings, setCustomizeMappingModalOpen, getProjectCustomMappings, resetProjectCustomMappings, setProjectCustomMappings];

  const value = useMemo(
    () => ({
      customizeMappingModalOpen,
      setCustomizeMappingModalOpen,
      projectCustomMappings,
      getProjectCustomMappings,
      resetProjectCustomMappings,
      setProjectCustomMappings,
      updateProjectCustomMappings
    }),
    useMemoDeps
  );

  return <ProjectCustomMappingsContext.Provider value={value}>{children}</ProjectCustomMappingsContext.Provider>;
};

ProjectsCustomMappingsProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ProjectsCustomMappingsProvider;
