import {CardActions, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import muiStyled from '@mui/material/styles/styled';
import {bool} from 'prop-types';

import {projectShape} from '../../../proptypes-shapes';
import ActualizeProjectDataButton from './actualize-data-button/ActualizeProjectDataButton';
import DisabledProjectOverlay from './DisabledProjectOverlay';
import InvitedOnProjectIcon from './InvitedOnProjectIcon';
import ProjectLogo from './ProjectLogo';
import ProjectMenuButton from './ProjectMenuButton';
import ProjectNameOrLogo from './ProjectNameOrLogo';
import SeeReportButton from './SeeReportButton';
import UsersAvatarsList from './UsersAvatarsList';

const CardWrapper = muiStyled(Card)(({theme}) => ({
  width: '100%',
  padding: 10,
  paddingBottom: 0,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}));

const CardBody = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  minHeight: 75
}));

const CardFooter = muiStyled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 0,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1)
}));

const CardContent = muiStyled(MuiCardContent)(() => ({
  padding: '0 0 0 12px !important',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}));

const ProjectCardView = ({project, isDisabled, shouldDisplayRefreshIcon, open}) => {
  const {url_logo: urlLogo, users, is_owner: isCreator} = project;
  const theme = useTheme();

  const cardBackgroundColor = open ? `${theme.palette.primary.main}15` : '#fff';
  const cardBorder = open ? `1px solid ${theme.palette.primary.main}80` : 0;

  return (
    <CardWrapper
      elevation={4}
      sx={{
        filter: isDisabled ? 'grayscale(1)' : '',
        background: cardBackgroundColor,
        border: cardBorder,
        position: 'relative'
      }}
    >
      {isDisabled && <DisabledProjectOverlay />}

      <CardBody>
        <ProjectLogo urlLogo={urlLogo} project={project} />
        <CardContent>
          <ProjectNameOrLogo project={project} />
          {isCreator && <UsersAvatarsList users={users} />}
        </CardContent>
        <Grid container justifyContent="flex-end" flexWrap="nowrap" flex={0}>
          {isCreator && shouldDisplayRefreshIcon && <ActualizeProjectDataButton project={project} />}
          {isCreator && <ProjectMenuButton project={project} open={open} isDisabled={isDisabled} />}
          <InvitedOnProjectIcon project={project} />
        </Grid>
      </CardBody>

      <CardFooter>
        <SeeReportButton project={project} />
      </CardFooter>
    </CardWrapper>
  );
};

ProjectCardView.propTypes = {
  project: projectShape.isRequired,
  open: bool.isRequired,
  isDisabled: bool.isRequired,
  shouldDisplayRefreshIcon: bool.isRequired
};

export default ProjectCardView;
