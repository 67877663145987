import {useEffect, useState} from 'react';

import {Grid, Typography} from '@mui/material';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import CustomDimmer from '../components/dimmer/Dimmer';
import Button from '../components/form/buttons/Button/Button';
import {APP_ROUTES, CONNECTORS, FONT_FAMILIES, SPINNER_TYPES, TIIME_OAUTH_CALLBACK_REDIRECT_URI} from '../const';
import useLoading from '../hooks/providers/useLoading';
import useProjects from '../hooks/providers/useProjects';
import {getTiimeRequestStateFromLocalStorage} from '../utils';

const TiimeAuthenticationCallbackPage = () => {
  const {loginToConnector, setIsSuccessfullyLoggedInToConnector, isGeneratingTiimeToken, setIsGeneratingTiimeToken, getConnectors} = useProjects();
  const {setSpinnerType} = useLoading();

  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      setSpinnerType(SPINNER_TYPES.DEFAULT);
      if (location.search && !isGeneratingTiimeToken) {
        getConnectors();

        setIsGeneratingTiimeToken(true);
        const appStateSecretKey = searchParams.get('state');
        const locallyStoredAppStateSecretKey = getTiimeRequestStateFromLocalStorage();

        // State is different that the one sent when redirecting user to Tiime auth0 authentication url and stored locally
        // This is potentially a CSRF intent ( see: https://auth0.com/docs/secure/attack-protection/state-parameters)
        if (appStateSecretKey !== locallyStoredAppStateSecretKey) {
          setSearchParams([]);
          return false;
        }
        const authenticationCode = searchParams.get('code');
        const res = await loginToConnector(CONNECTORS.tiime, {
          tiime_code: authenticationCode,
          tiime_redirect_uri: TIIME_OAUTH_CALLBACK_REDIRECT_URI // TODO see with adrian why we sent this to API ?
        });

        if (!res.success) {
          setError('Une erreur est survenue lors de la récupération des informations depuis Tiime.');
        } else {
          setIsSuccessfullyLoggedInToConnector(true);
          navigate(APP_ROUTES.connections, {
            state: {
              isTiimeProjectCreationModalOpen: true
            }
          });
        }

        setIsGeneratingTiimeToken(false);
      } else {
        setError('Votre requête est invalide.');
      }
    })();
  }, []);

  if (error && !isGeneratingTiimeToken) {
    return (
      <Grid container flex={1} height="100vh" alignItems="center" justifyContent="center" flexDirection="column" spacing={2}>
        <Grid item>
          <Typography textAlign="center" fontSize={24} fontFamily={FONT_FAMILIES.BODY} fontWeight="bold">
            Oups !
          </Typography>
          <Typography textAlign="center" fontSize={18} fontFamily={FONT_FAMILIES.BODY} fontWeight="bold">
            {error}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" component={Link} to={APP_ROUTES.connections}>
            Retourner à mes connexions
          </Button>
        </Grid>
      </Grid>
    );
  }

  return <CustomDimmer isOpen={isGeneratingTiimeToken} spinnerText="Récupération des informations depuis Tiime" />;
};

export default TiimeAuthenticationCallbackPage;
