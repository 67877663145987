import {useEffect} from 'react';

import {ArrowForward} from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import EmbedReportPreview from 'components/embed/EmbedReportPreview';

import Button from '../components/form/buttons/Button/Button';
import {APP_ROUTES, FONT_FAMILIES} from '../const';
import useReport from '../hooks/providers/useReport';
import {makeHumanFriendlyUrl} from '../utils';

const PageContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  padding-bottom: 36px;
  box-sizing: border-box;
`;

const EmbedContainer = styled.div`
  position: relative;
  display: flex;
  flex: 0;
  min-width: calc(50% - 64px);
  min-height: calc(50vh - 100px);
  padding: 0 32px;
  margin-top: 66px;
  height: 100%;

  @media screen and (max-height: 900px) {
    min-height: calc(65vh - 100px);
  }

  @media screen and (max-height: 600px) {
    min-height: calc(80vh - 100px);
  }
`;

const ReportName = styled(Link)`
  position: absolute;
  bottom: -24px;
  display: flex;
  align-items: center;
  font-family: ${FONT_FAMILIES.BODY};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${process.env.REACT_APP_MAIN_COLOR};
  text-decoration: none;

  p {
    margin-right: 8px;
  }

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  font: normal normal 300 40px/48px ${FONT_FAMILIES.TITLE};
`;

const PageSubtitle = styled.h2`
  text-align: center;
  font: normal normal 300 20px/35px ${FONT_FAMILIES.TITLE};
  opacity: 0.7;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 0 24px;
  max-width: 800px;
`;

const Home = () => {
  const reportsContext = useReport();
  const navigate = useNavigate();

  useEffect(() => {
    reportsContext?.setSelectedReport(null);
    reportsContext?.setSelectedReportId(null);
  }, []);

  useEffect(() => {
    if (reportsContext?.reports?.length === 1) {
      const report = reportsContext.reports[0];
      const reportUrl = makeHumanFriendlyUrl(APP_ROUTES.report(report.report_name));
      navigate(reportUrl, {state: {reportId: report.report_id}});
    }
  }, [reportsContext?.reports]);

  return (
    <PageContainer>
      {reportsContext.reports?.length === 0 && (
        <Grid container alignItems="center" justifyContent="center" flex={1} flexDirection="column">
          <PageTitle>Bienvenue !</PageTitle>
          <PageSubtitle>Il semble que vous n'ayez pas encore créé de connexions.</PageSubtitle>
          <Link to={APP_ROUTES.connections}>
            <Button size="large" variant="contained" color="secondary">
              Créer ma première connexion
            </Button>
          </Link>
        </Grid>
      )}
      {(reportsContext?.reports || []).map((report, index) => (
        <EmbedContainer isAlone={reportsContext.reports.length === 1} key={`${report.id}-${index}`}>
          <EmbedReportPreview reportName={report.report_name} reportId={report.report_id} />
          <ReportName onClick={() => localStorage.setItem('reportId', report.report_id)} to={makeHumanFriendlyUrl(APP_ROUTES.report(report.report_name))}>
            <p>{report.report_name}</p>
            <ArrowForward color="primary" fontSize="small" />
          </ReportName>
        </EmbedContainer>
      ))}
    </PageContainer>
  );
};

export default Home;
