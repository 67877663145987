import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import {useLocation} from 'react-router-dom';

import reportModulesIcon from 'img/report-modules-icon.svg';

import useReport from '../../hooks/providers/useReport';

const ReportModulesWidget = () => {
  const {setIsReportModulesSidebarOpen: setIsOpen, selectedReport, isReportLoaded} = useReport();
  const location = useLocation();

  const shouldDisplay = !!(location.pathname.startsWith('/reports') && selectedReport);

  if (shouldDisplay && isReportLoaded) {
    return (
      <Box mb={1}>
        <Fab color="secondary" onClick={() => setIsOpen(true)}>
          <img alt="report modules icon" src={reportModulesIcon} style={{maxWidth: '80%'}} />
        </Fab>
      </Box>
    );
  }

  return null;
};

export default ReportModulesWidget;
