import {useState} from 'react';

import {Menu, MenuItem, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, element, func, node, object, oneOf, oneOfType, shape, string} from 'prop-types';

import {FONT_FAMILIES} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import SearchBar from '../form/SearchBar/SearchBar';

const OverflowedTabsMenu = ({tabs, tabValueProperty, tabLabelProperty, anchorEl, isOpen, onClose}) => {
  const [searchedItemName, setSearchedItemName] = useState('');
  const {setSelectedReportTab} = useProjects();

  const filteredTabs = (tabs || []).filter(t => t[tabLabelProperty]?.toLowerCase().includes(searchedItemName.toLowerCase()));

  return (
    <Menu
      PaperProps={{
        sx: {width: 300}
      }}
      id="overflowed-tabs-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Box mt={1} mb={2} px={1}>
        <SearchBar placeholder="Recherchez un rapport" value={searchedItemName} setValue={setSearchedItemName} />
      </Box>

      {filteredTabs.map(item => {
        const value = item[tabValueProperty];
        const label = item[tabLabelProperty];
        return (
          <MenuItem
            onClick={() => {
              setSelectedReportTab(value);
              onClose();
              setSearchedItemName('');
            }}
            value={value}
          >
            <Typography noWrap fontFamily={FONT_FAMILIES.TITLE} color="primary">
              {label}
            </Typography>
          </MenuItem>
        );
      })}

      {filteredTabs.length === 0 && (
        <Typography fontSize={14} color="text.secondary" ml={1} px={1.5} fontFamily={FONT_FAMILIES.BODY}>
          Aucun rapport ne correspond à vos critères de recherche...
        </Typography>
      )}
    </Menu>
  );
};

OverflowedTabsMenu.defaultProps = {
  anchorEl: null
};

OverflowedTabsMenu.propTypes = {
  tabValueProperty: string.isRequired,
  tabLabelProperty: string.isRequired,
  tabs: shape({}),
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default OverflowedTabsMenu;
