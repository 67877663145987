import {useTheme} from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice
} from 'mui-tiptap';

const EditorMenuControls = () => {
  const theme = useTheme();
  return (
    <MenuControlsContainer>
      <MenuSelectFontFamily
        tooltipTitle="Police"
        emptyLabel="Police"
        options={[
          {label: 'Arial', value: 'Arial'},
          {label: 'Arial Black', value: 'Arial Black'},
          {label: 'Arial Unicode MS', value: 'Arial Unicode MS'},
          // {label: 'Calibri', value: 'Calibri'},
          // {label: 'Cambria', value: 'Cambria'},
          // {label: 'Cambria Math', value: 'Cambria Math'},
          // {label: 'Candara', value: 'Candara'},
          {label: 'Comic Sans MS', value: 'Comic Sans MS'},
          // {label: 'Consolas', value: 'Consolas'},
          // {label: 'Constantia', value: 'Constantia'},
          // {label: 'Corbel', value: 'Corbel'},
          {label: 'Courier New', value: 'Courier New'},
          // {label: 'DIN', value: 'DIN'},
          // {label: 'DIN Light', value: 'DIN Light'},
          {label: 'Georgia', value: 'Georgia'},
          {label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode'},
          {label: 'Segoe UI', value: 'Segoe UI'},
          {label: 'Symbol', value: 'Symbol'},
          {label: 'Tahoma', value: 'Tahoma'},
          {label: 'Times New Roman', value: 'Times New Roman'},
          {label: 'Trebuchet MS', value: 'Trebuchet MS'},
          {label: 'Verdana', value: 'Verdana'}
        ]}
      />
      <MenuDivider />
      <MenuSelectHeading
        labels={{
          paragraph: 'Paragraphe',
          heading1: 'Titre 1',
          heading2: 'Titre 2',
          heading3: 'Titre 3',
          heading4: 'Titre 4',
          heading5: 'Titre 5',
          heading6: 'Titre 6'
        }}
      />
      <MenuDivider />
      <MenuSelectFontSize tooltipTitle="Taille de police" />
      <MenuDivider />
      <MenuButtonBold tooltipLabel="Gras" />
      <MenuButtonItalic tooltipLabel="Italique" />
      <MenuButtonUnderline tooltipLabel="Souligné" />
      <MenuButtonStrikethrough tooltipLabel="Barré" />
      <MenuDivider />
      <MenuButtonTextColor
        tooltipLabel="Couleur du texte"
        defaultTextColor={theme.palette.text.primary}
        swatchColors={[
          {value: '#000000', label: 'Black'},
          {value: '#ffffff', label: 'White'},
          {value: '#888888', label: 'Grey'},
          {value: '#ff0000', label: 'Red'},
          {value: '#ff9900', label: 'Orange'},
          {value: '#ffff00', label: 'Yellow'},
          {value: '#00d000', label: 'Green'},
          {value: '#0000ff', label: 'Blue'}
        ]}
      />
      <MenuButtonHighlightColor
        tooltipLabel="Couleur du surlignage"
        swatchColors={[
          {value: '#595959', label: 'Dark grey'},
          {value: '#dddddd', label: 'Light grey'},
          {value: '#ffa6a6', label: 'Light red'},
          {value: '#ffd699', label: 'Light orange'},
          // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
          {value: '#ffff00', label: 'Yellow'},
          {value: '#99cc99', label: 'Light green'},
          {value: '#90c6ff', label: 'Light blue'},
          {value: '#8085e9', label: 'Light purple'}
        ]}
      />
      <MenuDivider />
      <MenuButtonEditLink tooltipLabel="Lien" />
      <MenuDivider />
      <MenuSelectTextAlign />
      <MenuDivider />
      <MenuButtonOrderedList tooltipLabel="Liste numérotée" />
      <MenuButtonBulletedList tooltipLabel="Liste à puces" />
      <MenuButtonTaskList tooltipLabel="Checklist" />
      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}
      <MenuDivider />
      <MenuButtonBlockquote tooltipLabel="Citation" />
      <MenuDivider />
      <MenuButtonHorizontalRule tooltipLabel="Insérer une ligne horizontale" />
      <MenuButtonAddTable tooltipLabel="Insérer une table" />
      <MenuDivider />
      <MenuButtonRemoveFormatting tooltipLabel="Supprimer la mise en forme" />
      <MenuDivider />
      <MenuButtonUndo tooltipLabel="Annuler" />
      <MenuButtonRedo tooltipLabel="Rétablir" />
    </MenuControlsContainer>
  );
};

export default EditorMenuControls;
