import {Grid, Typography} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import {FONT_FAMILIES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import Button from '../../form/buttons/Button/Button';

const WarningDataPrivacyDimmer = () => {
  const {isWarningAboutDataPrivacyBackdropOpen, setWarningAboutDataPrivacyBackdropOpen} = useChatbot();

  if (!isWarningAboutDataPrivacyBackdropOpen) {
    return null;
  }

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        opacity: '0.95 !important',
        background: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open
    >
      <Grid container flexDirection="column" px={2} alignItems="center">
        <Typography textAlign="center" fontFamily={FONT_FAMILIES.BODY} fontWeight="bold" fontSize={13} color="primary" mt={2} mb={4}>
          Veuillez ne pas divulguer d’informations confidentielles pendant vos conversations. Gardez à l’esprit que des erreurs peuvent survenir, donc il est essentiel de faire preuve de vigilance et
          d’avoir une analyse critique.
        </Typography>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => setWarningAboutDataPrivacyBackdropOpen(false)}>
            J'ai compris
          </Button>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default WarningDataPrivacyDimmer;
