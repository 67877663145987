import {Typography} from '@mui/material';
import {string} from 'prop-types';

import {FONT_FAMILIES} from '../../../const';

const FormUppercaseLabel = ({label}) => (
  <Typography fontSize={11} mb={0.5} mt={2} fontFamily={FONT_FAMILIES.BODY} fontWeight="bold">
    {label.toUpperCase()}
  </Typography>
);

FormUppercaseLabel.propTypes = {
  label: string.isRequired
};

export default FormUppercaseLabel;
