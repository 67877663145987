import {useEffect, useState} from 'react';

import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {styled as muiStyled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

import {MODULES_SIDEBAR_WIDTH, REPORT_MODULES_CATEGORIES} from '../../../const';
import useContact from '../../../hooks/providers/useContact';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button/Button';
import NoMatchingModulesFound from '../NoMatchingModulesFound/NoMatchingModulesFound';
import NoProjectSelectedBackdrop from '../NoProjectSelectedBackdrop/NoProjectSelectedBackdrop';
import ReportModulesCategory from '../ReportModulesCategory/ReportModulesCategory';
import ReportModulesHeader from '../ReportModulesHeader/ReportModulesHeader';
import ReportModulesSearchbar from '../ReportModulesSearchbar/ReportModulesSearchbar';

const DrawerHeader = muiStyled(Grid)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 1),
  position: 'sticky',
  background: 'white !important',
  top: 0,
  zIndex: 9,
  marginBottom: 4
}));

const CloseButtonContainer = muiStyled(Grid)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(1.5),
  position: 'sticky',
  background: 'white !important',
  bottom: 0,
  zIndex: 9,
  margin: '0 auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const ReportModulesSidebar = () => {
  const [categoryExpanded, setCategoryExpanded] = useState(null);

  const {isReportModulesSidebarOpen: open, setIsReportModulesSidebarOpen: setIsOpen, selectedProject} = useReport();
  const {setIsContactFormOpen} = useContact();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/reports')) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          width: {
            xs: '100%',
            md: MODULES_SIDEBAR_WIDTH
          },
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <NoProjectSelectedBackdrop />

      <Grid flex={1} display="flex" flexDirection="column" overflow={!selectedProject ? 'hidden' : 'scroll'}>
        <DrawerHeader container>
          <ReportModulesHeader />
          <ReportModulesSearchbar />
          <NoMatchingModulesFound />
        </DrawerHeader>
        <Grid px={1.5} container flexDirection="column" flex={1}>
          <Box>
            {Object.values(REPORT_MODULES_CATEGORIES).map(c => {
              return <ReportModulesCategory isExpanded={categoryExpanded === c.id} setIsExpanded={setCategoryExpanded} key={c.id} category={c} />;
            })}
          </Box>
          <Box textAlign="center" my={2}>
            <Typography fontSize={13}>Besoin d'un nouvel outil ?</Typography>
            <Typography fontSize={13} sx={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setIsContactFormOpen(true)}>
              Contactez-nous
            </Typography>
          </Box>
        </Grid>

        <CloseButtonContainer container>
          <Button fullWidth size="large" variant="contained" color="secondary" onClick={() => setIsOpen(false)}>
            Fermer
          </Button>
        </CloseButtonContainer>
      </Grid>
    </Drawer>
  );
};

export default ReportModulesSidebar;
