import {useEffect, useRef} from 'react';

// eslint-disable-next-line import/order
import {DialogContent} from '@mui/material';
import 'dayjs/locale/fr';

import {GENERATE_ANALYSIS_FORM_STEPS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import BaseModal from '../../modal/BaseModal';
import GenerateAnalysisForm from './GenerateAnalysisForm';
import ReportPrivacyAlert from './ReportPrivacyAlert';
import SummaryPromptInstructionsModal from './SummaryPromptInstructionsModal';
import SummarySharedDataModal from './SummarySharedDataModal';
import UpdateSummaryForm from './UpdateSummaryForm';

const UpdateReportSummaryModal = () => {
  const {
    getSummariesData,
    setSummariesData,
    reportSummaryModalOpen: isOpen,
    setReportSummaryModalOpen,
    setUserHasConsentedToDataAnalysisByAI,
    setUserHasCheckedDataIsAnonymous,
    setFormStepExpanded
  } = useReportSummary();
  const {selectedProject: project} = useReport();

  const containerRef = useRef(null);

  const closeModal = () => {
    setReportSummaryModalOpen(false);
  };

  const scrollToBottom = () => {
    const dialogContent = containerRef.current;
    if (dialogContent) {
      dialogContent.scrollTop = dialogContent.scrollHeight;
    }
  };

  const resetValues = async () => {
    setFormStepExpanded(GENERATE_ANALYSIS_FORM_STEPS.FIRST_STEP);
    setUserHasConsentedToDataAnalysisByAI(false);
    setUserHasCheckedDataIsAnonymous(false);
  };

  useEffect(() => {
    (async () => {
      await resetValues();
    })();
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      setSummariesData([]);
      await getSummariesData(project.siren);
    })();
  }, [project.siren]);

  return (
    <BaseModal maxWidth="md" canCloseOnBackdropClick={false} onClose={closeModal} open={isOpen} title="Créer une analyse" hasCloseIcon>
      <DialogContent ref={containerRef} sx={{scrollBehavior: 'smooth'}}>
        <GenerateAnalysisForm scrollToBottom={scrollToBottom} />
        <UpdateSummaryForm />
      </DialogContent>

      <ReportPrivacyAlert />

      {/* <SummaryPromptInstructionsModal /> */}

      <SummarySharedDataModal />
    </BaseModal>
  );
};

export default UpdateReportSummaryModal;
