import {Grid, Typography} from '@mui/material';
import styled from 'styled-components';

import {FONT_FAMILIES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import LogoSvg from '../../../img/company-logo';
import Spinner from '../../spinner/Spinner';
import AssistantList from '../AssistantList/AssistantList';
import ChatbotDimmerWarningDataPrivacy from '../ChatbotDimmerWarningDataPrivacy/ChatbotDimmerWarningDataPrivacy';

const LogoContainer = styled.div`
  img {
    max-height: 130px;
    width: 100%;
  }
`;

const NewConversationContent = () => {
  const {selectedAssistant, isWarningAboutDataPrivacyBackdropOpen, currentThreadId, assistants} = useChatbot();

  return (
    <Grid item flex={isWarningAboutDataPrivacyBackdropOpen || !currentThreadId ? 1 : 'none'} display="flex" position="relative" alignItems="center">
      <ChatbotDimmerWarningDataPrivacy />
      {!selectedAssistant && !currentThreadId && (
        <Grid container flexDirection="column" alignItems="center" flex={1}>
          {assistants.length === 0 ? (
            <Spinner size={96} withoutText isLoading />
          ) : (
            <>
              <LogoContainer>
                <LogoSvg isSymbol />
              </LogoContainer>
              <Typography px={1} textAlign="center" fontFamily={FONT_FAMILIES.TITLE} fontSize={22} color="primary" mt={0} mb={0}>
                Avec qui souhaitez-vous discuter ?
              </Typography>
              <AssistantList />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default NewConversationContent;
