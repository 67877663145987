import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';

import {FONT_FAMILIES} from '../../../const';
import useTranscript from '../../../hooks/providers/useTranscript';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import StartRecordingButton from '../StartRecordingButton/StartRecordingButton';

const RecordForm = () => {
  const {isRecording} = useTranscript();

  return (
    <Grid container alignItems="center" flexDirection="column">
      {isRecording ? <AudioRecorder /> : <StartRecordingButton />}
      <Box sx={{maxWidth: 700}} mt={1}>
        <Typography textAlign="center" mt={2} fontFamily={FONT_FAMILIES.BODY} fontSize={12}>
          Pour démarrer la saisie vocale, cliquer sur le bouton <b>Lancer l'enregistrement vocal</b>, puis décrivez votre journée en énoncant le <b>nom du dossier</b>, le <b>temps passé</b> et l’
          <b>action produite</b>. Énoncez vos actions sur les dossiers les unes à la suite des autres. Cliquez sur <b>Terminer l'enregistrement</b> pour valider
        </Typography>
      </Box>
    </Grid>
  );
};

export default RecordForm;
