import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import {PROJECTS_GROUPING_TYPES, PROJECTS_VIEW_TYPES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';
import {getHumanReadableSiren, isProjectDemo} from '../../../utils';

const SpaceBetweenTitleAndUsersList = muiStyled(Box)(({theme}) => ({
  height: theme.spacing(2)
}));

const ProjectSiren = muiStyled(Typography)(({theme}) => ({
  fontSize: 12,
  marginBottom: 1,
  color: theme.palette.grey['400']
}));

const Name = muiStyled(Typography)(() => ({
  fontSize: 18
}));

const ConnectorImage = styled.img`
  height: 20px;
`;

const ProjectName = ({project}) => {
  const {project_name: projectName, siren} = project;
  const {viewType, connectionGroupingType, getConnectorAssociatedToProject} = useProjects();
  const isListViewType = viewType === PROJECTS_VIEW_TYPES.list;

  const getCardTitle = () => {
    if (connectionGroupingType === PROJECTS_GROUPING_TYPES.connection) {
      return projectName;
    }

    return project.connector.toUpperCase();
  };

  const connector = getConnectorAssociatedToProject(project);

  return (
    <Box flex={1}>
      {connector && connectionGroupingType === PROJECTS_GROUPING_TYPES.folder ? (
        <ConnectorImage alt="logo connecteur" src={connector.url_logo} />
      ) : (
        <Name
          component="div"
          variant="h5"
          sx={{
            lineHeight: isListViewType ? 1 : 1.1
          }}
        >
          {getCardTitle()}
        </Name>
      )}

      <Box
        sx={{
          lineHeight: isListViewType ? 1 : 'default',
          marginTop: isListViewType ? 1 : 0,
          marginBottom: isListViewType ? 0 : 1
        }}
      >
        {!isProjectDemo(siren) && (
          <ProjectSiren component="div" variant="h6">
            {getHumanReadableSiren(siren)}
          </ProjectSiren>
        )}
      </Box>

      {isProjectDemo(siren) && !isListViewType && <SpaceBetweenTitleAndUsersList />}
    </Box>
  );
};

ProjectName.propTypes = {
  project: projectShape.isRequired
};

export default ProjectName;
