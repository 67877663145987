import {Typography, useTheme} from '@mui/material';
import {shape, string} from 'prop-types';

import {EMBED_EDITABLE_REPORT_SETTINGS, REPORT_MODULES_IDS, SNACKBAR_ACTIONS} from '../../../const';
import {useIsModuleVisibleOrEnabled} from '../../../hooks/components/report-modules/useIsModuleVisibleOrEnabled/useIsModuleVisibleOrEnabled';
import useProjectCrudModal from '../../../hooks/dom/useProjectCrudModal';
import useProjectsCustomMappings from '../../../hooks/providers/useProjectsCustomMappings';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import useUpload from '../../../hooks/providers/useUpload';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';

const SingleReportModule = ({module}) => {
  const {
    reports,
    selectedReport,
    setIsCreateOrUpdateBudgetFormOpen,
    setIsSpreadsheetModalOpen,
    setIsCreateBudgetForm,
    setIsDeleteBudgetDialogOpen,
    selectedProject: project,
    setAccountantDocumentRequestModalOpen,
    setIsReportInEditionMode,
    setIsReportModulesSidebarOpen,
    setNoProjectsSelectedBackdropOpen,
    alreadyTakenBudgetNames: budgets,
    setSelectedBudget,
    setIsReportExportTypeChoiceModalOpen,
    reportSelectedFoldersSirens
  } = useReport();
  const {showSnackbar} = useSnackbar();
  const {setIsUploadFilesModalOpen, setProjectSelected} = useUpload();
  const {setReportSummaryModalOpen} = useReportSummary();
  const {setCustomizeMappingModalOpen, resetProjectCustomMappings} = useProjectsCustomMappings();
  const {setRightClickedReportTab: setReportToDelete, setDeleteWorkspaceOrReportModalOpen: setDeleteReportModalOpen} = useWorkspaces();

  const {isModuleVisible, isModuleEnabled, setCanRefreshReport} = useIsModuleVisibleOrEnabled();

  const currentReport = reports.find(r => r.report_id === selectedReport?.config?.id);

  const {openModal: openCustomMappingsModal} = useProjectCrudModal({
    setModalOpen: setCustomizeMappingModalOpen,
    project
  });

  const isModuleVisibleForCurrentReport = isModuleVisible(module);

  // eslint-disable-next-line complexity
  const handleClick = async () => {
    if (!isModuleVisibleForCurrentReport) return;

    const hasExactlyOneSirenSelected = reportSelectedFoldersSirens.length === 1;
    if (module.requiresSirenSelected && !hasExactlyOneSirenSelected) {
      setNoProjectsSelectedBackdropOpen(true);
      return;
    }

    switch (module.id) {
      case REPORT_MODULES_IDS.CREATE_BUDGET:
        setIsCreateOrUpdateBudgetFormOpen(true);
        setIsCreateBudgetForm(true);
        break;
      case REPORT_MODULES_IDS.EDIT_BUDGET:
        if (budgets.length === 1) {
          setSelectedBudget(budgets[0]);
        }
        setIsSpreadsheetModalOpen(true);
        break;
      case REPORT_MODULES_IDS.DELETE_BUDGET:
        setIsDeleteBudgetDialogOpen(true);
        break;
      case REPORT_MODULES_IDS.CUSTOMIZE_MAPPINGS:
        openCustomMappingsModal();
        break;
      case REPORT_MODULES_IDS.RESET_CUSTOMIZE_MAPPINGS:
        setIsReportModulesSidebarOpen(false);
        await resetProjectCustomMappings(project.siren);

        break;
      case REPORT_MODULES_IDS.UPDATE_REPORT_SUMMARY:
        setReportSummaryModalOpen(true);
        break;
      case REPORT_MODULES_IDS.SHARE_DOCUMENT_WITH_ACCOUNTANT:
        setAccountantDocumentRequestModalOpen(true);
        break;
      case REPORT_MODULES_IDS.DELETE_REPORT:
        setDeleteReportModalOpen(true);
        setReportToDelete(currentReport);
        // TODO maybe we'll have to redirect or somethign when report is deleted ?
        break;
      case REPORT_MODULES_IDS.EDIT_REPORT:
        await selectedReport.switchMode('edit');
        await selectedReport.updateSettings(EMBED_EDITABLE_REPORT_SETTINGS);
        setIsReportInEditionMode(true);
        setIsReportModulesSidebarOpen(false);
        break;
      case REPORT_MODULES_IDS.EXPORT_REPORT:
        setIsReportExportTypeChoiceModalOpen(true);

        break;
      case REPORT_MODULES_IDS.REFRESH_REPORT:
        await selectedReport.refresh();
        setCanRefreshReport(false);
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_SPREADSHEET_DATA_SUCCESS);
        setTimeout(() => {
          setCanRefreshReport(true);
        }, 15000);
        break;
      case REPORT_MODULES_IDS.UPLOAD_DATA:
        setIsUploadFilesModalOpen(true);
        setProjectSelected(project);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Unknown module id :', module.id);
        break;
    }
  };
  const theme = useTheme();
  const moduleEnabled = isModuleEnabled(module);

  if (isModuleVisibleForCurrentReport) {
    return (
      <Typography
        disabled={!moduleEnabled}
        onClick={moduleEnabled ? handleClick : null}
        py={1.5}
        textAlign="center"
        fontSize={13}
        color="text.primary"
        sx={{
          borderTop: '1px solid #DEDEDE',
          '&:hover': {
            color: moduleEnabled ? theme.palette.secondary.main : 'inherit'
          },
          background: moduleEnabled ? 'inherit' : 'lightgrey',
          cursor: moduleEnabled ? 'pointer' : 'not-allowed'
        }}
      >
        {module.name}
      </Typography>
    );
  }

  return null;
};

SingleReportModule.propTypes = {
  module: shape({
    name: string.isRequired,
    id: string.isRequired
  }).isRequired
};

export default SingleReportModule;
