import {forwardRef, useEffect, useRef} from 'react';

import Box from '@mui/material/Box';
import {RichTextEditor, LinkBubbleMenu, TableBubbleMenu} from 'mui-tiptap';

import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';

// eslint-disable-next-line react/prop-types
const MyRichTextEditor = forwardRef(({content, isStreaming}, ref) => {
  const extensions = useExtensions({
    placeholder: 'Saisir votre analyse ici...'
  });
  const containerRef = useRef(null);

  const editor = ref.current?.editor;
  useEffect(() => {
    if (!editor || editor.isDestroyed) {
      return;
    }
    if (!editor.isFocused || !editor.isEditable) {
      // Use queueMicrotask per https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546854730
      queueMicrotask(() => {
        const currentSelection = editor.state.selection;
        editor.chain().setContent(content).setTextSelection(currentSelection).run();
      });
    }
  }, [content, editor, editor?.isEditable, editor?.isFocused]);

  // Auto scroll to bottom hook, ChatGPT-like behaviour
  useEffect(() => {
    if (!isStreaming) return;

    const scrollToBottom = () => {
      const textarea = containerRef.current?.querySelector('.MuiTiptap-RichTextContent-root');
      if (textarea) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    };

    const timeoutId = setTimeout(scrollToBottom, 0);
    return () => clearTimeout(timeoutId);
  }, [content, isStreaming]);

  return (
    <Box
      ref={containerRef}
      sx={{
        '& .ProseMirror[contenteditable="true"]': {
          height: '100%'
        },
        '& .ProseMirror': {
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            scrollMarginTop: 50,
            margin: '10px 0'
          },
          '& ul, ol': {
            paddingLeft: 3,
            marginBlock: 1.5
          },
          '& li': {
            lineHeight: 1.5
          },
          '& em': {
            fontStyle: 'italic'
          },
          '& p': {
            marginBlockStart: '1em !important',
            marginBlockEnd: '1em !important'
          }
        },
        '& .MuiTiptap-RichTextContent-root': {
          height: 250,
          overflow: 'scroll'
        }
      }}
    >
      <RichTextEditor
        ref={ref}
        extensions={extensions}
        content={content}
        renderControls={() => <EditorMenuControls />}
        RichTextFieldProps={{
          variant: 'outlined',
          error: true
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
    </Box>
  );
});

export default MyRichTextEditor;
