import {Box, Grid, useTheme} from '@mui/material';
import {arrayOf, shape} from 'prop-types';
import styled from 'styled-components';

import {connectorShape} from '../../proptypes-shapes';
import FormUppercaseLabel from '../form/FormUppercaseLabel/FormUppercaseLabel';

const ConnectorRibbon = styled.div`
  position: absolute;
  background: ${props => props.color};
  top: -6px;
  color: white;
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 5px;
  min-width: 40px;
  text-align: center;
`;

const CompatibleEditors = ({editors}) => {
  const theme = useTheme();

  return (
    <Box mb={3}>
      <FormUppercaseLabel label="Fichiers compatibles" />

      <Grid position="relative" justifyContent="center" container flexWrap="nowrap" mt={1}>
        {editors.map(c => (
          <Grid
            key={c.id}
            component={c.url_help ? 'a' : 'div'}
            href={c.url_help}
            target="_blank"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1}
            item
            xs={3}
            sx={{
              pt: 0.5,
              cursor: c.url_help ? 'pointer' : 'inherit',
              height: 55,
              boxShadow: '0px 0px 5px #00000029',
              borderRadius: 2.5
            }}
          >
            {c.is_beta ? (
              <ConnectorRibbon key={c.id} color={theme.palette.warning.main}>
                Beta
              </ConnectorRibbon>
            ) : null}
            <img style={{maxWidth: '90%', maxHeight: '100%'}} src={c.url_logo} alt={`logo ${c.name}`} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

CompatibleEditors.propTypes = {
  // TODO shape of compatible_editors + update connectorShape
  editors: arrayOf(shape({})).isRequired
};

export default CompatibleEditors;
