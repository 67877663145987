import {ExpandMore} from '@mui/icons-material';
import {Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Typography} from '@mui/material';
import {func} from 'prop-types';

import {FONT_FAMILIES, GENERATE_ANALYSIS_FORM_STEPS as FORM_STEPS, SNACKBAR_ACTIONS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import {wait} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import AnalysisConsentsCheckboxes from './AnalysisConsentsCheckboxes';
import SharedDataBlock from './SharedDataBlock';

const GenerateAnalysisForm = ({scrollToBottom}) => {
  const {
    summary,
    instructions,
    shouldCustomizeInstructions,
    companyContext,
    setCompanyContext,
    analysisPurpose,
    setAnalysisPurpose,
    userHasCheckedDataIsAnonymous,
    userHasConsentedToDataAnalysisByAI,
    isGeneratingAnalysis,
    setIsGeneratingAnalysis,
    setSummaryBeforeUpdate,
    filteredDataToShare,
    generateSummary,
    formStepExpanded,
    setFormStepExpanded,
    handleExpandFormStep
  } = useReportSummary();
  const {showSnackbar, closeSnackbar} = useSnackbar();
  const {selectedProject: project} = useReport();

  const expanded = formStepExpanded === FORM_STEPS.FIRST_STEP;
  const submitButtonDisabled = !userHasCheckedDataIsAnonymous || !userHasConsentedToDataAnalysisByAI || isGeneratingAnalysis || filteredDataToShare.length === 0;

  const generateAnalysis = async () => {
    const snackbarId = showSnackbar(SNACKBAR_ACTIONS.GENERATE_ANALYSIS_IN_PROGRESS, {
      severity: 'warning',
      autoHide: false,
      hasSpinner: true
    });
    setFormStepExpanded(FORM_STEPS.SECOND_STEP);
    scrollToBottom();
    await wait(1000);

    setIsGeneratingAnalysis(true);
    setSummaryBeforeUpdate(summary);

    const propertiesForCustomInstructions = {
      summaryInstructions: instructions
    };
    const propertiesForDefaultInstructions = {
      purpose: analysisPurpose,
      context: companyContext
    };

    const body = {
      siren: project.siren,
      data: filteredDataToShare,
      ...(shouldCustomizeInstructions ? propertiesForCustomInstructions : propertiesForDefaultInstructions)
    };

    await generateSummary(body);

    closeSnackbar(snackbarId);
    setIsGeneratingAnalysis(false);
  };

  return (
    <Accordion expanded={expanded} onChange={handleExpandFormStep(FORM_STEPS.FIRST_STEP)}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
        <Typography fontSize={18} fontFamily={FONT_FAMILIES.TITLE}>
          Etape 1 - Choix des paramètres
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container mt={2} mb={3} flexDirection="column">
          {/* <PromptInstructionsBlock /> */}

          <SharedDataBlock />

          <Grid item xs={12} mt={2}>
            <TextField
              variant={shouldCustomizeInstructions ? 'filled' : 'outlined'}
              disabled={shouldCustomizeInstructions}
              InputLabelProps={{shrink: true}}
              sx={{
                mb: 3,
                '& .MuiInputBase-input': {
                  marginTop: 1
                }
              }}
              multiline
              rows={3}
              size="small"
              value={analysisPurpose}
              label="Objectif de l'analyse (Facultatif)"
              color="primary"
              placeholder="Quelles sont les questions spécifiques ou les problèmes que vous souhaitez aborder grâce à cette analyse ?"
              fullWidth
              onChange={event => {
                setAnalysisPurpose(event.target.value);
              }}
            />

            <TextField
              variant={shouldCustomizeInstructions ? 'filled' : 'outlined'}
              disabled={shouldCustomizeInstructions}
              InputLabelProps={{shrink: true}}
              sx={{
                mb: 2,
                '& .MuiInputBase-input': {
                  marginTop: 1
                }
              }}
              multiline
              rows={3}
              size="small"
              value={companyContext}
              label="Contexte de l'entreprise (Facultatif)"
              color="primary"
              placeholder="Quelques informations de base sur votre client (taille, secteur d'activité, etc) peuvent également être utiles pour adapter l'analyse"
              fullWidth
              onChange={event => {
                setCompanyContext(event.target.value);
              }}
            />
          </Grid>

          <AnalysisConsentsCheckboxes />

          <Grid item sx={{margin: '0 auto', mt: 1.5}}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Button
                  disabled={submitButtonDisabled}
                  onClick={generateAnalysis}
                  size="large"
                  variant="contained"
                  sx={{
                    background: process.env.REACT_APP_REPORT_COLOR_2
                  }}
                >
                  Générer l'analyse
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

GenerateAnalysisForm.propTypes = {
  scrollToBottom: func.isRequired
};

export default GenerateAnalysisForm;
