import {useMemo} from 'react';

import {oneOfType, node, func} from 'prop-types';

import BannerContext from '../contexts/BannerContext';
import usePayment from '../hooks/providers/usePayment';
import useProjects from '../hooks/providers/useProjects';

// eslint-disable-next-line complexity
const BannerProvider = ({children}) => {
  const {isGuest, hasTrialEnded, productSubscribed, isTrial, hasBeenSubscribedOnce, numberOfProjectsOwnedByUser, maxNumberOfProjects} = usePayment();
  const projectsContext = useProjects();

  // Business rule : if user is invited on a project, he should not see any banners related to subscription, payment, ect ...
  const shouldDisplayBanners = !isGuest;

  // Banner displayed when user subscription has expired
  const shouldDisplayExpiredSubscriptionBanner =
    shouldDisplayBanners &&
    hasTrialEnded &&
    !productSubscribed &&
    hasBeenSubscribedOnce &&
    (numberOfProjectsOwnedByUser > maxNumberOfProjects || (maxNumberOfProjects === 0 && projectsContext.projects?.length === 0));

  // Banner displayed when user has reached the limit of projects he can create with its subscription
  const shouldDisplayProjectsLimitReachedBanner = shouldDisplayBanners && hasTrialEnded && productSubscribed && hasBeenSubscribedOnce && numberOfProjectsOwnedByUser > maxNumberOfProjects;

  // Banner displayed when user 30-days trial has expired
  const shouldDisplayTrialExpiredBanner =
    shouldDisplayBanners && hasTrialEnded && !hasBeenSubscribedOnce && (numberOfProjectsOwnedByUser > maxNumberOfProjects || (maxNumberOfProjects === 0 && projectsContext.projects?.length === 0));

  // Banner displayed when user 30-days trial is currently in progress
  const shouldDisplayTrialInProgressBanner = shouldDisplayBanners && isTrial;

  const isSomeBannerVisible = shouldDisplayExpiredSubscriptionBanner || shouldDisplayProjectsLimitReachedBanner || shouldDisplayTrialExpiredBanner || shouldDisplayTrialInProgressBanner;

  const useMemoDeps = [shouldDisplayExpiredSubscriptionBanner, shouldDisplayProjectsLimitReachedBanner, shouldDisplayTrialExpiredBanner, shouldDisplayTrialInProgressBanner, isSomeBannerVisible];

  const value = useMemo(
    () => ({
      shouldDisplayExpiredSubscriptionBanner,
      shouldDisplayProjectsLimitReachedBanner,
      shouldDisplayTrialExpiredBanner,
      shouldDisplayTrialInProgressBanner,
      isSomeBannerVisible
    }),
    useMemoDeps
  );

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};

BannerProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default BannerProvider;
