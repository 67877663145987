import {useEffect, useState} from 'react';

import {TextField, Box, Grid, DialogContent, useTheme, Typography} from '@mui/material';

import {BREAKPOINTS, DEFAULT_FILE_FORMAT_ACCEPTED, SNACKBAR_ACTIONS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useAuth from '../../hooks/providers/useAuth';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import useSnackbar from '../../hooks/providers/useSnackbar';
import useUpload from '../../hooks/providers/useUpload';
import {getHumanReadableSiren, getTruncatedString} from '../../utils';
import Alert from '../alert/Alert';
import OpenContactFormButton from '../contact/OpenContactFormButton/OpenContactFormButton';
import Button from '../form/buttons/Button/Button';
import ErrorMessage from '../form/ErrorMessage/ErrorMessage';
import BaseModal from '../modal/BaseModal';
import CompatibleEditors from '../project/CompatibleEditors';
import Spinner from '../spinner/Spinner';
import UploadDropzone from './UploadDropzone';

// eslint-disable-next-line complexity
const UploadDialog = () => {
  const {projectSelected, setProjectSelected, isUploadFilesModalOpen: isOpen, setIsUploadFilesModalOpen: setIsOpen} = useUpload();
  const projectsContext = useProjects();
  const {selectedReport} = useReport();
  const auth = useAuth();
  const {showSnackbar, closeSnackbar} = useSnackbar();

  const theme = useTheme();
  const breakpoint = useBreakpoint();

  const [files, setFiles] = useState(null);

  const closeModal = () => {
    setIsOpen(false);
    projectsContext.setSelectedProject(null);
  };

  const resetState = () => {
    setFiles(null);
    closeModal();
    setProjectSelected(null);
  };

  const handleSubmit = async () => {
    const snackbarId = showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_IN_PROGRESS, {
      severity: 'info',
      autoHide: false
    });
    closeModal();
    const uploadPromisesResults = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      const res = await projectsContext.importDataFile(projectSelected, file);
      uploadPromisesResults.push(res);
    }

    setTimeout(() => {
      const allFilesUploadedSuccessfully = uploadPromisesResults.every(res => res.status === 200);
      closeSnackbar(snackbarId);
      if (allFilesUploadedSuccessfully) {
        showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_SUCCESS);
        if (selectedReport) {
          selectedReport?.refresh();
        }
      } else {
        showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_ERROR, {severity: 'error'});
      }
    }, 2500);
  };

  useEffect(() => {
    (async () => {
      setProjectSelected(null);
      setFiles(null);
      if (auth?.user?.tokenAad && isOpen && projectsContext.projects?.length === 0) {
        await projectsContext.fetchProjects();
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    setProjectSelected(projectSelected || null);
  }, [isOpen]);

  // This hook aims to remove pointerEvents from PowerBi Report iframe while modal is open
  // Otherwise, the pointer events are captures by iframe rather than the react-dropzone and we cannot drag-n-drop files to it.
  useEffect(() => {
    const iframe = document.querySelector('iframe');
    if (isOpen) {
      iframe.style.pointerEvents = 'none';
    } else {
      iframe.style.pointerEvents = 'all';
    }
  }, [isOpen]);

  const getProjectLabel = () => {
    if (!projectSelected) return '';

    const isExtraSmallScreen = breakpoint === BREAKPOINTS.xs;
    const selectedProjectLabel = `${getTruncatedString(projectSelected.project_name, isExtraSmallScreen ? 12 : 40)} (${getHumanReadableSiren(projectSelected.siren)})`;

    return selectedProjectLabel;
  };

  const projectConnector = projectSelected ? projectsContext.getConnectorAssociatedToProject(projectSelected) : [];

  const getAcceptedFileTypes = () => {
    const parentConnector = projectsContext.connectors.find(c => c.id === projectSelected?.schema_name);
    const acceptedFileTypes = {};

    if (parentConnector && parentConnector?.is_manual) {
      projectConnector.accepted_extension.forEach(ext => {
        acceptedFileTypes[ext.mime_type] = [ext.extension];
      });
      return acceptedFileTypes;
    }

    return DEFAULT_FILE_FORMAT_ACCEPTED;
  };

  const projectLabel = getProjectLabel();
  const acceptedFileTypes = getAcceptedFileTypes();

  return (
    <BaseModal onClose={resetState} open={isOpen} title="Importez vos fichiers">
      <DialogContent>
        <Spinner text="Chargement des connexions" isLoading={projectsContext.isLoading} />
        {!projectsContext.isLoading && (
          <Box my={1}>
            <TextField disabled fullWidth id="outlined-select-project" label="Connexion" value={projectLabel} />
          </Box>
        )}

        {projectSelected && !projectsContext.isLoading && (
          <Box pt={2} mb={2}>
            <UploadDropzone error="" onFilesUpload={setFiles} acceptedFileTypes={acceptedFileTypes} />
            <Typography fontSize={13}>
              <a href={projectConnector?.url_help} target="_blank" rel="noreferrer">
                En savoir plus sur le format de fichier
              </a>
            </Typography>
          </Box>
        )}

        {projectSelected && projectConnector ? <CompatibleEditors editors={[projectConnector]} /> : null}

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={5}>
            <Button disabled={!projectSelected || !files || files?.length === 0} onClick={handleSubmit} fullWidth variant="contained" color="secondary" size="large">
              Importer vos données
            </Button>
          </Grid>
          {projectsContext?.importDataFileError !== '' && <ErrorMessage message={projectsContext?.importDataFileError} />}
        </Grid>
      </DialogContent>
      <Alert
        sx={{
          marginTop: theme.spacing(2),
          background: theme.palette.primary.light,
          color: theme.typography.color.main
        }}
      >
        <Typography fontSize={14}>Pour toute question,</Typography>
        <OpenContactFormButton text="Contactez-nous !" />
      </Alert>
    </BaseModal>
  );
};

export default UploadDialog;
