import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {grey} from '@mui/material/colors';
import muiStyled from '@mui/material/styles/styled';
import {string} from 'prop-types';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';

import {FONT_FAMILIES} from '../../../const';
import csvFileIcon from '../../../img/csv-file.svg';

// eslint-disable-next-line react/jsx-props-no-spreading
const Container = muiStyled(({size, ...props}) => <Box {...props} />)(({theme, ...props}) => ({
  fontFamily: FONT_FAMILIES.BODY,
  paddingLeft: theme.spacing(6),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 5,
  padding: '3px 6px 3px 4px',
  display: 'flex',
  alignItems: 'center',
  width: props.size === 'small' ? 'auto' : 300
}));

const IconContainer = styled.div`
  svg {
    width: ${props => (props.size === 'small' ? '16px' : '24px')};
    height: ${props => (props.size === 'small' ? '16px' : '20px')};
  }
`;

const CsvFileMessage = ({filename, size}) => {
  const fontSize = size === 'small' ? 11 : 15;
  const csvTextFontSize = size === 'small' ? 8 : 11;
  const iconHeight = size === 'small' ? 16 : 20;

  return (
    <Container size={size}>
      <IconContainer size={size}>
        <ReactSVG role="img" aria-label="CSV icon" src={csvFileIcon} style={{fill: grey[700], height: iconHeight}} />
      </IconContainer>
      <Box ml={1}>
        <Typography color="text.primary" fontSize={fontSize} lineHeight={1}>
          <b>{filename}</b>
        </Typography>
        <Typography color="text.primary" fontSize={csvTextFontSize} lineHeight={1}>
          CSV
        </Typography>
      </Box>
    </Container>
  );
};

CsvFileMessage.propTypes = {
  filename: string.isRequired,
  size: string.isRequired
};

export default CsvFileMessage;
