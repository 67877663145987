import VisibilityIcon from '@mui/icons-material/Visibility';
import {Tooltip} from '@mui/material';

import {projectShape} from '../../../proptypes-shapes';

const InvitedOnProjectIcon = ({project}) => {
  const {is_owner: isOwner, masked_owner_role: maskedOwnerRole} = project;

  const getTooltipMessage = () => {
    if (maskedOwnerRole) {
      return 'Accès admin suspendu. Lecture seule. Contactez-nous pour en savoir plus';
    }
    return 'Accès invité. Lecture seule';
  };

  const tooltipMessage = getTooltipMessage();

  return !isOwner ? (
    <Tooltip title={tooltipMessage}>
      <VisibilityIcon aria-label="icone invité" color="primary" />
    </Tooltip>
  ) : null;
};

InvitedOnProjectIcon.propTypes = {
  project: projectShape.isRequired
};

export default InvitedOnProjectIcon;
