import {useEffect, useRef, useState} from 'react';

import {ExpandMore} from '@mui/icons-material';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {marked} from 'marked';

import {FONT_FAMILIES, GENERATE_ANALYSIS_FORM_STEPS as FORM_STEPS, REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button/Button';
import Checkbox from '../../form/Checkbox/Checkbox';
import ErrorMessage from '../../form/ErrorMessage/ErrorMessage';
import MyRichTextEditor from '../../form/RichTextEditor/RichTextEditor';

const UpdateSummaryForm = () => {
  const {
    summaryBeforeUpdate,
    handleExpandFormStep,
    summary,
    setSummary,
    streamedSummary,
    shouldReplaceExistingSummary,
    isGeneratingAnalysis,
    setReportSummaryModalOpen,
    updateSummary,
    currentReportPageSummary,
    formStepExpanded
  } = useReportSummary();
  const {selectedReport, reports, reportSelectedPage, selectedProject: project} = useReport();

  const [initialSummary, setInitialSummary] = useState('');
  const [shouldGenerateAudio, setShouldGenerateAudio] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [summaryConvertedToHtml, setSummaryConvertedToHtml] = useState('');

  const rteRef = useRef(null);
  const editor = rteRef.current?.editor;
  const expanded = formStepExpanded === FORM_STEPS.SECOND_STEP;

  const summaryPristine = summary === initialSummary;
  const minimumNumberOrChars = 300;
  const maximumNumberOrChars = 2000;
  const hasSummaryTextError = summary !== '' && !REGULAR_EXPRESSIONS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars).test(summary);
  const submitButtonDisabled = summaryPristine || hasSummaryTextError || !summary || isSubmitting;

  // This hook sets default analysis value from PBI report visual
  useEffect(() => {
    if (currentReportPageSummary) {
      setSummaryConvertedToHtml(currentReportPageSummary);

      if (!editor) return;
      const textContent = editor.getText();
      setSummary(textContent);
      setInitialSummary(textContent);
    }
  }, [expanded, editor, currentReportPageSummary]);

  useEffect(() => {
    if (!editor) return;

    const setContent = () => {
      const textContent = editor.getText();
      const htmlContent = editor.getHTML();
      setSummary(textContent);
      setSummaryConvertedToHtml(htmlContent);
    };

    editor.on('update', setContent);

    return () => {
      editor.off('update', setContent);
    };
  }, [expanded, editor]);

  useEffect(() => {
    (async () => {
      if (streamedSummary.length === 0) return;

      if (shouldReplaceExistingSummary) {
        setSummary(streamedSummary.join(''));
      } else {
        setSummary(`${summaryBeforeUpdate} ${streamedSummary.join('')}`);
      }
    })();
  }, [streamedSummary]);

  useEffect(() => {
    (async () => {
      if (streamedSummary.length > 0) {
        const streamedSummaryToString = streamedSummary.join('');
        const markdownToHtmlSummary = await marked(streamedSummaryToString);
        setSummaryConvertedToHtml(markdownToHtmlSummary);
      }
    })();
  }, [streamedSummary]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setReportSummaryModalOpen(false);
    const reportName = reports?.find(r => r.report_id === selectedReport.config.id).report_name;

    await updateSummary({
      siren: project.siren,
      newSummary: summaryConvertedToHtml,
      withAudio: shouldGenerateAudio,
      reportName,
      pageName: reportSelectedPage.displayName
    });

    setIsSubmitting(false);
  };

  return (
    <Accordion sx={{marginBottom: '12px !important'}} expanded={expanded} onChange={handleExpandFormStep(FORM_STEPS.SECOND_STEP)}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
        <Typography fontSize={18} fontFamily={FONT_FAMILIES.TITLE}>
          Etape 2 - Analyse générée
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MyRichTextEditor ref={rteRef} isStreaming={isGeneratingAnalysis} content={summaryConvertedToHtml} />
        {hasSummaryTextError && !isGeneratingAnalysis ? (
          <Box mb={2}>
            <ErrorMessage message={REGULAR_EXPRESSIONS_ERROR_TEXTS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars)} />
          </Box>
        ) : null}

        <Checkbox label="Générer l'audio de l'analyse" checked={shouldGenerateAudio} onChange={e => setShouldGenerateAudio(e.target.checked)} />
        <Grid container justifyContent="center" mb={1.5}>
          <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={onSubmit}>
            Mettre à jour
          </Button>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UpdateSummaryForm;
