import {useEffect, useRef, useState} from 'react';

import {Fade, Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';

import {FONT_FAMILIES, SPINNER_TYPES} from '../../const';
import useLoading from '../../hooks/providers/useLoading';
import rseSpinnerAnimation from '../../lotties/rseSpinner.json';
import {capitalizeWord} from '../../utils';
import LinkToRse from '../spinner/LinkToRse';
import Spinner from '../spinner/Spinner';

const loadingSentences = [
  'Patientez un instant... comme quand vous êtes le premier à tirer l’eau chaude.',
  'Patientez un instant... comme quand vous faîtes couler le café.',
  'Patientez un instant... comme quand vous surveillez le premier flocon de neige tomber en hiver.',
  'Patientez un instant... comme quand vous concentrez votre regard sur la première étoile du soir.',
  'Patientez un instant... comme quand vous écoutez le crépitement du feu qui prend dans la cheminée.',
  'Patientez un instant... comme quand vous attendez que le gâteau gonfle dans le four.',
  "Patientez un instant... comme quand vous attendez que la peinture sèche sur votre chef-d'œuvre.",
  "Patientez un instant... comme quand vous fixez l'horizon en attendant le coucher du soleil.",
  'Patientez un instant... comme quand vous laissez le vin respirer avant de le servir.',
  'Patientez un instant... comme quand vous anticipez le message de quelqu’un sur votre téléphone.'
];

const SpinnerRse = () => {
  const {completedApiCalls, globalLoading, totalNumberOfApiCalls, spinnerType} = useLoading();
  const lottieRef = useRef();

  const [sentence, setSentence] = useState('');

  const fadeInDuration = 750;
  const loadingPercentage = Math.round((completedApiCalls / totalNumberOfApiCalls) * 100);
  const companyName = capitalizeWord(process.env.REACT_APP_HUMANIZED_NAME);

  useEffect(() => {
    const randomSentence = loadingSentences[Math.floor(Math.random() * 10)];
    setSentence(randomSentence);
  }, []);

  useEffect(() => {
    if (lottieRef.current) {
      const animationInstance = lottieRef.current;
      const progress = completedApiCalls / totalNumberOfApiCalls;
      const frame = animationInstance.getDuration(true) * progress;
      animationInstance.goToAndStop(frame, true);
    }
  }, [completedApiCalls, totalNumberOfApiCalls]);

  if (spinnerType === SPINNER_TYPES.DEFAULT && globalLoading) {
    return (
      <Grid container alignItems="center" sx={{height: '100vh'}}>
        <Spinner withoutText isLoading />
      </Grid>
    );
  }

  return (
    <Fade in={globalLoading} timeout={fadeInDuration}>
      <Grid zIndex={99} position="absolute" container flexDirection="column" alignItems="center" justifyContent="center" flex={1} height="100vh" sx={{background: 'white'}}>
        <Box position="relative" display="flex" justifyContent="center" alignItems="center">
          <Typography fontSize={27} color="#000" fontFamily={FONT_FAMILIES.TITLE} zIndex={1} position="absolute">
            {loadingPercentage > 100 ? 100 : loadingPercentage} %
          </Typography>

          <Lottie autoplay={false} lottieRef={lottieRef} style={{height: 250, width: 250}} animationData={rseSpinnerAnimation} />
        </Box>
        <Typography px={1.5} textAlign="center" fontFamily={FONT_FAMILIES.TITLE} fontSize={20} mb={1.5}>
          " {sentence} "
        </Typography>
        <Typography px={1} textAlign="center" fontFamily={FONT_FAMILIES.BODY} fontSize={15} mt={0.5} mb={1}>
          {companyName} est serverless. Votre attente contribue à notre effort collectif pour la planète.
        </Typography>

        <LinkToRse />
      </Grid>
    </Fade>
  );
};

export default SpinnerRse;
