import {useState} from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useTheme, Grid, Typography, Tooltip, Popper, Paper} from '@mui/material';
import Box from '@mui/material/Box';
import styled from 'styled-components';

import useProjects from '../../../../hooks/providers/useProjects';
import {connectorShape} from '../../../../proptypes-shapes';

const ConnectorContainer = styled.div`
  height: 30px;
  padding: 12px;
  border: ${`1px solid ${process.env.REACT_APP_MAIN_COLOR} `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${props => (props.isActive ? 'pointer' : 'not-allowed')};
  border-radius: 5px;
  position: relative;
  filter: ${props => (props.isActive ? '' : 'grayscale(1)')};
  background: ${props => (props.isActive ? '' : '#f5f5f5')};
`;

const ConnectorImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const CompatibleConnectorImage = styled.img`
  max-height: 30px;
  max-width: 100%;
  margin-bottom: 8px;
`;

const RibbonsContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
`;

const ConnectorRibbon = styled.div`
  position: relative;
  background: ${props => props.color};
  top: -6px;
  color: white;
  padding: 2px 6px;
  margin-bottom: 4px;
  margin-right: 4px;
  font-size: 11px;
  border-radius: 5px;
  min-width: 50px;
`;

const ConnectorBox = ({connector}) => {
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {setConnector} = useProjects();
  const theme = useTheme();

  const {name, url_logo: logo, is_active: isActive} = connector;
  const onClick = isActive ? () => setConnector(connector) : null;

  const getConnectorRibbonsObjects = () => {
    const ribbons = [];
    if (connector.is_local) {
      ribbons.push({text: 'Local', color: 'success'});
    }
    if (connector.is_beta) {
      ribbons.push({text: 'Beta', color: 'warning'});
    }
    return ribbons;
  };

  const renderConnectorsInfoIcon = () => {
    if (!connector.compatible_editors) return null;
    return (
      <div>
        <Popper sx={{maxWidth: 128}} disablePortal anchorEl={anchorEl} open={isInfoIconHovered} placement="left">
          <Paper elevation={2} sx={{p: 1}}>
            <Grid container flexDirection="column" alignItems="center">
              {connector.compatible_editors.map(c => (
                <CompatibleConnectorImage key={c.id} src={c.url_logo} alt={c.name} />
              ))}
            </Grid>
          </Paper>
        </Popper>

        <Box
          onMouseEnter={e => {
            setAnchorEl(e.currentTarget);
            setIsInfoIconHovered(true);
          }}
          onMouseLeave={() => {
            setIsInfoIconHovered(false);
            setAnchorEl(null);
          }}
        >
          <InfoOutlinedIcon color="primary" />
        </Box>
      </div>
    );
  };

  const ribbons = getConnectorRibbonsObjects(connector);

  return (
    <Grid item xs={12} sm={4}>
      <Tooltip title={isActive ? '' : 'Bientôt disponible'} placement="top">
        <ConnectorContainer onClick={onClick} isActive={isActive} role="button">
          {connector.is_manual ? (
            <Grid container flexWrap="nowrap" alignItems="center" justifyContent="center">
              <Typography sx={{fontSize: 13}}>Importer manuellement</Typography>
              <Box
                ml={{
                  xs: 1,
                  sm: 0
                }}
              >
                {renderConnectorsInfoIcon()}
              </Box>
            </Grid>
          ) : (
            <>
              <RibbonsContainer>
                {ribbons.map((ribbon, i) => (
                  <ConnectorRibbon key={i} color={theme.palette[ribbon.color].main}>
                    {ribbon.text}
                  </ConnectorRibbon>
                ))}
              </RibbonsContainer>

              {logo ? <ConnectorImage src={logo} alt={`Logo ${name}`} /> : <Typography sx={{fontSize: 13}}>{name}</Typography>}
            </>
          )}
        </ConnectorContainer>
      </Tooltip>
    </Grid>
  );
};

ConnectorBox.propTypes = {
  connector: connectorShape.isRequired
};

export default ConnectorBox;
