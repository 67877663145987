/* eslint-disable no-case-declarations */

const ACTIONS_TYPES = {
  SET_CONNECTIONS: 'setConnections',
  UPDATE_CONNECTION: 'updateConnection',
  UPDATE_CONNECTION_SUCCESS: 'updateConnectionSuccess',
  UPDATE_CONNECTION_FAIL: 'updateConnectionFail',
  DELETE_CONNECTION: 'deleteConnection',
  DELETE_CONNECTION_SUCCESS: 'deleteConnectionSuccess',
  DELETE_CONNECTION_FAIL: 'deleteConnectionFail',
  SET_FOLDERS_INTEGRATION_DATA: 'setFoldersIntegrationData',
  // State relative to project users
  SET_EMAIL_ERROR: 'SET_EMAIL_ERROR',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
  DELETE_USER: 'DELETE_USER',
  SET_DELETE_USER_SUCCESS: 'SET_DELETE_USER_SUCCESS',
  ADD_USER: 'ADD_USER',
  SET_ADD_USER_SUCCESS: 'SET_ADD_USER_SUCCESS',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  TOGGLE_DELETE_USER_MODAL: 'TOGGLE_DELETE_USER_MODAL',
  SET_USER_TO_DELETE: 'SET_USER_TO_DELETE',
  TOGGLE_PROMOTE_USER_MODAL: 'TOGGLE_PROMOTE_USER_MODAL',
  SET_USER_TO_PROMOTE: 'SET_USER_TO_PROMOTE',
  PROMOTE_OR_DEMOTE_SUCCESS: 'PROMOTE_OR_DEMOTE_SUCCESS'
};

const ActionCreators = dispatch => ({
  setConnections: connections => {
    dispatch({type: ACTIONS_TYPES.SET_CONNECTIONS, payload: connections});
  },
  updateConnection: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION, payload: conn});
  },
  updateConnectionSuccess: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION_SUCCESS, payload: conn});
  },
  updateConnectionFail: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION_FAIL, payload: conn});
  },
  deleteConnection: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION, payload: conn});
  },
  deleteConnectionSuccess: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION_SUCCESS, payload: conn});
  },
  deleteConnectionFail: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION_FAIL, payload: conn});
  },
  setFoldersIntegrationData: ({url, numberOfFolders, connector}) => {
    dispatch({type: ACTIONS_TYPES.SET_FOLDERS_INTEGRATION_DATA, payload: {url, numberOfFolders, connector}});
  },

  // State relative to project users
  setEmailError: errorText => {
    dispatch({type: ACTIONS_TYPES.SET_EMAIL_ERROR, payload: errorText});
  },
  setIsSubmitting: submitting => {
    dispatch({type: ACTIONS_TYPES.SET_IS_SUBMITTING, payload: submitting});
  },
  deleteUser: () => {
    dispatch({type: ACTIONS_TYPES.DELETE_USER});
  },
  setDeletedSuccessfully: ({siren, schema, userId}) => {
    dispatch({type: ACTIONS_TYPES.SET_DELETE_USER_SUCCESS, payload: {siren, schema, userId}});
  },
  addUser: () => {
    dispatch({type: ACTIONS_TYPES.ADD_USER});
  },
  setAddedSuccessfully: ({siren, schema, user}) => {
    dispatch({type: ACTIONS_TYPES.SET_ADD_USER_SUCCESS, payload: {siren, schema, user}});
  },
  setUserInvitedSuccessMessage: message => {
    dispatch({type: ACTIONS_TYPES.INVITE_USER_SUCCESS, payload: message});
  },
  setDeleteUserModalOpen: isOpen => {
    dispatch({type: ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL, payload: isOpen});
  },
  setUserToDelete: userId => {
    dispatch({type: ACTIONS_TYPES.SET_USER_TO_DELETE, payload: userId});
  },
  setPromoteModalOpen: isOpen => {
    dispatch({type: ACTIONS_TYPES.TOGGLE_PROMOTE_USER_MODAL, payload: isOpen});
  },
  setUserToPromote: userId => {
    dispatch({type: ACTIONS_TYPES.SET_USER_TO_PROMOTE, payload: userId});
  },
  promoteOrDemoteUser: ({siren, schema, userId}) => {
    dispatch({type: ACTIONS_TYPES.PROMOTE_OR_DEMOTE_SUCCESS, payload: {siren, schema, userId}});
  }
});

const initialState = {
  newConnectionDataIntegrationJobUrl: null,
  numberOfFoldersToIntegrate: 0,
  projects: [],
  deleting: [],
  updating: [],
  // State relative to project users
  emailError: '',
  isSubmitting: false,
  userInvitedSuccessMessage: '',
  deleteUserModalOpen: false,
  userToDelete: null,
  promoteModalOpen: false,
  userToPromote: null
};

// eslint-disable-next-line default-param-last,complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_CONNECTIONS:
      return {...state, projects: action.payload};

    case ACTIONS_TYPES.UPDATE_CONNECTION:
      return {
        ...state,
        updating: [...state.updating, action.payload.siren + action.payload.schema_name]
      };

    case ACTIONS_TYPES.UPDATE_CONNECTION_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project => (project.siren === action.payload.siren && project.schema_name === action.payload.schema_name ? {...project, ...action.payload} : project)),
        updating: state.updating.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.UPDATE_CONNECTION_FAIL:
      return {
        ...state,
        updating: state.updating.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.DELETE_CONNECTION:
      return {
        ...state,
        deleting: [...state.deleting, action.payload.siren + action.payload.schema_name]
      };

    case ACTIONS_TYPES.DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(project => project.siren !== action.payload.siren || project.schema_name !== action.payload.schema_name),
        deleting: state.deleting.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.DELETE_CONNECTION_FAIL:
      return {
        ...state,
        deleting: state.deleting.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.SET_FOLDERS_INTEGRATION_DATA:
      return {
        ...state,
        newConnectionDataIntegrationJobUrl: action.payload.url,
        numberOfFoldersToIntegrate: action.payload.numberOfFolders,
        connector: action.payload.connector
      };

    case ACTIONS_TYPES.SET_EMAIL_ERROR:
      return {...state, emailError: action.payload};
    case ACTIONS_TYPES.SET_IS_SUBMITTING:
      return {...state, isSubmitting: action.payload};

    case ACTIONS_TYPES.DELETE_USER:
      return {
        ...state,
        isSubmitting: true,
        deleteUserModalOpen: false,
        userInvitedSuccessMessage: ''
      };
    case ACTIONS_TYPES.SET_DELETE_USER_SUCCESS: {
      const {siren, schema, userId} = action.payload;

      return {
        ...state,
        isSubmitting: false,
        projects: state.projects.map(p =>
          p.siren === siren && p.schema_name === schema
            ? {
                ...p,
                users: p.users.filter(user => user.user_id !== userId)
              }
            : p
        )
      };
    }
    case ACTIONS_TYPES.ADD_USER:
      return {
        ...state,
        isSubmitting: true,
        userInvitedSuccessMessage: ''
      };

    case ACTIONS_TYPES.SET_ADD_USER_SUCCESS: {
      const {siren, schema, user} = action.payload;

      return {
        ...state,
        isSubmitting: false,
        projects: state.projects.map(p =>
          p.siren === siren && p.schema_name === schema
            ? {
                ...p,
                users: [...p.users, user]
              }
            : p
        )
      };
    }

    case ACTIONS_TYPES.PROMOTE_OR_DEMOTE_SUCCESS: {
      const {siren, schema, userId} = action.payload;
      return {
        ...state,
        isSubmitting: false,
        projects: state.projects.map(p =>
          p.siren === siren && p.schema_name === schema
            ? {
                ...p,
                users: p.users.map(u => (u.user_id === userId ? {...u, is_owner: !u.is_owner} : u))
              }
            : p
        )
      };
    }

    case ACTIONS_TYPES.INVITE_USER_SUCCESS:
      return {...state, userInvitedSuccessMessage: action.payload};
    case ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL:
      return {...state, deleteUserModalOpen: action.payload};
    case ACTIONS_TYPES.SET_USER_TO_DELETE:
      return {...state, userToDelete: action.payload};
    case ACTIONS_TYPES.TOGGLE_PROMOTE_USER_MODAL:
      return {...state, promoteModalOpen: action.payload};
    case ACTIONS_TYPES.SET_USER_TO_PROMOTE:
      return {...state, userToPromote: action.payload};
    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};

export {reducer, initialState, ActionCreators};
