import {string} from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {APP_ROUTES, FONT_FAMILIES} from '../../const';
import {makeHumanFriendlyUrl} from '../../utils';

const PreviewLink = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${process.env.REACT_APP_MAIN_COLOR};
  transition: 0.3s;
  text-decoration: none;

  :hover {
    opacity: 0.7;
  }
`;
const PreviewReportName = styled.p`
  font-family: ${FONT_FAMILIES.BODY};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
`;

const EmbedReportPreview = ({reportName, reportId}) => {
  const url = makeHumanFriendlyUrl(APP_ROUTES.report(reportName));
  const navigationState = {
    reportId
  };

  return (
    <PreviewLink to={url} state={navigationState}>
      <PreviewReportName>{reportName}</PreviewReportName>
    </PreviewLink>
  );
};
EmbedReportPreview.propTypes = {
  reportName: string.isRequired,
  reportId: string.isRequired
};

export default EmbedReportPreview;
