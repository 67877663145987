import defaultAnimationfile from '../lotties/spinner/drivnSpinnerAnimation.json';

// Default color of Drivn Spinner
const DEFAULT_SPINNER_COLOR = [1, 0.694117647059, 0.250980392157, 1];

/**
 * Function to convert HEXA color to RGBA
 *
 * @param {string} hex - The hexa color
 * @returns {string} The RGBA color
 *
 * @example
 * const rgbaWhite = hexToRgba('#FFFFFF');
 * // Result: 'rgba(0,0,0,1)'
 *
 */
const hexToRgbaArray = hex => {
  let r = 0;
  let g = 0;
  let b = 0;
  let a = 1;
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16) / 255;
    g = parseInt(hex.slice(3, 5), 16) / 255;
    b = parseInt(hex.slice(5, 7), 16) / 255;
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16) / 255;
    g = parseInt(hex.slice(3, 5), 16) / 255;
    b = parseInt(hex.slice(5, 7), 16) / 255;
    a = parseInt(hex.slice(7, 9), 16) / 255;
  }
  return [r, g, b, a];
};

/**
 * Recursively replaces spinner colors in an object with a new color.
 *
 * This function traverses an object and looks for properties that match the
 * criteria of having a key 'c' with an array property 'k'. If the array 'k'
 * matches the default spinner color, it is replaced with a new color.
 *
 * @param {Object} obj - The object to traverse and potentially modify.
 * @param {number[]} newColor - The new color to replace the default color with.
 *   This should be an array of four numbers representing the RGBA color values.
 *
 * @returns {void} This function does not return any value. It modifies the object in place.
 *
 * @example
 * const obj = {
 *   c: { a: 0, k: [0.5, 0.5, 0.5, 1], ix: 4 },
 *   nested: {
 *     c: { a: 0, k: [0, 0, 0, 1], ix: 5 }
 *   }
 * };
 *
 * const newColor = [1, 0, 0, 1]; // Red color
 * const DEFAULT_SPINNER_COLOR = [0, 0, 0, 1]; // Default color (black)
 *
 * replaceSpinnerColors(obj, newColor);
 * console.log(obj);
 * // Output:
 * // {
 * //   c: { a: 0, k: [0.5, 0.5, 0.5, 1], ix: 4 },
 * //   nested: {
 * //     c: { a: 0, k: [1, 0, 0, 1], ix: 5 }
 * //   }
 * // }
 */
const replaceSpinnerColors = (obj, newColor) => {
  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (key === 'c' && Array.isArray(obj[key].k)) {
        const color = obj[key].k;
        if (color.length === 4 && color.every((val, index) => val === DEFAULT_SPINNER_COLOR[index])) {
          // eslint-disable-next-line no-param-reassign
          obj[key].k = newColor;
        }
      } else {
        replaceSpinnerColors(obj[key], newColor);
      }
    }
  }
};

const getCompanySpinnerAnimation = () => {
  try {
    const lottieJson = defaultAnimationfile;

    const rgbColor = hexToRgbaArray(process.env.REACT_APP_SECONDARY_COLOR);
    replaceSpinnerColors(lottieJson, rgbColor);
    return lottieJson;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`error while generating spinner animation`, e);
    return defaultAnimationfile;
  }
};

export default getCompanySpinnerAnimation;
