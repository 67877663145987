import {ListItem, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import {bool} from 'prop-types';
import styled from 'styled-components';

import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';
import ActualizeProjectDataButton from './actualize-data-button/ActualizeProjectDataButton';
import DeleteProjectButton from './delete-button/DeleteProjectButton';
import DisabledProjectOverlay from './DisabledProjectOverlay';
import ImportProjectDataButton from './import-data-button/ImportProjectDataButton';
import InvitedOnProjectIcon from './InvitedOnProjectIcon';
import ManageProjectUsersButton from './manage-users-button/ManageProjectUsersButton';
import ProjectLogo from './ProjectLogo';
import ProjectMenuButton from './ProjectMenuButton';
import ProjectNameOrLogo from './ProjectNameOrLogo';
import SeeReportButton from './SeeReportButton';
import UpdateProjectButton from './update-button/UpdateProjectButton';
import UsersAvatarsList from './UsersAvatarsList';

const Separator = styled.span`
  color: lightgrey;
  margin: 0 16px;
  border-left: 1px solid lightgrey;
  height: 24px;
`;

const ListItemWrapper = muiStyled(ListItem)(() => ({
  minHeight: 50,
  display: 'flex',
  boxShadow: '0px 0px 5px #00000029',
  mb: 1.5,
  alignItems: 'center',
  borderRadius: '5px'
}));

const ListContent = muiStyled(Box)(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const HiddenMdDownContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  display: 'none'
}));

const HiddenMdUpContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  display: 'flex'
}));

// eslint-disable-next-line complexity
const ProjectListView = ({project, open, isDisabled, shouldDisplayRefreshIcon}) => {
  const {url_logo: urlLogo, users, is_owner: isCreator} = project;
  const theme = useTheme();
  const {CONNECTORS_HAVING_UPLOAD_FEATURE} = useProjects();
  const shouldDisplayUploadIconManualProject = CONNECTORS_HAVING_UPLOAD_FEATURE.includes(project.connector);

  const cardBackgroundColor = open ? `${theme.palette.primary.main}15` : '#fff';
  const cardBorder = open ? `1px solid ${theme.palette.primary.main}80` : 0;

  return (
    <ListItemWrapper
      disabled={isDisabled}
      sx={{
        background: cardBackgroundColor,
        border: cardBorder,
        filter: isDisabled ? 'grayscale(1)' : ''
      }}
    >
      {isDisabled && <DisabledProjectOverlay />}

      <ProjectLogo urlLogo={urlLogo} project={project} />
      <Separator />

      <ListContent>
        <ProjectNameOrLogo project={project} />
        {isCreator && (
          <HiddenMdDownContainer>
            <UsersAvatarsList users={users} />
            <Separator />
          </HiddenMdDownContainer>
        )}
      </ListContent>

      {isCreator && (
        <>
          <HiddenMdDownContainer>
            <UpdateProjectButton project={project} />
            <Separator />
            <ManageProjectUsersButton project={project} />
            <Separator />
            {shouldDisplayUploadIconManualProject && (
              <>
                <ImportProjectDataButton project={project} />
                <Separator />
              </>
            )}
          </HiddenMdDownContainer>

          {shouldDisplayRefreshIcon && (
            <>
              <ActualizeProjectDataButton project={project} />
              <Separator />
            </>
          )}

          <HiddenMdDownContainer>
            <DeleteProjectButton project={project} />
            <Separator />
          </HiddenMdDownContainer>
        </>
      )}

      <SeeReportButton project={project} />

      <HiddenMdUpContainer>
        {isCreator && (
          <>
            <Separator />
            <ProjectMenuButton project={project} isDisabled={isDisabled} />
          </>
        )}
      </HiddenMdUpContainer>

      {!isCreator && <Separator />}
      <InvitedOnProjectIcon project={project} />
    </ListItemWrapper>
  );
};

ProjectListView.propTypes = {
  project: projectShape.isRequired,
  isDisabled: bool.isRequired,
  open: bool.isRequired,
  shouldDisplayRefreshIcon: bool.isRequired
};

export default ProjectListView;
