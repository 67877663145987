/* eslint-disable consistent-return */
import {useEffect, useMemo, useState} from 'react';

import {func, node, oneOfType} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_PROJECTS_SCHEMAS, GENERATE_ANALYSIS_FORM_STEPS, SNACKBAR_ACTIONS} from '../const';
import ReportSummaryContext from '../contexts/ReportSummaryContext';
import useHttp from '../hooks/misc/useHttp';
import useAuth from '../hooks/providers/useAuth';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import {formatJsonSummaryAccountingDataToCsv, groupEntriesAmountsByDate} from '../utils';

const ReportSummaryProvider = ({children}) => {
  const [reportAudios, setReportAudios] = useState([]);
  const [isSummaryInstructionsModalOpen, setIsSummaryInstructionsModalOpen] = useState(false);
  const [shouldCustomizeInstructions, setShouldCustomizeInstructions] = useState(false);
  const [shouldShareCustomData, setShouldShareCustomData] = useState(false);
  const [promptModels, setPromptModels] = useState([]);
  const [chosenModel, setChosenModel] = useState(null);
  const [instructions, setInstructions] = useState('');
  const [promptModel, setPromptModel] = useState('');
  const [isSummaryShareDataModalOpen, setIsSummaryShareDataModalOpen] = useState(false);
  const [summariesData, setSummariesData] = useState([]);
  const [streamedSummary, setStreamedSummary] = useState([]);
  const [summariesLoading, setSummariesLoading] = useState(false);
  const [reportSummaryModalOpen, setReportSummaryModalOpen] = useState(false);
  const [currentReportPageSummary, setCurrentReportPageSummary] = useState(null);

  const [dataToShare, setDataToShare] = useState([]);
  const [anonymizedDataToShare, setAnonymizedDataToShare] = useState([]);
  const [filteredDataToShare, setFilteredDataToShare] = useState([]);
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const [checkedThemes, setCheckedThemes] = useState([]);
  const [expandedThemes, setExpandedThemes] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [searchedLabel, setSearchedLabel] = useState('');
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [analysisPurpose, setAnalysisPurpose] = useState('');
  const [companyContext, setCompanyContext] = useState('');
  const [userHasCheckedDataIsAnonymous, setUserHasCheckedDataIsAnonymous] = useState(false);
  const [userHasConsentedToDataAnalysisByAI, setUserHasConsentedToDataAnalysisByAI] = useState(false);
  const [shouldReplaceExistingSummary, setShouldReplaceExistingSummary] = useState(true);
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [summary, setSummary] = useState(false);
  const [summaryBeforeUpdate, setSummaryBeforeUpdate] = useState('');
  const [formStepExpanded, setFormStepExpanded] = useState(GENERATE_ANALYSIS_FORM_STEPS.FIRST_STEP);

  const {_post, _get} = useHttp();
  const {showSnackbar, closeSnackbar} = useSnackbar();
  const {selectedReport} = useReport();
  const auth = useAuth();

  useEffect(() => {
    setDataToShare(summariesData);
    setAnonymizedDataToShare(summariesData);
    setFilteredDataToShare(summariesData);
  }, [summariesData]);

  const updateSummary = async ({newSummary, siren, withAudio, pageName, reportName}) => {
    const url = API_ENDPOINTS.reports.summary.update;
    try {
      const snackbarAction = withAudio ? SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY_WITH_AUDIO : SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY;
      const snackbarId = showSnackbar(snackbarAction, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const {response} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        summary: newSummary,
        with_audio: withAudio,
        report_name: reportName,
        page_name: pageName
      });
      closeSnackbar(snackbarId);

      if (response.status === 200) {
        setCurrentReportPageSummary(newSummary);
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY_SUCCESS);
        selectedReport.refresh();
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const getSummaryPromptModels = async () => {
    const url = API_ENDPOINTS.reports.summary.getModels;
    try {
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setPromptModels(data);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const getSummariesData = async siren => {
    const url = API_ENDPOINTS.reports.summary.getData;
    setSummariesLoading(true);
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion
      });

      if (response.status === 200) {
        setSummariesData(data);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    } finally {
      setSummariesLoading(false);
    }
  };

  const savePromptModel = async (prompt, title) => {
    const url = API_ENDPOINTS.reports.summary.saveModel;
    try {
      const newPrompt = {
        title,
        prompt
      };

      const {response} = await _post(url, newPrompt);

      if (response.status === 200) {
        setPromptModels(currentPrompts => [...currentPrompts, newPrompt]);
        showSnackbar(SNACKBAR_ACTIONS.CREATE_PROMPT_MODEL_SUCCESS);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const deletePromptModel = async title => {
    const url = API_ENDPOINTS.reports.summary.deleteModel;
    try {
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.DELETE_PROMPT_MODEL_IN_PROGRESS, {
        severity: 'info',
        autoHide: false
      });
      const {response, responseJson: data} = await _post(url, {
        title
      });
      closeSnackbar(snackbarId);
      if (response.status === 200) {
        setPromptModels(currentPrompts => [...currentPrompts].filter(p => p.title !== title));
        showSnackbar(SNACKBAR_ACTIONS.DELETE_PROMPT_MODEL_SUCCESS);
        return {success: true, status: 200};
      }

      showSnackbar(data || data.message, {severity: 'error'});

      return {success: false, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const getReportPageAudio = async ({siren, reportName, pageName}) => {
    const url = API_ENDPOINTS.reports.getReportPageAudio;
    try {
      const {response, responseJson: data} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        report_name: reportName,
        page_name: pageName
      });

      if (response.status === 200) {
        const newAudio = {data, siren, report_name: reportName, page_name: pageName};
        setReportAudios(currentAudios => {
          const newReportAudios = [...currentAudios];
          const existingAudioIndex = newReportAudios.findIndex(a => a.siren === siren && a.report_name === reportName && a.page_name === pageName);

          if (existingAudioIndex !== -1) {
            newReportAudios.splice(existingAudioIndex, 1);
          }
          newReportAudios.push(newAudio);
          return newReportAudios;
        });
      }

      return {data, success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const handleSSEMessage = dataString => {
    setStreamedSummary(currentWords => {
      return [...currentWords, dataString];
    });
  };

  const anonymizeData = data => {
    if (shouldShareCustomData) {
      const groupedData = groupEntriesAmountsByDate(data);
      return groupedData.map(entry => {
        const {Compte, Catégorie, ...rest} = entry;
        return rest;
      });
    }
    return data;
  };

  const generateSummary = async ({data, siren, purpose = null, context = null, summaryInstructions = null}) => {
    let formattedData = [...data];

    try {
      const url = API_ENDPOINTS.reports.summary.generateSummary;
      setStreamedSummary([]);

      // Format data as needed : anonymize if user picked anonymized data + format to csv
      if (shouldShareCustomData) {
        formattedData = anonymizeData(data);
      }

      formattedData = formatJsonSummaryAccountingDataToCsv(formattedData);
      const dataWithoutQuotes = formattedData.replace(/"/g, '');
      const formattedBody = {
        siren,
        data: dataWithoutQuotes,
        ...(context ? {context} : {}),
        ...(purpose ? {target: purpose} : {}),
        ...(summaryInstructions ? {instructions: summaryInstructions} : {})
      };

      return fetch(url, {
        method: 'POST',
        headers: {
          policy: process.env.REACT_APP_COMPANY_POLICY,
          env: process.env.REACT_APP_DB_ENV,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.tokenAad}`
        },
        body: JSON.stringify(formattedBody)
      })
        .then(async res => {
          if (res.body && res.body.pipeTo) {
            // eslint-disable-next-line no-undef
            const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
            const readStream = async () => {
              let streamingInProgress = true;
              while (streamingInProgress) {
                // eslint-disable-next-line no-await-in-loop
                const {value, done} = await reader.read();
                if (done) {
                  streamingInProgress = false;
                  break;
                }

                const finalValue = value?.replace(/\\n\\n/g, '\n\n')?.replace(/\\n/g, '\n');
                handleSSEMessage(finalValue);
              }
            };
            await readStream();

            return {
              success: true
            };
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error({error});
          return {
            success: true
          };
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const handleExpandFormStep = panel => (event, isExpanded) => {
    setFormStepExpanded(isExpanded ? panel : false);
  };

  const value = useMemo(
    () => ({
      updateSummary,
      getReportPageAudio,
      reportAudios,
      setReportAudios,
      isSummaryInstructionsModalOpen,
      setIsSummaryInstructionsModalOpen,
      shouldCustomizeInstructions,
      setShouldCustomizeInstructions,
      getSummaryPromptModels,
      promptModels,
      savePromptModel,
      chosenModel,
      setChosenModel,
      instructions,
      setInstructions,
      promptModel,
      setPromptModel,
      deletePromptModel,
      shouldShareCustomData,
      setShouldShareCustomData,
      isSummaryShareDataModalOpen,
      setIsSummaryShareDataModalOpen,
      getSummariesData,
      summariesData,
      dataToShare,
      setDataToShare,
      anonymizedDataToShare,
      setAnonymizedDataToShare,
      generateSummary,
      streamedSummary,
      setSummariesData,
      summariesLoading,
      reportSummaryModalOpen,
      setReportSummaryModalOpen,
      filteredDataToShare,
      setFilteredDataToShare,
      isDataFiltered,
      setIsDataFiltered,
      currentReportPageSummary,
      setCurrentReportPageSummary,
      checkedThemes,
      setCheckedThemes,
      expandedThemes,
      setExpandedThemes,
      checkedCategories,
      setCheckedCategories,
      searchedLabel,
      setSearchedLabel,
      filtersAnchorEl,
      setFiltersAnchorEl,
      startDateRange,
      setStartDateRange,
      endDateRange,
      setEndDateRange,
      analysisPurpose,
      setAnalysisPurpose,
      companyContext,
      setCompanyContext,
      userHasCheckedDataIsAnonymous,
      setUserHasCheckedDataIsAnonymous,
      userHasConsentedToDataAnalysisByAI,
      setUserHasConsentedToDataAnalysisByAI,
      shouldReplaceExistingSummary,
      setShouldReplaceExistingSummary,
      isGeneratingAnalysis,
      setIsGeneratingAnalysis,
      summary,
      setSummary,
      summaryBeforeUpdate,
      setSummaryBeforeUpdate,
      formStepExpanded,
      setFormStepExpanded,
      handleExpandFormStep
    }),
    [
      getReportPageAudio,
      reportAudios,
      setReportAudios,
      isSummaryInstructionsModalOpen,
      setIsSummaryInstructionsModalOpen,
      shouldCustomizeInstructions,
      setShouldCustomizeInstructions,
      getSummaryPromptModels,
      promptModels,
      savePromptModel,
      chosenModel,
      setChosenModel,
      instructions,
      setInstructions,
      promptModel,
      setPromptModel,
      deletePromptModel,
      shouldShareCustomData,
      setShouldShareCustomData,
      isSummaryShareDataModalOpen,
      setIsSummaryShareDataModalOpen,
      getSummariesData,
      summariesData,
      dataToShare,
      setDataToShare,
      anonymizedDataToShare,
      setAnonymizedDataToShare,
      generateSummary,
      streamedSummary,
      setSummariesData,
      summariesLoading,
      reportSummaryModalOpen,
      setReportSummaryModalOpen,
      filteredDataToShare,
      setFilteredDataToShare,
      isDataFiltered,
      setIsDataFiltered,
      currentReportPageSummary,
      setCurrentReportPageSummary,
      checkedThemes,
      setCheckedThemes,
      expandedThemes,
      setExpandedThemes,
      checkedCategories,
      setCheckedCategories,
      searchedLabel,
      setSearchedLabel,
      filtersAnchorEl,
      setFiltersAnchorEl,
      startDateRange,
      setStartDateRange,
      endDateRange,
      setEndDateRange,
      analysisPurpose,
      setAnalysisPurpose,
      companyContext,
      setCompanyContext,
      userHasCheckedDataIsAnonymous,
      setUserHasCheckedDataIsAnonymous,
      userHasConsentedToDataAnalysisByAI,
      setUserHasConsentedToDataAnalysisByAI,
      shouldReplaceExistingSummary,
      setShouldReplaceExistingSummary,
      isGeneratingAnalysis,
      setIsGeneratingAnalysis,
      summary,
      setSummary,
      summaryBeforeUpdate,
      setSummaryBeforeUpdate,
      formStepExpanded,
      setFormStepExpanded,
      handleExpandFormStep
    ]
  );

  return <ReportSummaryContext.Provider value={value}>{children}</ReportSummaryContext.Provider>;
};

ReportSummaryProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ReportSummaryProvider;
