import {Tooltip} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import muiStyled from '@mui/material/styles/styled';
import {arrayOf} from 'prop-types';
import styled from 'styled-components';

import {userShape} from '../../../proptypes-shapes';

const ProjectUserAvatar = muiStyled(Avatar)(() => ({
  width: 28,
  height: 28,
  fontSize: 13
}));

const ExtraUsersTooltipTitle = styled.div`
  white-space: pre-line;
`;

const UsersAvatarsList = ({users}) => {
  return (
    <AvatarGroup max={4}>
      {/* We only show the first 3 avatars as we want to customize the display of the last one */}
      {users.slice(0, 3).map(({username}, index) => (
        <Tooltip title={username} key={`${username}${index}`}>
          <ProjectUserAvatar alt={username}>{username?.substring(0, 1).toUpperCase()}</ProjectUserAvatar>
        </Tooltip>
      ))}

      {/*
             If there is more than 3 users, the last avatar shows the additional number, but it cannot be customized in MUI
             see for more information: https://github.com/mui/material-ui/issues/23849
             So we do this hack to customize the last item (we want to show the number of additionals users + their emails)
              */}
      {users.length > 3 && (
        <Tooltip
          title={
            <ExtraUsersTooltipTitle>
              {users
                .map(u => u.username)
                .slice(3)
                .join('\n')}
            </ExtraUsersTooltipTitle>
          }
        >
          <ProjectUserAvatar alt="additional users">+{users.length - 3}</ProjectUserAvatar>
        </Tooltip>
      )}
    </AvatarGroup>
  );
};

UsersAvatarsList.propTypes = {
  users: arrayOf(userShape).isRequired
};

export default UsersAvatarsList;
