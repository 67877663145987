import Box from '@mui/material/Box';
import {string} from 'prop-types';

import {FONT_FAMILIES} from '../../../const';

const AssistantAnswerState = ({stateText}) => {
  return (
    <Box
      component="span"
      ml={1}
      sx={{
        fontSize: 14,
        fontFamily: FONT_FAMILIES.BODY,
        color: 'text.primary',
        fontStyle: 'italic'
      }}
    >
      {stateText} ...
    </Box>
  );
};

AssistantAnswerState.propTypes = {
  stateText: string.isRequired
};

export default AssistantAnswerState;
