import {TextField} from '@mui/material';
import {bool, func} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
// [WARN] Do not shorten this import . It will create a circular dependency issue
import {getHumanReadableSiren} from '../../../utils/data-formatting';

const SirenTextField = ({disabled, onChange, onKeyDown}) => {
  const defaultOnKeyDown = async e => {
    e.stopPropagation(); // see : https://github.com/mui/material-ui/issues/19116
  };

  const {connector, createProjectSirenError, siren, isSirenValid, isSirenAlreadyTakenForConnector} = useProjects();

  const isSirenAlreadyTaken = isSirenAlreadyTakenForConnector(siren, connector);
  const getErrorText = () => {
    if (siren && !isSirenValid) return 'Siren invalide';
    if (createProjectSirenError) return createProjectSirenError;
    if (isSirenAlreadyTaken) return 'Une connexion existe déjà pour ce siren.';
    return '';
  };

  const errorText = getErrorText();
  const hasError = (siren !== '' && !isSirenValid) || createProjectSirenError !== '' || isSirenAlreadyTaken;

  return (
    <TextField
      disabled={disabled}
      required
      error={hasError}
      helperText={errorText}
      inputProps={{maxLength: 11, minLength: 11}}
      size="small"
      value={getHumanReadableSiren(siren).trim()}
      label="Siren"
      color="primary"
      fullWidth
      sx={{mb: 2}}
      onChange={onChange}
      onKeyDown={onKeyDown || defaultOnKeyDown}
    />
  );
};

SirenTextField.defaultProps = {
  onKeyDown: null
};

SirenTextField.propTypes = {
  disabled: bool.isRequired,
  onChange: func.isRequired,
  onKeyDown: func
};

export default SirenTextField;
