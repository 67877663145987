import {Typography} from '@mui/material';
import Box from '@mui/material/Box';

import {FONT_FAMILIES, REPORT_MODULES_CATEGORIES} from '../../../const';
import useReport from '../../../hooks/providers/useReport';

const NoMatchingModulesFound = () => {
  const {searchedModule} = useReport();

  const modulesFoundMatchingSearchedModule = Object.values(REPORT_MODULES_CATEGORIES).some(c => {
    return c.modules.some(m => m.name.toLowerCase().includes(searchedModule.toLowerCase()));
  });

  if (modulesFoundMatchingSearchedModule) return null;

  return (
    <Box px={1} mb={1}>
      <Typography fontFamily={FONT_FAMILIES.BODY} fontWeight="bold" fontSize={15} color="text.secondary">
        Aucun module ne correspond à votre recherche...
      </Typography>
    </Box>
  );
};

NoMatchingModulesFound.propTypes = {};

export default NoMatchingModulesFound;
