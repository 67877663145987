import {PROJECTS_VIEW_TYPES} from '../../../const';
import usePayment from '../../../hooks/providers/usePayment';
import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';
import ProjectCardView from './ProjectCardView';
import ProjectListView from './ProjectListView';

const Project = ({project}) => {
  const {userHasEndedTrialAndIsOwnerOfProjects} = usePayment();
  const {CONNECTORS_WITHOUT_ACTUALIZE_DATA_BUTTON, isProjectCurrentlyDeleting, projectCardMenuAnchorEl, selectedProject, viewType} = useProjects();

  const isProjectCardHighlighted = selectedProject?.siren === project.siren && selectedProject?.schema_name === project.schema_name;
  const open = viewType === PROJECTS_VIEW_TYPES.card ? Boolean(projectCardMenuAnchorEl && isProjectCardHighlighted) : isProjectCardHighlighted;
  const shouldDisplayRefreshIcon = !CONNECTORS_WITHOUT_ACTUALIZE_DATA_BUTTON.includes(project.connector);
  const isDisabled = userHasEndedTrialAndIsOwnerOfProjects || isProjectCurrentlyDeleting(project);

  if (viewType === PROJECTS_VIEW_TYPES.card) {
    return <ProjectCardView project={project} isDisabled={isDisabled} open={open} shouldDisplayRefreshIcon={shouldDisplayRefreshIcon} />;
  }

  return <ProjectListView project={project} isDisabled={isDisabled} open={open} shouldDisplayRefreshIcon={shouldDisplayRefreshIcon} />;
};

Project.propTypes = {
  project: projectShape.isRequired
};

export default Project;
