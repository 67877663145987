import {useState} from 'react';

import {FolderOpen} from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Paper, useTheme} from '@mui/material';
import {number, string} from 'prop-types';
import styled from 'styled-components';

import {PROJECTS_VIEW_TYPES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';

const ConnectorIconContainer = styled.div`
  background: white;
  bottom: 0;
  left: 0;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80%;
    height: auto;
    max-height: 80%;
  }
`;

// eslint-disable-next-line complexity
const ProjectLogo = ({urlLogo, project}) => {
  const {viewType, getConnectorAssociatedToProject} = useProjects();

  const size = viewType === PROJECTS_VIEW_TYPES.card ? 64 : 32;

  const [projectImageFound, setProjectImageFound] = useState(false);
  const [projectImageLoaded, setProjectImageLoaded] = useState(false);

  const theme = useTheme();
  const thumbnailBackgroundColor = `${theme.palette.primary.main}30`;

  const imageSize = urlLogo ? size + 8 : size;
  const connectorIconContainerSize = viewType === PROJECTS_VIEW_TYPES.card ? 22 : 15;

  const connector = getConnectorAssociatedToProject(project);
  const connectorIcon = connector?.url_favicon;

  return (
    <Paper
      elevation={2}
      sx={{
        position: 'relative',
        padding: urlLogo ? 0.5 : 1,
        borderRadius: 2,
        background: thumbnailBackgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: imageSize,
        height: imageSize
      }}
    >
      {(projectImageFound || !projectImageLoaded) && urlLogo && (
        <img
          alt={urlLogo}
          src={urlLogo}
          onError={() => {
            setProjectImageFound(false);
            setProjectImageLoaded(true);
          }}
          onLoad={() => {
            setProjectImageLoaded(true);
            setProjectImageFound(true);
          }}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
      )}
      {((!projectImageFound && projectImageLoaded) || !urlLogo) && <FolderOpen sx={{fontSize: size}} color="primary" />}

      <ConnectorIconContainer size={connectorIconContainerSize}>
        {connectorIcon ? <img alt="icon connector" src={connectorIcon} /> : <FileUploadIcon color="primary" sx={{fontSize: connectorIconContainerSize}} />}
      </ConnectorIconContainer>
    </Paper>
  );
};

ProjectLogo.defaultProps = {
  urlLogo: null
};

ProjectLogo.propTypes = {
  urlLogo: string,
  project: projectShape.isRequired
};

export default ProjectLogo;
