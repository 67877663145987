import {useEffect} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {grey} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import {bool, func, shape, string} from 'prop-types';

import {FONT_FAMILIES, REPORT_MODULES_CATEGORIES} from '../../../const';
import {useIsModuleVisibleOrEnabled} from '../../../hooks/components/report-modules/useIsModuleVisibleOrEnabled/useIsModuleVisibleOrEnabled';
import useReport from '../../../hooks/providers/useReport';
import SingleReportModule from '../SingleReportModule/SingleReportModule';

// eslint-disable-next-line complexity
const ReportModulesCategory = ({category, isExpanded, setIsExpanded}) => {
  const {searchedModule, selectedProject} = useReport();
  const {isModuleVisible, isModuleEnabled} = useIsModuleVisibleOrEnabled();

  const isCategoryEnabled = category.modules.some(m => isModuleEnabled(m));
  const isCategoryDisabledBecauseUserIsNotOwnerOfProject = category.modules.every(m => m.requiresSirenSelected) && selectedProject && !selectedProject.is_owner;
  const isCategoryDisabledBecauseAllModulesRequiresSiren = category.modules.every(m => m.requiresSirenSelected) && !selectedProject;
  const categoryContainsMatchingModule = category.modules.some(m => m.name.toLowerCase().includes(searchedModule.toLowerCase()));
  const categoryContainsVisibleModules = category.modules.some(m => isModuleVisible(m));

  const {label} = category;

  const theme = useTheme();
  const categoryLabelColor = isExpanded ? 'white ' : 'text.primary';

  const toggleExpanded = () => {
    if (isExpanded) {
      setIsExpanded(null);
    } else {
      setIsExpanded(category.id);
    }
  };

  const getBgColor = () => {
    if (!isCategoryEnabled) {
      return grey[300];
    }
    const bgColor = isExpanded ? theme.palette.primary.main : 'white';
    return bgColor;
  };

  const getCategoryDisabledTooltip = () => {
    if (isCategoryEnabled) return '';

    if (isCategoryDisabledBecauseAllModulesRequiresSiren) {
      return "Veuillez sélectionner un dossier pour activer l'outil";
    }

    if (isCategoryDisabledBecauseUserIsNotOwnerOfProject) {
      return 'Vous ne disposez pas des droits pour utiliser cet outil';
    }

    if (category.id === REPORT_MODULES_CATEGORIES.ANALYSIS.id) {
      return "Positionnez-vous sur une page avec une analyse pour activer l'outil";
    }

    return '';
  };

  // This hook aims to automatically expanded a category containing a module with searched name in ReportModulesSearchbar
  useEffect(() => {
    if (searchedModule) {
      setIsExpanded(categoryContainsMatchingModule);
    } else {
      setIsExpanded(false);
    }
  }, [searchedModule]);

  // This hook aims to automatically collapse a category is disabled
  useEffect(() => {
    if (!isCategoryEnabled && isExpanded) {
      setIsExpanded(false);
    }
  }, [isCategoryEnabled]);

  const IconComponent = isExpanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  if (searchedModule && !categoryContainsMatchingModule) {
    return null;
  }

  if (!categoryContainsVisibleModules) {
    return null;
  }

  return (
    <Tooltip title={getCategoryDisabledTooltip()}>
      <Card
        onClick={isCategoryEnabled ? toggleExpanded : null}
        variant="outlined"
        sx={{
          cursor: isCategoryEnabled ? 'pointer' : 'not-allowed',
          boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
          mb: 2,
          background: getBgColor(),
          borderRadius: 3
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center" py={1} px={1}>
          <Box ml={3} />
          <Typography sx={{color: categoryLabelColor}} fontFamily={FONT_FAMILIES.BODY} fontWeight="bold" fontSize={16}>
            {label}
          </Typography>
          <IconButton disabled={!isCategoryEnabled} aria-label="ouvrir catégorie module" color="inherit" size="small">
            <IconComponent color={isExpanded ? 'white' : 'text.primary'} />
          </IconButton>

          {isExpanded && isCategoryEnabled ? (
            <Card
              onClick={e => e.stopPropagation()}
              variant="outlined"
              sx={{
                width: '90%',
                margin: '0 auto',
                mt: 1.5,
                mb: 1,
                borderRadius: 3
              }}
            >
              {category.modules.map((m, i) => (
                <SingleReportModule key={i} module={m} />
              ))}
            </Card>
          ) : null}
        </Grid>
      </Card>
    </Tooltip>
  );
};

ReportModulesCategory.propTypes = {
  category: shape({
    label: string.isRequired,
    id: string.isRequired
  }).isRequired,
  isExpanded: bool.isRequired,
  setIsExpanded: func.isRequired
};

export default ReportModulesCategory;
