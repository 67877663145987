import CableIcon from '@mui/icons-material/Cable';
import FolderIcon from '@mui/icons-material/Folder';
import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import {BREAKPOINTS, FONT_FAMILIES, PROJECTS_GROUPING_TYPES} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import useProjects from '../../../../hooks/providers/useProjects';

const Container = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    borderLeftWidth: 0,
    padding: '0 4px 0 0'
  },
  borderRightWidth: 1,
  borderColor: '#DCE0EA',
  borderStyle: 'solid',
  paddingRight: 10,
  marginRight: 6,
  minHeight: 42,
  display: 'flex',
  alignItems: 'center'
}));

const StyledToggleButtonsGroup = muiStyled(ToggleButtonGroup)(({theme}) => ({
  maxHeight: 20,
  maxWidth: 170,
  borderRadius: 20,
  backgroundColor: theme.palette.primary.main,
  padding: '4px 4px 6px 4px'
}));

const SingleButton = muiStyled(props => <ToggleButton {...props} />)(({theme, ...props}) => ({
  border: 'none',
  color: props.selected ? 'primary' : 'white',
  backgroundColor: props.selected ? 'white !important' : 'primary',
  borderRadius: '30px !important',
  lineHeight: 1,
  fontFamily: FONT_FAMILIES.BODY,
  fontWeight: 'bold',
  fontSize: 13,
  [theme.breakpoints.down('sm')]: {
    width: 28
  }
}));

const ConnectionsGroupingTypeToggler = () => {
  const {connectionGroupingType, setConnectionGroupingType} = useProjects();

  const toggleGroupingType = () => {
    const newGroupingType = connectionGroupingType === PROJECTS_GROUPING_TYPES.folder ? PROJECTS_GROUPING_TYPES.connection : PROJECTS_GROUPING_TYPES.folder;
    setConnectionGroupingType(newGroupingType);
  };

  const currentBreakpoint = useBreakpoint();
  const isExtraSmallScreen = currentBreakpoint === BREAKPOINTS.xs;

  return (
    <Container>
      <StyledToggleButtonsGroup color="primary" value={connectionGroupingType} exclusive onChange={toggleGroupingType} aria-label="type de regroupement">
        <SingleButton selected={connectionGroupingType === PROJECTS_GROUPING_TYPES.folder} value={PROJECTS_GROUPING_TYPES.folder}>
          {isExtraSmallScreen ? <FolderIcon fontSize="small" /> : 'Dossier'}
        </SingleButton>
        <SingleButton selected={connectionGroupingType === PROJECTS_GROUPING_TYPES.connection} value={PROJECTS_GROUPING_TYPES.connection}>
          {isExtraSmallScreen ? <CableIcon fontSize="small" /> : 'Connexion'}
        </SingleButton>
      </StyledToggleButtonsGroup>
    </Container>
  );
};

ConnectionsGroupingTypeToggler.propTypes = {};

export default ConnectionsGroupingTypeToggler;
