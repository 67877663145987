/* eslint-disable react/jsx-props-no-spreading */
import {TabList} from '@mui/lab';
import muiStyled from '@mui/material/styles/styled';

const StyledTabs = muiStyled(({isReportTabs, ...props}) => (
  <TabList
    {...props}
    TabIndicatorProps={{
      children: (
        <>
          <span className="MuiTabs-indicatorSpan" />
          <span className="MuiTabs-indicatorSpanPointer" />
        </>
      )
    }}
  />
))(({theme, ...props}) => ({
  '& .MuiTabs-flexContainer': props.isReportTabs
    ? {
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '0 auto'
      }
    : {
        justifyContent: 'space-between'
      },

  // Root style, only applied in WorkspacesTabs because we pass isReportTabs={false} prop
  ...(!props.isReportTabs
    ? {
        display: 'flex',
        minHeight: 75,
        // maxWidth: 700,
        [theme.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: '100%',
          paddingLeft: 0
        },
        overflow: 'visible !important',
        '& .MuiTabs-scroller': {
          overflow: 'visible !important',
          height: '100%',
          zIndex: 9,
          background: 'white'
        }
      }
    : {
        '& .MuiTabs-scroller': {
          display: 'flex',
          height: '100%',
          zIndex: 9,
          background: 'inherit'
        }
      }),
  flex: 1,
  '& .MuiTabs-centered': {
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 5
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    height: 5,
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiTabs-indicatorSpanPointer': {
    position: 'absolute',
    top: 5,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '5px 8px 0 8px',
    borderColor: `${theme.palette.secondary.main} transparent transparent transparent`,
    transform: 'rotate(0deg)'
  }
}));

export default StyledTabs;
