import {useEffect, useState} from 'react';

import SortIcon from '@mui/icons-material/Sort';
import {Typography, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useLocation, useNavigate} from 'react-router-dom';

import {FONT_FAMILIES, SORT_TYPES} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import Button from '../../../form/buttons/Button/Button';
import SearchBar from '../../../form/SearchBar/SearchBar';
import Spinner from '../../../spinner/Spinner';
import ConnectorFoldersList from '../ConnectorFoldersList/ConnectorFoldersList';
import ConnectorFoldersListCounter from '../ConnectorFoldersListCounter/ConnectorFoldersListCounter';

const ConnectorFoldersListForm = () => {
  const {isSubmitting, projects, connectorFolders, setIsSubmitting, connector, createConnection, foldersCompanyIdsToConnect} = useProjects();

  const [sortBy, setSortBy] = useState(SORT_TYPES.NAME);
  const [searchedFolder, setSearchedFolder] = useState('');

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const foldersToConnect = connectorFolders.filter(f => foldersCompanyIdsToConnect.includes(f.company_id));
    await createConnection(connector, foldersToConnect);
    setIsSubmitting(false);
  };

  const handleSortByChange = (event, newSortType) => {
    if (newSortType) {
      setSortBy(newSortType);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // We clear the state to prevent re-opening create modal on tiime connector if user reload page, because state is persist on page reload
    navigate(location.pathname, {replace: true});
  }, []);

  if (isSubmitting) return <Spinner isLoading text="Création en cours..." />;

  const isFormValid = foldersCompanyIdsToConnect.length > 0;
  const allFoldersAlreadyImported = connectorFolders.every(f => (projects || []).find(p => p.siren === f.siren && p.connector === connector.id) !== undefined);
  const buttonText = foldersCompanyIdsToConnect.length > 1 ? 'Importer les dossiers' : 'Importer le dossier';

  return (
    <Box>
      <Box sx={{position: 'sticky', top: '24px', background: 'white', zIndex: 2, pt: 1, pb: 3}}>
        <Typography sx={{fontSize: 14}}>
          Choisissez dans la liste ci-dessous les dossiers
          <Typography component="span" fontSize={13} fontFamily={FONT_FAMILIES.BODY} fontWeight="bold">
            &nbsp;{connector.name}&nbsp;
          </Typography>
          que vous souhaitez connecter
        </Typography>
        <Grid flexWrap="nowrap" container mt={1}>
          <Grid item xs display="flex" alignItems="center">
            <SearchBar placeholder="Recherchez un dossier" value={searchedFolder} setValue={setSearchedFolder} />
          </Grid>
          <Grid
            display="flex"
            item
            sx={{
              borderLeft: '1px solid lightgrey',
              borderRight: '1px solid lightgrey',
              px: 1,
              mx: 1
            }}
          >
            <Grid container flexWrap="nowrap" alignItems="center">
              <Grid item mr={1}>
                <SortIcon color="primary" />
              </Grid>
              <ToggleButtonGroup color="primary" value={sortBy} exclusive onChange={handleSortByChange} aria-label="tri par">
                <ToggleButton size="small" value={SORT_TYPES.NAME}>
                  Nom
                </ToggleButton>
                <ToggleButton size="small" value={SORT_TYPES.COMPANY_ID}>
                  Numéro
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <ConnectorFoldersListCounter numberOfFoldersSelected={foldersCompanyIdsToConnect.length} />
          </Grid>
        </Grid>
      </Box>

      <Box pb={3}>
        <ConnectorFoldersList search={searchedFolder} sortType={sortBy} />
      </Box>

      <Box sx={{position: 'sticky', bottom: 0, background: 'white', py: 2}}>
        <Button sx={{position: 'static'}} disabled={isSubmitting || !isFormValid || allFoldersAlreadyImported} onClick={handleSubmit} fullWidth variant="contained" color="secondary" size="large">
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ConnectorFoldersListForm;
