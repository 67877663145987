import {useState, useEffect} from 'react';

import {DialogContent, DialogActions, Typography} from '@mui/material';
import {useBlocker} from 'react-router-dom';

import {EMBED_REPORT_DEFAULT_SETTINGS, FONT_FAMILIES} from '../../const';
import useReport from '../../hooks/providers/useReport';
import Button from '../form/buttons/Button/Button';
import BaseModal from '../modal/BaseModal';
import SaveReportButton from './report-buttons/SaveReportButton';

const SaveEditedReportModal = () => {
  const {isSaveReportModalOpen, setIsSaveReportModalOpen, selectedReport, isReportInEditionMode, setIsReportInEditionMode} = useReport();

  const [canNavigate, setCanNavigate] = useState(false);

  const shouldPreventNavigation = isReportInEditionMode && !canNavigate;
  const blocker = useBlocker(({currentLocation, nextLocation}) => shouldPreventNavigation && currentLocation.pathname !== nextLocation.pathname);

  const open = blocker.state === 'blocked' || isSaveReportModalOpen;

  const onClose = async () => {
    setCanNavigate(true);
    await selectedReport.switchMode('view');
    await selectedReport.updateSettings(EMBED_REPORT_DEFAULT_SETTINGS);
    setIsReportInEditionMode(false);
    setIsSaveReportModalOpen(false);
    setCanNavigate(false);
    blocker.proceed();
  };

  const alertUser = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  return (
    <BaseModal onClose={() => setIsSaveReportModalOpen(false)} open={open} title="Sauvegarder les modifications" maxWidth="sm">
      <DialogContent sx={{py: 3}}>
        <Typography fontFamily={FONT_FAMILIES.BODY} fontSize={14}>
          Vous êtes sur le point de quitter la page sans avoir sauvegardé vos modifications.
        </Typography>
      </DialogContent>
      <DialogActions sx={{width: '100%', justifyContent: 'center'}}>
        <SaveReportButton closeModal={onClose} />
        <Button variant="outlined" color="error" onClick={onClose}>
          Quitter sans sauvegarder
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

SaveEditedReportModal.propTypes = {};

export default SaveEditedReportModal;
