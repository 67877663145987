import {useState} from 'react';

import {Box, Grid} from '@mui/material';

import useProjects from '../../../hooks/providers/useProjects';
import {generateFormFields} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import Spinner from '../../spinner/Spinner';
import CompatibleEditors from '../CompatibleEditors';

const ManualConnectorForm = () => {
  const {
    createConnection,
    connector,
    loginToConnectorFormData: formData,
    setLoginToConnectorFormData: setFormData,
    isSirenValid,
    handleSirenChange,
    isSirenAlreadyTakenForConnector,
    siren,
    isSubmitting,
    setIsSubmitting
  } = useProjects();

  const [files, setFiles] = useState([]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await createConnection(connector, [{siren}], files);
    setIsSubmitting(false);
  };

  if (isSubmitting) return <Spinner text="Envoi des données" isLoading />;

  const formFields = generateFormFields({connector, formData, setFormData, setFiles, handleSirenChange});
  const isSirenAlreadyTaken = isSirenAlreadyTakenForConnector(siren, connector);
  const isSubmitButtonDisabled = isSubmitting || files.length === 0 || !isSirenValid || isSirenAlreadyTaken;

  return (
    <Box>
      {formFields}

      {connector.compatible_editors ? <CompatibleEditors editors={connector.compatible_editors} /> : null}

      <Grid container justifyContent="center" mt={2}>
        <Grid item xs={12} sm={6}>
          <Button disabled={isSubmitButtonDisabled} fullWidth onClick={handleSubmit} variant="contained" color="secondary" size="large">
            Importer vos données
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualConnectorForm;
