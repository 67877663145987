import {useState, useEffect} from 'react';

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation} from 'react-router-dom';

import useAudioPlayer from '../../../../hooks/misc/useAudioPlayer';
import useReport from '../../../../hooks/providers/useReport';
import useReportSummary from '../../../../hooks/providers/useReportSummary';

// eslint-disable-next-line complexity
const ReadAudioButton = () => {
  const [audio, setAudio] = useState(null);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const location = useLocation();
  const {reportSelectedPage, selectedProject, isReportLoaded, reports, selectedReport} = useReport();
  const {reportAudios, getReportPageAudio, currentReportPageSummary} = useReportSummary();
  const report = reports?.find(r => r.report_id === selectedReport?.config?.id);

  const currentPageHasAudio = currentReportPageSummary !== null;
  const isCurrentPageWithoutAudio = !currentPageHasAudio;
  const shouldDisplay = isReportLoaded && location.pathname.startsWith('/reports');

  const {isPlaying, sound, setIsPlaying, togglePlayPause} = useAudioPlayer(audio);

  const readAudioButtonDisabled = isLoadingAudio || (isCurrentPageWithoutAudio && !isPlaying) || !audio;

  const loadAudio = async () => {
    setIsLoadingAudio(true);
    setIsPlaying(false);
    const {siren} = selectedProject;

    const reportName = reports.find(r => r.report_id === selectedReport.config.id)?.report_name;
    const pageName = reportSelectedPage.displayName;

    const res = await getReportPageAudio({siren, reportName, pageName});

    setIsLoadingAudio(false);
    return res;
  };

  useEffect(() => {
    (async () => {
      if (selectedProject && currentReportPageSummary) {
        const res = await loadAudio();
        if (res.status !== 200) {
          setAudio(null);
        }
      }
    })();
  }, [currentReportPageSummary]);

  useEffect(() => {
    const newAudio = (reportAudios || []).find(a => a.report_name === report?.report_name && a.page_name === reportSelectedPage?.displayName && a.siren === selectedProject?.siren);
    setAudio(newAudio);
  }, [reportAudios]);

  useEffect(() => {
    if (!location.pathname.startsWith('/reports')) {
      setIsPlaying(false);
      setAudio(null);
    }
  }, [location.pathname]);

  if (shouldDisplay) {
    return (
      <Box mb={1}>
        <Fab aria-label="audio button" disabled={readAudioButtonDisabled} variant="contained" color="primary" onClick={togglePlayPause}>
          {isLoadingAudio && <CircularProgress data-testid="circularProgress" sx={{position: 'absolute'}} size={55} />}
          {isPlaying ? <GraphicEqIcon color="white" className="wave-animation" /> : <PlayCircleIcon color="white" />}
        </Fab>
      </Box>
    );
  }

  return null;
};

export default ReadAudioButton;
