import CloseIcon from '@mui/icons-material/Close';
import DoubleChevronLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DoubleChevronRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import DotsIcon from '@mui/icons-material/MoreVertOutlined';
import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {styled as muiStyled} from '@mui/material/styles';

import {FONT_FAMILIES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import TopBarIconButton from '../ChatbotTopbarIconButton/ChatbotTopbarIconButton';

const DrawerHeader = muiStyled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 1),
  justifyContent: 'flex-start',
  position: 'sticky',
  background: 'white',
  top: 0,
  zIndex: 9
}));

const ChatbotTopbar = () => {
  const {setChatbotSidebarOpen: setOpen, isFullScreenMode, setIsFullScreenMode, setIsTalksListDrawerOpen, openMainMenu: openMainChatbotMenu} = useChatbot();

  const closeChatbotSidebar = () => {
    setOpen(false);
  };

  return (
    <DrawerHeader>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box sx={{display: {lg: 'block', xs: 'none'}}}>
          <TopBarIconButton onClick={() => setIsFullScreenMode(!isFullScreenMode)} aria-label="mode plein écran">
            {isFullScreenMode ? <DoubleChevronRightIcon color="primary" fontSize="medium" /> : <DoubleChevronLeftIcon color="primary" fontSize="small" />}
          </TopBarIconButton>
        </Box>
        <Box sx={{display: {lg: 'none', xs: 'block'}}}>
          <TopBarIconButton onClick={() => setIsTalksListDrawerOpen(true)} aria-label="ouvrir la liste des conversations">
            <MenuIcon color="primary" fontSize="medium" />
          </TopBarIconButton>
        </Box>
        <Typography fontFamily={FONT_FAMILIES.TITLE} fontSize={20} color="primary">
          SerenIA
        </Typography>
        <Grid item>
          <Grid container>
            <TopBarIconButton onClick={openMainChatbotMenu} aria-label="ouvrir le menu SerenIA">
              <DotsIcon color="primary" fontSize="medium" />
            </TopBarIconButton>
            <TopBarIconButton onClick={closeChatbotSidebar} aria-label="fermer SerenIA">
              <CloseIcon color="primary" fontSize="medium" />
            </TopBarIconButton>
          </Grid>
        </Grid>
      </Grid>
    </DrawerHeader>
  );
};

export default ChatbotTopbar;
