import Box from '@mui/material/Box';

import useReport from '../../../hooks/providers/useReport';
import SearchBar from '../../form/SearchBar/SearchBar';

const ReportModulesSearchbar = () => {
  const {searchedModule: value, setSearchedModule: setValue} = useReport();

  return (
    <Box mt={2.5} mb={3}>
      <SearchBar placeholder="Recherchez un outil" value={value} setValue={setValue} />
    </Box>
  );
};

export default ReportModulesSearchbar;
