import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import {arrayOf, func, number} from 'prop-types';

function valuetext(value) {
  return `${value}`;
}

const NumberRangeFilter = ({min, max, value, setValue}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: 300}}>
      <Slider min={min} max={max} getAriaLabel={() => 'Temperature range'} value={value} onChange={handleChange} valueLabelDisplay="auto" getAriaValueText={valuetext} />

      <Grid container mt={2} flexWrap="nowrap">
        <TextField
          onChange={e => {
            handleChange(e, [e.target.value, max]);
          }}
          variant="filled"
          value={value[0]}
          id="outlined-number-min"
          label="Minimum"
          type="number"
        />
        <TextField
          onChange={e => {
            handleChange(e, [min, e.target.value]);
          }}
          variant="filled"
          value={value[1]}
          sx={{ml: 1}}
          id="outlined-number-max"
          label="Maximum"
          type="number"
        />
      </Grid>
    </Box>
  );
};

NumberRangeFilter.propTypes = {
  min: number.isRequired,
  max: number.isRequired,
  value: arrayOf(number).isRequired,
  setValue: func.isRequired
};

export default NumberRangeFilter;
