/* eslint-disable class-methods-use-this,react/no-unused-class-component-methods */

import FilterListIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

// eslint-disable-next-line react/require-optimization
class HeaderCell {
  getCompatibleCell(uncertainCell) {
    return {...uncertainCell};
  }

  isFocusable() {
    return false;
  }

  render(cell) {
    const {onOpen, hasFilters, isOpen} = cell;

    const iconButtonColor = hasFilters ? 'white' : 'primary';
    const iconColor = hasFilters ? 'primary' : 'white';

    return (
      <Grid container justifyContent="space-between" alignItems="center" flex={1}>
        <span>{cell.text}</span>
        <IconButton
          onClick={onOpen}
          sx={{
            width: 20,
            height: 20,
            background: iconButtonColor,
            '&.MuiIconButton-root:hover': {
              background: hasFilters ? iconButtonColor : 'rgba(0, 0, 0, 0.3)'
            }
          }}
        >
          <FilterListIcon sx={{fontSize: 16}} color={iconColor} />
        </IconButton>
      </Grid>
    );
  }
}

export default HeaderCell;
