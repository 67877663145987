import {Typography, useTheme} from '@mui/material';
import {grey} from '@mui/material/colors';

import CustomAlert from '../../alert/Alert';

const ReportPrivacyAlert = () => {
  const muiTheme = useTheme();

  return (
    <CustomAlert sx={{background: muiTheme.palette.primary.light, color: grey[700]}}>
      <Typography fontSize={14} sx={{pr: 2}}>
        Vos données sont traitées de manière sécurisée sur nos serveurs européens, dans la stricte limite nécessaire au fonctionnement du service. Les résumés texte et audio générés respectent les
        principes de minimisation et de licéité exigés par le RGPD.{' '}
        <a target="_blank" href="https://www.drivn.fr/politique-de-confidentialite/" rel="noreferrer">
          En savoir plus
        </a>
      </Typography>
    </CustomAlert>
  );
};

export default ReportPrivacyAlert;
