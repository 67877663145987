import {Grid, Typography} from '@mui/material';
import {number} from 'prop-types';

import {FONT_FAMILIES} from '../../const';

const LinkToRse = ({fontSize = 14}) => {
  const {REACT_APP_RSE_POLICY_URL, REACT_APP_HAS_CUSTOM_POLICY_URL} = process.env;
  const companyHasCustomPolicy = REACT_APP_HAS_CUSTOM_POLICY_URL === 'true';
  const sentence = companyHasCustomPolicy ? 'En savoir plus sur nos engagements RSE' : 'En savoir plus sur les engagements RSE de notre partenaire Drivn';

  return (
    <Grid container justifyContent="center" px={0.5}>
      <Typography ml={0.2} textAlign="center" color="blue" target="_blank" href={REACT_APP_RSE_POLICY_URL} component="a" fontFamily={FONT_FAMILIES.BODY} fontSize={fontSize}>
        {sentence}
      </Typography>
    </Grid>
  );
};

LinkToRse.defaultProps = {
  fontSize: 14
};

LinkToRse.propTypes = {
  fontSize: number
};

export default LinkToRse;
