import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Grid, Paper, Typography} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import {FONT_FAMILIES} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button/Button';

const NoProjectSelectedBackdrop = () => {
  const {noProjectsSelectedBackdropOpen, setNoProjectsSelectedBackdropOpen: setIsOpen} = useReport();

  if (!noProjectsSelectedBackdropOpen) return null;

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        background: 'rgba(0,0,0,0.5)',
        opacity: '1 !important',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open
    >
      <Paper
        elevation={2}
        sx={{
          p: 2.5,
          m: 1.5
        }}
      >
        <Grid container mb={2.5} alignItems="center">
          <InfoOutlinedIcon color="primary" />

          <Typography fontSize={20} ml={1} fontFamily={FONT_FAMILIES.TITLE}>
            Information
          </Typography>
        </Grid>
        <Typography fontSize={16}>Sélectionnez 1 dossier pour activer le module</Typography>

        <Button variant="contained" color="secondary" sx={{display: 'block', margin: '0 auto', mt: 2}} onClick={() => setIsOpen(false)}>
          J'ai compris
        </Button>
      </Paper>
    </Backdrop>
  );
};

export default NoProjectSelectedBackdrop;
