const getOldestDate = datesArray => {
  // Sort the dates in ascending order
  datesArray.sort((a, b) => {
    return a.localeCompare(b);
  });
  // Return the oldest date (the first element in the array)
  return datesArray[0];
};

const getFileB64 = async file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const downloadFileFromApiRawResult = (data, outputFilename, type = 'application/octet-stream') => {
  const blob = new Blob([data], {type});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  console.log({data, outputFilename, type});
  a.href = url;
  a.download = outputFilename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export {getOldestDate, getFileB64, downloadFileFromApiRawResult};
