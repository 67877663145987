import {useState, useMemo, useReducer} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_WORKSPACE, SNACKBAR_ACTIONS, USERS_AUTHORIZATION_LEVELS} from '../const';
import WorkspacesContext from '../contexts/WorkspacesContext';
import useHttp from '../hooks/misc/useHttp';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import {ActionCreators, initialState, reducer} from '../reducers/providers/workspacesReducer';
import {getFileB64} from '../utils';

const WorkspacesProvider = ({children}) => {
  const {loadReports, setReports} = useReport();

  const [workspacesFetching, setWorkspacesFetching] = useState(false);
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [workspacesTabsMenuAchorEl, setWorkspacesTabsMenuAchorEl] = useState(null);
  const [isCustomReportCreation, setIsCustomReportCreation] = useState(true);
  const [deleteWorkspaceOrReportModalOpen, setDeleteWorkspaceOrReportModalOpen] = useState(false);
  const [anchorElReportTabsMenu, setAnchorElReportTabsMenu] = useState(null);
  const [rightClickedReportTabId, setRightClickedReportTabId] = useState(null);
  const [isExistingWorkspaceEdition, setIsExistingWorkspaceEdition] = useState(false);
  const [createOrUpdateWorkspaceModalOpen, setCreateOrUpdateWorkspaceModalOpen] = useState(false);
  const [rightClickedWorkspaceId, setRightClickedWorkspaceId] = useState(null);
  const [isWorkspaceUsersModalOpen, setIsWorkspaceUsersModalOpen] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const {workspaces} = state;

  const {
    createWorkspaceInProgress,
    createWorkspaceEnd,
    setSelectedWorkspace,
    replaceReportInWorkspace,
    addReportToWorkspace,
    setWorkspaces,
    deleteWorkspace: deleteWorkspaceActionCreator,
    deleteWorkspaceSuccess,
    deleteWorkspaceFail,
    deleteReportFromWorkspace,
    deleteReportFromWorkspaceSuccess,
    deleteReportFromWorkspaceFail,
    addUserToReport: addUserToReportActionCreator,
    deleteUserFromReport: deleteUserFromReportActionCreator,
    addUserToWorkspace: addUserToWorkspaceActionCreator,
    deleteUserFromWorkspace: deleteUserFromWorkspaceActionCreator
  } = ActionCreators(dispatch);

  const openWorkspaceTabsMenu = event => {
    setWorkspacesTabsMenuAchorEl(event.currentTarget);
  };

  const closeWorkspaceTabsMenu = () => {
    setWorkspacesTabsMenuAchorEl(null);
    setRightClickedWorkspaceId(null);
  };

  const {_post, _get} = useHttp();
  const {showSnackbar, closeSnackbar, defaultSnackbarOptions} = useSnackbar();

  const getWorkspaces = async () => {
    const url = API_ENDPOINTS.workspaces.findAll;
    setWorkspacesFetching(true);
    try {
      const {response, responseJson: data} = await _get(url);
      setWorkspacesFetching(false);

      console.log({data});
      if (response.status === 200) {
        setWorkspaces([DEFAULT_WORKSPACE, ...data]);

        return {
          status: 200,
          success: true,
          data: [DEFAULT_WORKSPACE, ...data]
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setWorkspaces([]);
      setWorkspacesFetching(false);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createWorkspace = async name => {
    const url = API_ENDPOINTS.workspaces.create;
    try {
      createWorkspaceInProgress();
      const {response, responseJson: data} = await _post(url, {
        workspace_name: name
      });
      createWorkspaceEnd();

      if (response.status === 200) {
        closeWorkspaceTabsMenu();

        setWorkspaces([DEFAULT_WORKSPACE, ...data]);

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteProviderWorkspace = workspace => {
    const associatedReportsIds = workspace.reports?.map(r => r.report_id);
    if (associatedReportsIds) {
      setReports(reportsBeforeWorkspaceDeletion => reportsBeforeWorkspaceDeletion.filter(r => !associatedReportsIds.includes(r.report_id)));
    }
    deleteWorkspaceSuccess(workspace.workspace_id);
  };

  const deleteWorkspace = async workspaceId => {
    const url = API_ENDPOINTS.workspaces.delete;
    try {
      deleteWorkspaceActionCreator(workspaceId);
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.DELETE_WORKSPACE_IN_PROGRESS, {
        severity: 'info',
        autoHide: false
      });
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId
      });
      closeSnackbar(snackbarId);
      const workspaceToDelete = workspaces.find(w => w.workspace_id === workspaceId);

      if (response.status === 200) {
        const workspaceName = workspaces.find(w => w.workspace_id === workspaceId)?.workspace_name;
        showSnackbar(SNACKBAR_ACTIONS.DELETE_WORKSPACE_SUCCESS, defaultSnackbarOptions, {
          workspaceName
        });

        deleteProviderWorkspace(workspaceToDelete);
        return {
          status: 200,
          success: true
        };
      }

      deleteWorkspaceFail(workspaceId);
      // eslint-disable-next-line no-console
      console.error({response, data});

      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createReport = async (name, workspaceId) => {
    const url = API_ENDPOINTS.reports.create;
    try {
      const {response, responseJson: data} = await _post(url, {
        report_name: name,
        workspace_id: workspaceId
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_REPORT_SUCCESS, defaultSnackbarOptions, {
          reportName: name
        });

        addReportToWorkspace(workspaceId, {
          reportName: name,
          reportId: data.report_id,
          reportSchemaName: data.schema_name
        });

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteReport = async (workspaceId, reportId) => {
    const url = API_ENDPOINTS.reports.delete;
    const snackbarId = showSnackbar(SNACKBAR_ACTIONS.DELETE_REPORT_IN_PROGRESS, {
      severity: 'info',
      autoHide: false
    });
    try {
      deleteReportFromWorkspace(reportId);
      const {response, responseJson: data} = await _post(url, {
        report_id: reportId,
        workspace_id: workspaceId
      });
      deleteReportFromWorkspaceSuccess(workspaceId, reportId);
      closeSnackbar(snackbarId);
      if (response.status === 200) {
        showSnackbar(data);
        return {
          status: 200,
          success: true
        };
      }
      deleteReportFromWorkspaceFail(reportId);
      // eslint-disable-next-line no-console
      console.error({response, data});
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const importReport = async (reportData, shouldEraseExistingReport = false) => {
    const {file, workspaceId, role, isEffectiveIdentityRolesRequired} = reportData;
    try {
      const b64 = await getFileB64(file);
      const url = API_ENDPOINTS.reports.import;
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.IMPORT_REPORT_IN_PROGRESS, {
        severity: 'info',
        autoHide: false
      });

      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        filename: file.name,
        file_b64: b64.split('base64,')[1],
        isEffectiveIdentityRolesRequired,
        ...(Boolean(isEffectiveIdentityRolesRequired) && {role})
      });

      if (shouldEraseExistingReport) {
        replaceReportInWorkspace(workspaceId, data);
      }

      await loadReports();
      closeSnackbar(snackbarId);
      showSnackbar(SNACKBAR_ACTIONS.IMPORT_REPORT_SUCCESS);

      if (!shouldEraseExistingReport) {
        addReportToWorkspace(
          workspaceId,
          {
            reportName: data.report_name,
            reportId: data.report_id,
            reportSchemaName: data.schema_name
          },
          true
        );
      }

      return {
        status: response.status,
        success: response.status === 200
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const exportReport = async (workspaceId, reportId) => {
    try {
      const url = API_ENDPOINTS.reports.export;
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        report_id: reportId
      });

      return {
        status: response.status,
        data,
        success: response.status === 200
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message,
        success: false
      };
    }
  };

  // TODO DRY factorize with deleteUserFromWorkspaceOrReport
  const addUserToReportOrWorkspace = async ({isWorkspaceAdding, email, workspaceId, reportId}) => {
    try {
      const url = API_ENDPOINTS.workspaces.addUser;

      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        level: isWorkspaceAdding ? USERS_AUTHORIZATION_LEVELS.workspace : USERS_AUTHORIZATION_LEVELS.report,
        report_id: isWorkspaceAdding ? '00000000-0000-0000-0000-000000000000' : reportId,
        guest_usermail: email
      });

      const successHttpCodes = [200, 402];
      if (!successHttpCodes.includes(response.status)) {
        return {
          status: response.status,
          success: false
        };
      }

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.ADD_USER_SUCCESS, defaultSnackbarOptions, {newUserUsername: data.givenName});
      }

      return {
        status: response.status,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const addUserToWorkspace = async (email, workspaceId) => {
    const res = await addUserToReportOrWorkspace({isWorkspaceAdding: true, email, workspaceId});
    if (res.success && res.status === 200) {
      const user = res.data;
      const formattedUser = {
        username: user.givenName,
        user_id: user.id,
        email: user.mail
      };
      addUserToWorkspaceActionCreator(workspaceId, formattedUser);
    }
    return res;
  };

  const addUserToReport = async (email, workspaceId, reportId) => {
    const res = await addUserToReportOrWorkspace({isWorkspaceAdding: false, email, workspaceId, reportId});
    if (res.success && res.status === 200) {
      const user = res.data;
      const formattedUser = {
        username: user.givenName,
        user_id: user.id,
        email: user.mail
      };
      addUserToReportActionCreator(workspaceId, reportId, formattedUser);
    }

    return res;
  };

  // TODO DRY factorize with addUserForWorkspaceOrReport
  const deleteUserFromWorkspaceOrReport = async ({isWorkspaceDeleting, userId, workspaceId, reportId}) => {
    try {
      const url = API_ENDPOINTS.workspaces.deleteUser;

      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        level: isWorkspaceDeleting ? USERS_AUTHORIZATION_LEVELS.workspace : USERS_AUTHORIZATION_LEVELS.report,
        report_id: isWorkspaceDeleting ? '00000000-0000-0000-0000-000000000000' : reportId,
        delete_user_id: userId
      });

      if (response.status !== 200) {
        return {
          status: response.status,
          success: false
        };
      }

      showSnackbar(SNACKBAR_ACTIONS.DELETE_USER_SUCCESS);
      return {
        status: 200,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const deleteUserFromWorkspace = async (userId, workspaceId, isUserDeletingHimSelf) => {
    const res = await deleteUserFromWorkspaceOrReport({isWorkspaceDeleting: true, userId, workspaceId});

    if (res.success) {
      // if self-deletion, delete whole workspace
      if (isUserDeletingHimSelf) {
        deleteWorkspaceSuccess(workspaceId);
        // if deleting another user, delete user from workspace users list
      } else {
        deleteUserFromWorkspaceActionCreator(workspaceId, userId);
      }
      closeWorkspaceTabsMenu();
    } else {
      deleteWorkspaceFail(workspaceId);
    }

    return res;
  };

  const deleteUserFromReport = async ({userId, workspaceId, reportId, isUserDeletingHimSelf}) => {
    const res = await deleteUserFromWorkspaceOrReport({isWorkspaceDeleting: false, userId, workspaceId, reportId});

    if (res.success) {
      if (isUserDeletingHimSelf) {
        const workspace = workspaces.find(w => w.workspace_id === workspaceId);
        // eslint-disable-next-line no-use-before-define
        const numberOfReportsOwnedInWorkspace = getCountReportsUserOwnsInWorkspace(userId, workspace);

        if (numberOfReportsOwnedInWorkspace === 1) {
          deleteProviderWorkspace(workspace);
        } else {
          deleteReportFromWorkspaceSuccess(workspaceId, reportId);
        }
      } else {
        deleteUserFromReportActionCreator(workspaceId, reportId, userId);
      }
    }
    return res;
  };

  const openCreateWorkspaceModal = () => {
    setIsExistingWorkspaceEdition(false);
    setCreateOrUpdateWorkspaceModalOpen(true);
    closeWorkspaceTabsMenu();
  };

  const findReportInWorkspaces = reportId => {
    const workspaceContainingReport = workspaces.filter(workspace => Array.isArray(workspace.reports)).find(workspace => workspace.reports.some(report => report.report_id === reportId));

    if (!workspaceContainingReport) {
      return null;
    }

    const report = workspaceContainingReport.reports.find(r => r.report_id === reportId);
    return {
      report,
      workspace: workspaceContainingReport
    };
  };

  const isUserInWorkspace = (userId, workspace) => {
    if (!userId || !workspace) return false;

    const user = workspace.workspace_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());

    return !!user;
  };

  const isUserOwnerOfWorkspace = (userId, workspace) => {
    const user = workspace.workspace_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());
    if (!user) {
      return false;
    }

    return user?.is_owner;
  };

  const isUserOwnerOfReport = (userId, reportId) => {
    const reportAndWorkspaceData = findReportInWorkspaces(reportId);

    if (!reportAndWorkspaceData) return false;

    const {report, workspace} = reportAndWorkspaceData;

    // Business rule: user owner at workspace level is automatically owner at report level
    const userOwnerOfWorkspace = isUserOwnerOfWorkspace(userId, workspace);
    if (userOwnerOfWorkspace) {
      return true;
    }

    const user = report.report_users?.find(u => u.user_id.toLowerCase() === userId.toLowerCase());
    return user.is_owner;
  };

  const getCountReportsUserOwnsInWorkspace = (userId, workspace) => {
    return workspace.reports.reduce((accumulator, report) => {
      const isUserOwner = isUserOwnerOfReport(userId, report.report_id);
      return accumulator + (isUserOwner ? 1 : 0);
    }, 0);
  };

  const selectedWorkspace = workspaces.find(w => w.isSelected);
  const selectedWorkspaceId = selectedWorkspace?.workspace_id;
  const isDefaultWorkspace = selectedWorkspaceId === DEFAULT_WORKSPACE.workspace_id;
  const rightClickedWorkspace = workspaces.find(w => w.workspace_id === rightClickedWorkspaceId);
  const rightClickedReportTab = selectedWorkspace?.reports?.find(r => r.report_id === rightClickedReportTabId);

  const isWorkspaceCurrentlyDeleting = workspaceId => {
    return state.workspacesDeleting.find(id => id === workspaceId) !== undefined;
  };

  const isReportCurrentlyDeleting = reportId => {
    return state.reportsDeleting.find(id => id === reportId) !== undefined;
  };

  const memoizedValues = {
    getWorkspaces,
    workspaces,
    numberOfWorkspaces: workspaces?.length,
    createWorkspace,
    deleteWorkspace,
    selectedWorkspace,
    selectedWorkspaceId,
    setSelectedWorkspace,
    createReport,
    deleteReport,
    createReportModalOpen,
    setCreateReportModalOpen,
    isDefaultWorkspace,
    workspacesTabsMenuAchorEl,
    openWorkspaceTabsMenu,
    closeWorkspaceTabsMenu,
    importReport,
    exportReport,
    isCustomReportCreation,
    setIsCustomReportCreation,
    setWorkspacesTabsMenuAchorEl,
    anchorElReportTabsMenu,
    setAnchorElReportTabsMenu,
    rightClickedReportTab,
    openCreateWorkspaceModal,
    isExistingWorkspaceEdition,
    setIsExistingWorkspaceEdition,
    createOrUpdateWorkspaceModalOpen,
    setCreateOrUpdateWorkspaceModalOpen,
    deleteWorkspaceOrReportModalOpen,
    rightClickedWorkspace,
    setRightClickedWorkspaceId,
    setRightClickedReportTabId,
    setDeleteWorkspaceOrReportModalOpen,
    isWorkspaceUsersModalOpen,
    setIsWorkspaceUsersModalOpen,
    addUserToWorkspace,
    addUserToReport,
    deleteUserFromWorkspace,
    deleteUserFromReport,
    isUserOwnerOfReport,
    isUserOwnerOfWorkspace,
    isUserInWorkspace,
    isWorkspaceCurrentlyDeleting,
    isReportCurrentlyDeleting,
    isWorkspaceCreationInProgress: state.isCreatingWorkspace,
    workspacesFetching
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <WorkspacesContext.Provider value={value}>{children}</WorkspacesContext.Provider>;
};

WorkspacesProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default WorkspacesProvider;
