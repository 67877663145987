import {MoreVert} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import {bool} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
import {projectShape} from '../../../proptypes-shapes';
import ProjectCardMenu from '../ProjectCardMenu';

const MenuContainer = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end'
}));

const ProjectMenuButton = ({project, open, isDisabled}) => {
  const {setProjectCardMenuAnchorEl, setSelectedProject} = useProjects();

  const openProjectMenu = event => {
    setProjectCardMenuAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  return (
    <MenuContainer>
      <IconButton
        onClick={openProjectMenu}
        disabled={isDisabled}
        aria-controls={open ? 'project-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="large"
        aria-label="Options"
        sx={{p: 0}}
      >
        <MoreVert color="primary" />
      </IconButton>
      <ProjectCardMenu project={project} />
    </MenuContainer>
  );
};

ProjectMenuButton.propTypes = {
  project: projectShape.isRequired,
  open: bool.isRequired,
  isDisabled: bool.isRequired
};

export default ProjectMenuButton;
