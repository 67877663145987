import {DialogContentText, DialogContent, DialogActions, useTheme} from '@mui/material';

import {FONT_FAMILIES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const DeleteThreadModal = () => {
  const {deleteThread, threadIdToDelete, threads, isDeleteThreadModalOpen: open, setIsDeleteThreadModalOpen, closeThreadMenu} = useChatbot();

  const conversationTitle = threads.find(t => t.thread_id === threadIdToDelete)?.thread_name;

  const onClose = () => {
    setIsDeleteThreadModalOpen(false);
    closeThreadMenu();
  };

  const handleDelete = async () => {
    onClose();
    return deleteThread(threadIdToDelete);
  };

  return (
    <BaseModal
      title="Supprimer une conversation"
      maxWidth="sm"
      onClose={onClose}
      open={open}
      sx={{
        zIndex: 9999
      }}
    >
      <DialogContent sx={{py: 3}}>
        <DialogContentText variant="inherit" lineHeight={1.4}>
          Etes-vous sûr de vouloir supprimer cette conversation :{' '}
          <span
            style={{
              fontFamily: FONT_FAMILIES.BODY,
              fontWeight: 'bold'
            }}
          >
            "{conversationTitle}"
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Supprimer
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default DeleteThreadModal;
