const SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES = {
  date: 'date',
  category: 'category',
  amount: 'amount',
  label: 'label',
  delete: 'delete'
};

const SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS = {
  date: {
    name: 'date',
    width: 250
  },
  amount: {
    name: 'amount',
    width: 250
  },
  delete: {
    name: 'delete',
    width: 50
  }
};

const SUMMARIES_SHARE_FULL_DATA_SPREADSHEET_COLUMNS = {
  date: {
    name: 'date',
    width: 150
  },
  category: {
    name: 'category',
    width: 150
  },
  label: {
    name: 'label',
    width: 150
  },
  amount: {
    name: 'amount',
    width: 150
  },
  delete: {
    name: 'delete',
    width: 50
  }
};

const getSummariesShareDataColumns = isAnonymized => {
  const cols = isAnonymized ? SUMMARIES_SHARE_ANONYMIZED_DATA_SPREADSHEET_COLUMNS : SUMMARIES_SHARE_FULL_DATA_SPREADSHEET_COLUMNS;
  let columnsToCreate = Object.values(cols);

  if (isAnonymized) {
    columnsToCreate = columnsToCreate.filter(c => c.name !== SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.label);
    return columnsToCreate.map(c => ({
      columnId: c.name,
      width: c.width,
      resizable: true
    }));
  }

  return columnsToCreate.map(c => ({
    columnId: c.name,
    width: c.width,
    resizable: true
  }));
};

const groupEntriesAmountsByDate = array => {
  const groupedMap = array.reduce((acc, item) => {
    const date = new Date(item.Mois);
    const dateKey = date.toLocaleDateString('fr-FR');

    if (acc.has(dateKey)) {
      const currentAmount = acc.get(dateKey).Montant;
      const newAmount = item.Montant + currentAmount;
      acc.set(dateKey, {
        ...item,
        Montant: newAmount
      });
    } else {
      acc.set(dateKey, {
        ...item,
        Montant: item.Montant
      });
    }

    return acc;
  }, new Map());

  return Array.from(groupedMap.values());
};

export {SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES, getSummariesShareDataColumns, groupEntriesAmountsByDate};
