/* eslint-disable react/boolean-prop-naming */
import React, {useState, useCallback, useEffect} from 'react';

import Box from '@mui/material/Box';
import {ReactGrid} from '@silevis/reactgrid';
import {arrayOf, bool, func, number, shape, string} from 'prop-types';
import '@silevis/reactgrid/styles.scss';
import '../../styles/spreadsheet/index.scss';

import {FONT_FAMILIES} from '../../const';
import useDimensions from '../../hooks/dom/useDimensions';
import FrenchDateCellTemplate from './cell-templates/FrenchDateCellTemplate';
import FrenchNumberCellTemplate from './cell-templates/FrenchNumberCellTemplate';

const BaseReactGrid = React.forwardRef(
  (
    {
      rows,
      columns,
      stickyTopRows,
      stickyLeftColumns,
      containerClassName,
      containerStyle,
      onFocusLocationChanged,
      onContextMenu,
      canReorderRows,
      onRowsReordered,
      onCellsChanged,
      enableFillHandle,
      enableRowSelection,
      customCellTemplates,
      initialFocusLocation,
      columnIdThatShouldTakeAvailableSpace
    },
    ref
  ) => {
    const [containerRef, {width}] = useDimensions();
    const [customColumnsWidth, setCustomColumnsWidth] = useState([]);
    const [columnsWithWidth, setColumnsWithWidth] = useState([]);

    const handleColumnResize = useCallback((columnId, widthAfterResize) => {
      setCustomColumnsWidth(prevColumnsWidth => {
        const columnIndex = prevColumnsWidth.findIndex(el => el.columnId === columnId);
        if (columnIndex === -1) {
          prevColumnsWidth.push({
            columnId,
            width: widthAfterResize
          });
        } else {
          const resizedColumn = prevColumnsWidth[columnIndex];
          const updatedColumn = {...resizedColumn, width: widthAfterResize};
          // eslint-disable-next-line no-param-reassign
          prevColumnsWidth[columnIndex] = updatedColumn;
        }
        return [...prevColumnsWidth];
      });
    }, []);

    const calculateAvailableWidth = () => {
      let widthAlreadyTaken = 0;
      columns.forEach(col => {
        const customColumnWidth = customColumnsWidth.find(w => w.columnId === col.columnId)?.width;
        const columnWidth = customColumnWidth ?? col.width;
        widthAlreadyTaken += columnWidth;
      });
      return width - widthAlreadyTaken;
    };

    const calculateColumnThatShouldExpandWidth = () => {
      const availableWidth = calculateAvailableWidth();
      const column = columns.find(c => c.columnId === columnIdThatShouldTakeAvailableSpace);

      return (column?.width || 0) + availableWidth;
    };

    useEffect(() => {
      if (!width) return;

      const cols = columns.map(col => {
        const customWidth = customColumnsWidth.find(w => w.columnId === col.columnId)?.width;

        if (columnIdThatShouldTakeAvailableSpace && col.columnId === columnIdThatShouldTakeAvailableSpace && !customWidth) {
          const expandedColumnWidth = calculateColumnThatShouldExpandWidth();
          return {...col, width: expandedColumnWidth};
        }
        return {...col, width: customWidth ?? col.width};
      });
      setColumnsWithWidth(cols);
    }, [columnIdThatShouldTakeAvailableSpace, width, columns, customColumnsWidth]);

    // TODO see with adrian if this should be configurable ?
    const secondaryLightColor = `${process.env.REACT_APP_SECONDARY_COLOR}1C`;
    const primaryLightColor = `${process.env.REACT_APP_MAIN_COLOR}66`;

    return (
      <Box
        ref={containerRef}
        className={containerClassName}
        style={containerStyle}
        id="reactgrid"
        sx={{
          '& .header-cell, .rg-headerCell-cell': {
            overflow: 'visible',
            background: `${process.env.REACT_APP_MAIN_COLOR} !important`,
            color: ' white !important',
            fontFamily: FONT_FAMILIES.TITLE
          },

          '& .cell-header-budget-year': {
            background: process.env.REACT_APP_MAIN_COLOR
          },

          '& .cell-header-budget-ratio': {
            background: process.env.REACT_APP_SECONDARY_COLOR
          },
          '& .cell-header-budget-month': {
            background: primaryLightColor,
            color: 'white !important',
            fontFamily: FONT_FAMILIES.TITLE
          },
          '& .cell-ratio': {
            background: secondaryLightColor,
            justifyContent: 'center',
            fontFamily: FONT_FAMILIES.BODY
          }
        }}
      >
        {columnsWithWidth.length > 0 ? (
          <ReactGrid
            enableRangeSelection
            ref={ref}
            rows={rows}
            columns={columnsWithWidth}
            stickyTopRows={stickyTopRows}
            stickyLeftColumns={stickyLeftColumns}
            onFocusLocationChanged={onFocusLocationChanged}
            onContextMenu={onContextMenu}
            canReorderRows={canReorderRows}
            onRowsReordered={onRowsReordered}
            onCellsChanged={onCellsChanged}
            enableFillHandle={enableFillHandle}
            enableRowSelection={enableRowSelection}
            onColumnResized={handleColumnResize}
            initialFocusLocation={initialFocusLocation}
            customCellTemplates={{
              frenchNumber: new FrenchNumberCellTemplate(),
              frenchDate: new FrenchDateCellTemplate(),
              ...customCellTemplates
            }}
            labels={{
              copyLabel: 'Copier',
              pasteLabel: 'Coller',
              cutLabel: 'Couper'
            }}
          />
        ) : null}
      </Box>
    );
  }
);

BaseReactGrid.defaultProps = {
  containerClassName: '',
  containerStyle: null,
  enableFillHandle: false,
  enableRowSelection: false,
  stickyTopRows: 0,
  stickyLeftColumns: 0,
  onFocusLocationChanged: null,
  onContextMenu: null,
  canReorderRows: null,
  onRowsReordered: null,
  onCellsChanged: null,
  customCellTemplates: null,
  rows: [],
  columns: [],
  initialFocusLocation: null,
  columnIdThatShouldTakeAvailableSpace: null
};

BaseReactGrid.propTypes = {
  containerClassName: string,
  columnIdThatShouldTakeAvailableSpace: string,
  containerStyle: shape({}),
  enableFillHandle: bool,
  enableRowSelection: bool,
  stickyTopRows: number,
  stickyLeftColumns: number,
  onFocusLocationChanged: func,
  onContextMenu: func,
  canReorderRows: func,
  onRowsReordered: func,
  onCellsChanged: func,
  customCellTemplates: shape({}),
  rows: arrayOf(shape({})),
  columns: arrayOf(shape({})),
  initialFocusLocation: shape({columnId: string.isRequired, rowId: string.isRequired})
};

export default BaseReactGrid;
