import {CloudUpload} from '@mui/icons-material';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useUpload from '../../../../hooks/providers/useUpload';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuImportDataButton from './ProjectMenuImportDataButton';

const ImportProjectDataButton = ({project}) => {
  const {setProjectSelected, setIsUploadFilesModalOpen} = useUpload();
  const {openModal} = useProjectCrudModal({setModalOpen: setIsUploadFilesModalOpen, project});

  const handleOpen = openModalFunction => {
    setProjectSelected(project);
    openModalFunction();
  };

  return (
    <ProjectActionButton cardMarkup={<ProjectMenuImportDataButton />} listMarkup={<CloudUpload sx={{cursor: 'pointer'}} fontSize="small" color="primary" onClick={() => handleOpen(openModal)} />} />
  );
};

ImportProjectDataButton.defaultProps = {
  project: null
};

ImportProjectDataButton.propTypes = {
  project: projectShape
};

export default ImportProjectDataButton;
