import {Dialog, DialogActions, DialogContent, Typography, DialogTitle, Divider} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

import {FONT_FAMILIES} from '../../../const';
import Button from '../../form/buttons/Button/Button';

const BoldText = styled.span`
  font-family: ${FONT_FAMILIES.BODY};
  font-weight: bold;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 0.8rem;

  li {
    font-family: ${FONT_FAMILIES.BODY};
    line-height: 1.5;
  }
`;

const PromoteUserToOwnerModal = ({isOpen, onCancel, onSubmit, username}) => {
  return (
    <Dialog maxWidth="sm" open={isOpen}>
      <DialogTitle>Rendre un utilisateur propriétaire</DialogTitle>
      <Divider sx={{my: 0.5}} />
      <DialogContent>
        <Typography sx={{mb: 1}}>
          Vous êtes sur le point de rendre <BoldText>{username}</BoldText> propriétaire de cette connexion. Il pourra effectuer l'ensemble des actions suivantes :
        </Typography>
        <List>
          <li>Mettre à jour les données</li>
          <li>Modifier les paramètres de la connexion</li>
          <li>Ajouter/supprimer un utilisateur/propriétaire</li>
        </List>
        <Typography sx={{mt: 1.5}}>Etes-vous sûr de vouloir effectuer cette action ?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onCancel}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Rendre propriétaire
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PromoteUserToOwnerModal.propTypes = {
  isOpen: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  username: string.isRequired
};

export default PromoteUserToOwnerModal;
