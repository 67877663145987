import {useEffect} from 'react';

import {InteractionStatus} from '@azure/msal-browser/dist/utils/BrowserConstants';
import {UnauthenticatedTemplate, useIsAuthenticated, useMsal} from '@azure/msal-react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import Spinner from '../components/spinner/Spinner';
import {APP_ROUTES, FONT_FAMILIES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useProfile from '../hooks/providers/useProfile';
import usePwa from '../hooks/providers/usePwa';
import LogoSvg from '../img/company-logo';
import rightImagePartners from '../img/macbook-pro-grey.png';
import rightImageDrivn from '../img/macbook-pro.png';
import {isMobile} from '../utils';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
`;

const ContainerLeft = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  background-color: ${process.env.REACT_APP_MAIN_COLOR};
  position: relative;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const ContainerRight = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  margin-top: 1vw;
  width: ${props => (props.size === 'small' ? '35vw' : '45vw')};
  height: ${props => (props.size === 'small' ? '35vw' : '45vw')};
  border-radius: 50%;
  background-color: rgba(21, 56, 101, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: ${props => (props.size === 'small' ? '45vw' : '55vw')};
    padding-right: 1vw;
  }
`;

const LoginButton = styled.button`
  margin-top: 10vh;
  background-color: ${process.env.REACT_APP_SECONDARY_COLOR};
  padding: 16px 109px;
  border-radius: 4px;
  color: #f6f9ff;
  border: none;
  font-family: ${FONT_FAMILIES.BODY};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const FooterText = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 85px;
  display: flex;
  color: #f6f9ff;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(246, 249, 255, 0.1);

  a {
    font-weight: 600;
    text-decoration-line: underline;
    color: #f6f9ff;
    margin-left: 6px;

    :hover {
      opacity: 0.7;
    }
  }
`;

// eslint-disable-next-line complexity
const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const context = useMsal();
  const {isConsentsLoading} = useProfile();
  const isLogged = useIsAuthenticated();
  const {webInstallPrompt} = usePwa();

  const signin = async () => {
    await auth.login();
  };

  useEffect(() => {
    (async () => {
      if (isLogged) {
        navigate(APP_ROUTES.connections, {replace: true});
      }
    })();
  }, [isLogged]);

  const isLoginRunning = document.cookie.length > 0 && document.cookie.includes('msal');
  const imageSrc = process.env.REACT_APP_COMPANY_NAME === 'drivn' ? rightImageDrivn : rightImagePartners;

  const isWebPwaPromptVisible = !navigator.standalone && webInstallPrompt && !isMobile();

  return (
    <Container>
      {!isLoginRunning && (
        <>
          {!isLogged && [InteractionStatus.Login, InteractionStatus.None].includes(context.inProgress) && (
            <UnauthenticatedTemplate>
              <ContainerLeft>
                <LogoSvg />
                <LoginButton type="button" onClick={signin}>
                  J'accède à l'application
                </LoginButton>
                <FooterText>
                  Besoin de plus d'information ? <a href={process.env.REACT_APP_ASK_ACCESS_URL}>Contactez-nous !</a>
                </FooterText>
              </ContainerLeft>
              <ContainerRight>
                <ImageContainer size={isWebPwaPromptVisible ? 'small' : 'large'}>
                  <img src={imageSrc} alt="" />
                </ImageContainer>
              </ContainerRight>
            </UnauthenticatedTemplate>
          )}

          {(context.inProgress === InteractionStatus.HandleRedirect || isLogged) && !isConsentsLoading && <Spinner size={128} withoutText isLoading />}
        </>
      )}
    </Container>
  );
};

export default Login;
