import {useState} from 'react';

import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import styled from 'styled-components';

import {FONT_FAMILIES} from '../../../const';
import {assistantShape} from '../../../proptypes-shapes';

const maxWidth = 500;
const minHeight = 110;
const pictureSize = 55;

const picturePixelsFromLeft = -(pictureSize / 2) - 3;
const Card = muiStyled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth,
  minHeight,
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '20px',
  border: `3px solid ${theme.palette.primary.main}`,
  '&:hover': {
    border: `3px solid ${theme.palette.secondary.main}`
  }
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const AssistantPicture = muiStyled(({isCardHovered, ...props}) => <Box {...props} />)(({theme, ...props}) => ({
  left: picturePixelsFromLeft,
  top: '50%',
  transform: 'translateY(-50%)',
  width: pictureSize,
  height: pictureSize,
  position: 'absolute',
  borderRadius: '50%',
  border: props.isCardHovered ? `3px solid ${theme.palette.secondary.main}` : '3px solid white',
  boxShadow: '0px 3px 6px #00000029'
}));

const DescriptionBlockquote = styled.blockquote`
  border-left: 4px solid lightgrey;
  line-height: 1.2;
  padding-left: 6px;
  font-style: italic;
  margin: 6px 4px 12px 0;
  color: #949494;
  font-size: 10px;
`;

const AssistantCard = ({assistant}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const textColor = isHovered ? theme.palette.secondary.main : theme.palette.primary.main;

  return (
    <Card onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <AssistantPicture isCardHovered={isHovered} src={assistant.url_photo} component="img" alt="assistant portrait" />
      <Box mt={1} ml={5}>
        <Typography fontFamily={FONT_FAMILIES.BODY} fontWeight="bold" fontSize={20} color={textColor}>
          {assistant.name}
        </Typography>
        <Typography fontFamily={FONT_FAMILIES.BODY} fontSize={12} color={textColor}>
          {assistant.role}
        </Typography>

        <DescriptionBlockquote>"{assistant.description}"</DescriptionBlockquote>
      </Box>
    </Card>
  );
};

AssistantCard.propTypes = {
  assistant: assistantShape.isRequired
};

export default AssistantCard;
