import {Grid, Typography} from '@mui/material';

import {FONT_FAMILIES} from '../../../const';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button/Button';

const SharedDataBlock = () => {
  const {shouldShareCustomData, setIsSummaryShareDataModalOpen, isDataFiltered} = useReportSummary();

  const spanColor = shouldShareCustomData ? 'warning.main' : 'success.main';
  const dataTypeLabel = shouldShareCustomData ? 'du plan personnalisé' : 'du plan général';

  return (
    <Grid item>
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography fontFamily={FONT_FAMILIES.BODY} fontWeight="bold" fontSize={15}>
            Données{' '}
            <Typography component="span" color={spanColor} fontWeight="inherit" fontSize="inherit">
              {dataTypeLabel}
            </Typography>{' '}
            partagées {isDataFiltered ? '(filtrées)' : ''}
          </Typography>
        </Grid>

        <Grid item xs={3} ml={1}>
          <Button onClick={() => setIsSummaryShareDataModalOpen(true)} fullWidth size="small" variant="outlined">
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SharedDataBlock;
