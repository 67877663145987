import {createTheme} from '@mui/material';
import {grey} from '@mui/material/colors';

import {FONT_FAMILIES} from '../../../const';

const createButtonTheme = (parentTheme, color) => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette) and use it
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '2rem',
            fontFamily: FONT_FAMILIES.TITLE,
            '&.Mui-disabled': {
              background: color !== 'inherit' && parentTheme.palette[color].main,
              color: color !== 'white' ? '#fff' : 'inherit',
              opacity: 0.6,
              cursor: 'not-allowed',
              pointerEvents: 'all',
              '&:hover': {
                borderWidth: 1
              }
            }
          },
          outlined: {
            borderWidth: 2,
            '&:hover': {
              borderWidth: 2
            },
            '&.Mui-disabled': {
              background: 'white !important',
              color: `${grey[500]} !important`
            }
          }
        }
      }
    }
  });
};

export {createButtonTheme};
