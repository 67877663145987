import {Refresh, Update, UpdateDisabled, EventRepeat} from '@mui/icons-material';
import {MenuItem, ListItemIcon, Divider, Menu} from '@mui/material';
import Typography from '@mui/material/Typography';
import {NestedMenuItem} from 'mui-nested-menu';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import {getLastDayOfPreviousMonthFrenchFormatted} from '../../../../utils';

// eslint-disable-next-line react/prop-types
const MenuContainer = ({isNested, ...props}) => {
  const WrapperComponent = isNested ? NestedMenuItem : Menu;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrapperComponent {...props} />;
};

const ActualizeDataMenu = ({isNested, anchorEl, isOpen, onClose}) => {
  const {selectedProject: project, projectsSirenCurrentlyRefreshing, setActualizeProjectDataDatePickerOpen, closeProjectMenu, CONNECTORS_WITHOUT_ACTUALIZE_DATA_BUTTON} = useProjects();
  const {runManualProjectDataRefresh, enableOrDisableProjectAutoRefresh} = useProjects();
  const shouldRender = project && !CONNECTORS_WITHOUT_ACTUALIZE_DATA_BUTTON.includes(project?.connector);

  if (!shouldRender) {
    return null;
  }

  const isProjectRefreshing = projectsSirenCurrentlyRefreshing.includes(project.siren);
  const lastDayOfPreviousMonth = getLastDayOfPreviousMonthFrenchFormatted();
  const classname = isNested ? 'nested-menu-item' : '';

  const isFecSchema = project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion;
  const manualUpdateListItemText = isFecSchema ? `Actualiser au ${lastDayOfPreviousMonth}` : 'Actualiser';

  return (
    <MenuContainer
      id="project-menu-listview"
      anchorEl={isNested ? null : anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={onClose}
      isNested={isNested}
      className={classname}
      leftIcon={<Refresh fontSize="small" color="primary" />}
      label="Actualiser les données"
      parentMenuOpen
    >
      <MenuItem
        key={0}
        onClick={() => {
          runManualProjectDataRefresh(project);
          closeProjectMenu();
        }}
        disabled={isProjectRefreshing}
      >
        <ListItemIcon>
          <Refresh fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography>{manualUpdateListItemText}</Typography>
      </MenuItem>

      {isFecSchema ? (
        <MenuItem
          key={1}
          disabled={isProjectRefreshing}
          onClick={() => {
            setActualizeProjectDataDatePickerOpen(true);
          }}
        >
          <ListItemIcon>
            <EventRepeat fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography>Actualiser à une date choisie...</Typography>
        </MenuItem>
      ) : null}

      <Divider />
      <MenuItem
        key={2}
        onClick={() => {
          enableOrDisableProjectAutoRefresh(project);
          closeProjectMenu();
        }}
      >
        <ListItemIcon>{project.auto_update ? <UpdateDisabled fontSize="small" color="primary" /> : <Update fontSize="small" color="primary" />}</ListItemIcon>
        <Typography>{project.auto_update ? 'Désactiver' : 'Activer'} la mise à jour automatique</Typography>
      </MenuItem>
    </MenuContainer>
  );
};

ActualizeDataMenu.defaultProps = {
  anchorEl: null,
  onClose: null,
  isOpen: false
};

ActualizeDataMenu.propTypes = {
  isNested: bool.isRequired,
  isOpen: bool,
  anchorEl: oneOfType([object, element, node, oneOf([null])]),
  onClose: func
};

export default ActualizeDataMenu;
