import {Typography} from '@mui/material';
import {bool, string} from 'prop-types';

import {FONT_FAMILIES} from '../../../../const';
import AssistantAnswerState from '../../AssistantAnswerState/AssistantAnswerState';

const MessageHeader = ({messageAuthor, assistantHasJustAnsweredNow}) => {
  return (
    <Typography textAlign="left" color="primary" fontFamily={FONT_FAMILIES.TITLE} fontSize={15} mb={1} mt={1.5}>
      {messageAuthor}
      {assistantHasJustAnsweredNow && <AssistantAnswerState stateText="écrit" />}
    </Typography>
  );
};

MessageHeader.propTypes = {
  messageAuthor: string.isRequired,
  assistantHasJustAnsweredNow: bool.isRequired
};

export default MessageHeader;
