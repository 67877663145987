import {Grid} from '@mui/material';

import {BREAKPOINTS} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import usePayment from '../../../../hooks/providers/usePayment';
import useProjects from '../../../../hooks/providers/useProjects';
import useWorkspaces from '../../../../hooks/providers/useWorkspaces';
import SearchBar from '../../../form/SearchBar/SearchBar';
import ConnectionsCounter from '../ConnectionsCounter/ConnectionsCounter';
import ConnectionsGroupingTypeToggler from '../ConnectionsGroupingTypeToggler/ConnectionsGroupingTypeToggler';
import ConnectionsViewTypeToggler from '../ConnectionsViewTypeToggler/ConnectionsViewTypeToggler';
import CreateConnectionButton from '../CreateConnectionButton/CreateConnectionButton';

// eslint-disable-next-line complexity
const ConnectionsToolbar = () => {
  const {searchedConnectionTerm: searchedProject, setSearchedConnectionTerm: setSearchedProject, projects} = useProjects();
  const {isGuest} = usePayment();
  const {isDefaultWorkspace} = useWorkspaces();

  const breakpoint = useBreakpoint();

  const userHasAtLeastOneProject = projects.length > 0;

  return (
    <Grid flexWrap="wrap" container mt={2} spacing={breakpoint === BREAKPOINTS.xs ? 0.5 : 2} alignItems="center" justifyContent="center">
      {userHasAtLeastOneProject ? (
        <Grid item xs minWidth={breakpoint === BREAKPOINTS.xs ? '100%' : 300} sx={{maxWidth: {xs: 330, sm: '100%'}, width: '100%'}}>
          <SearchBar placeholder="Recherchez un dossier, un utilisateur, une connexion ..." value={searchedProject} setValue={setSearchedProject} />
        </Grid>
      ) : null}

      <Grid item sm="auto" md="auto">
        <Grid container alignItems="center" mt={breakpoint === BREAKPOINTS.xs ? 1.5 : 0}>
          {isDefaultWorkspace && userHasAtLeastOneProject ? (
            <Grid item>
              <ConnectionsGroupingTypeToggler />
            </Grid>
          ) : null}

          {userHasAtLeastOneProject ? (
            <Grid item sx={{display: {xs: 'none', sm: 'flex'}, mr: 1}}>
              <ConnectionsViewTypeToggler />
            </Grid>
          ) : null}

          <Grid item>
            <Grid container flexDirection="column" alignItems="center">
              <CreateConnectionButton />
            </Grid>
          </Grid>

          <Grid item ml={{xs: 0.5, sm: 2}}>
            {!isGuest && userHasAtLeastOneProject ? <ConnectionsCounter /> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectionsToolbar;
