import {Grid, Typography} from '@mui/material';

import {APP_ROUTES, FONT_FAMILIES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import Button from '../../form/buttons/Button/Button';
import Link from '../../link/Link';
import ChatbotDimmerWarningDataPrivacy from '../ChatbotDimmerWarningDataPrivacy/ChatbotDimmerWarningDataPrivacy';

const ConversationContentMaximumNumberOfConversationReached = () => {
  const {setChatbotSidebarOpen: setOpen, setIsFullScreenMode, currentThreadId, setIsTalksListDrawerOpen} = useChatbot();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Grid item flex={1} display="flex" position="relative" alignItems="center">
      <ChatbotDimmerWarningDataPrivacy />

      {!currentThreadId && (
        <Grid container flexDirection="column" alignItems="center" flex={1}>
          <Typography variant="h3" fontSize={20} fontFamily={FONT_FAMILIES.TITLE} textAlign="center" px={1}>
            Vous souhaitez créer de nouvelles conversations ?
          </Typography>

          <Button onClick={handleDrawerClose} to={APP_ROUTES.profile} component={Link} sx={{mt: 4}} variant="contained" color="secondary">
            Sélectionner un abonnement
          </Button>
          <Button
            onClick={() => {
              // Open drawer talks list for mobile
              setIsTalksListDrawerOpen(true);
              // Sets full screen for desktop
              setIsFullScreenMode(true);
            }}
            sx={{textDecoration: 'underline'}}
            size="small"
            variant="text"
            color="primary"
          >
            Voir mes conversations
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ConversationContentMaximumNumberOfConversationReached;
