import {Grid} from '@mui/material';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Checkbox from '../../form/Checkbox/Checkbox';

const AnalysisConsentsCheckboxes = () => {
  const {
    userHasConsentedToDataAnalysisByAI,
    shouldReplaceExistingSummary,
    setShouldReplaceExistingSummary,
    userHasCheckedDataIsAnonymous,
    setUserHasConsentedToDataAnalysisByAI,
    setUserHasCheckedDataIsAnonymous
  } = useReportSummary();

  return (
    <Grid container flexDirection="column" spacing={1} my={1} ml={1}>
      <Checkbox label="J'ai vérifié l'absence de données personnelles dans mes données" checked={userHasCheckedDataIsAnonymous} onChange={e => setUserHasCheckedDataIsAnonymous(e.target.checked)} />
      <Checkbox
        label="J'accepte que mes données soient traitées pour générer une analyse par IA conformément à la Politique de confidentialité."
        checked={userHasConsentedToDataAnalysisByAI}
        onChange={e => setUserHasConsentedToDataAnalysisByAI(e.target.checked)}
      />

      <Checkbox label="Remplacer l'analyse existante" checked={shouldReplaceExistingSummary} onChange={e => setShouldReplaceExistingSummary(e.target.checked)} />
    </Grid>
  );
};

export default AnalysisConsentsCheckboxes;
