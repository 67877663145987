import CloseIcon from '@mui/icons-material/CloseOutlined';
import {Dialog, Card, CardContent, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {FONT_FAMILIES} from '../../const';
import usePwa from '../../hooks/providers/usePwa';
import {isMobile} from '../../utils';
import AndroidPwaPrompt from './android-prompt/AndroidPwaPrompt';
import IosPwaPrompt from './ios-prompt/IosPwaPrompt';

const InstallPwaModal = () => {
  const {iosInstallPrompt, webInstallPrompt, handleIosInstallDecline, handleWebInstallDecline} = usePwa();

  if ((!iosInstallPrompt && !webInstallPrompt) || !isMobile()) return null;

  const paperProps = {
    sx: {
      width: '100%',
      position: 'absolute',
      margin: 0,
      bottom: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  };

  const onDecline = iosInstallPrompt ? handleIosInstallDecline : handleWebInstallDecline;

  return (
    <Dialog open fullWidth maxWidth="xl" PaperProps={paperProps}>
      <Card>
        <CardContent sx={{p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Grid container justifyContent="space-between">
            <span />
            <Box sx={{height: 5, width: 96, background: '#232222', borderRadius: 10, mt: 1, mb: 2}} />
            <CloseIcon color="primary" onClick={onDecline} />
          </Grid>

          <Box pb={2}>
            <Typography px={1} mt={1} variant="h1" fontSize={24} fontFamily={FONT_FAMILIES.TITLE} color="primary" align="center" gutterBottom>
              Optimisez votre expérience
            </Typography>

            <Typography lineHeight={1.5} mt={2} variant="h3" fontSize={16} fontFamily={FONT_FAMILIES.BODY} color="primary" align="center" gutterBottom>
              Ajouter ce site Web à votre écran d'accueil pour profiter de toutes ses fonctionnalités d'application. <br />
              Utilisez-le en plein écran, même hors-ligne pour certaines fonctionnalités. Une immersion totale, à portée de clic !
            </Typography>
          </Box>

          {iosInstallPrompt && <IosPwaPrompt />}
          {webInstallPrompt && !iosInstallPrompt && <AndroidPwaPrompt />}
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default InstallPwaModal;
