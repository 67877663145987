import {useEffect} from 'react';

import {Box, Alert} from '@mui/material';

import useProfile from '../../../hooks/providers/useProfile';
import useProjects from '../../../hooks/providers/useProjects';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import AddUserToProjectOrWorkspace from '../../users/AddUserToProjectOrWorkspace';
import DeleteUserModal from '../../users/DeleteUserModal';
import ProjectUsersList from './ProjectUsersList';
import PromoteUserToOwnerModal from './PromoteUserToOwnerModal';

const ManageProjectUsersModal = () => {
  const {profile} = useProfile();
  const {
    closeDeleteUserModal: closeDeleteModal,
    openPromoteUserModal: openPromoteModal,
    closePromoteUserModal: closePromoteModal,
    addUserToProject,
    deleteUserFromProject,
    promoteOrDemoteUser,
    closeProjectMenu,
    selectedProject: project,
    manageProjectUsersModalOpen: open,
    setManageProjectUsersModalOpen: setOpen,
    setUpdateProjectUsersSuccess,
    emailError,
    setEmailError,
    isUserActionSubmitting: isSubmitting,
    userInvitedSuccessMessage,
    deleteUserModalOpen,
    userToDelete,
    promoteModalOpen,
    userToPromote,
    projects
  } = useProjects();
  const {users} = projects.find(p => p.siren === project.siren && p.schema_name === project.schema_name);

  const onClose = () => {
    setOpen(false);
    closeProjectMenu();
  };

  const addUser = async mail => {
    const {siren, schema_name: schema} = project;
    const data = {
      email: mail.toLowerCase(),
      siren,
      schema
    };
    const res = await addUserToProject(data);

    return res;
  };

  const deleteUser = async userId => {
    const {siren, schema_name: schema} = project;
    const isUserDeletingHimSelf = userId.toLowerCase() === profile.id.toLowerCase();
    const data = {
      userId,
      siren,
      schema
    };
    const result = await deleteUserFromProject(data, isUserDeletingHimSelf);

    if (result.status === 200 && isUserDeletingHimSelf) {
      onClose();
    }
  };

  const promoteOrDemote = async (userId, isPromoting) => {
    const {siren, schema_name: schema} = project;
    const data = {
      userId,
      isOwner: isPromoting ? 1 : 0,
      siren,
      schema
    };
    return promoteOrDemoteUser(data);
  };

  const promoteUser = async userId => {
    closePromoteModal();
    await promoteOrDemote(userId, true);
  };

  const demoteUser = async userId => {
    await promoteOrDemote(userId, false);
  };

  useEffect(() => {
    setUpdateProjectUsersSuccess(false);
  }, []);

  return (
    <BaseModal title="Gérer les utilisateurs" onClose={onClose} open={open}>
      {deleteUserModalOpen && (
        <DeleteUserModal isOpen username={users?.find(u => u.user_id === userToDelete)?.username} onSubmit={() => deleteUser(userToDelete)} onCancel={closeDeleteModal} entity="cette connexion" />
      )}

      {promoteModalOpen && (
        <PromoteUserToOwnerModal username={users?.find(u => u.user_id === userToPromote)?.username} isOpen onSubmit={() => promoteUser(userToPromote)} onCancel={closePromoteModal} />
      )}

      <Box sx={{padding: 2, marginTop: 2}}>
        <Box px={3}>
          <AddUserToProjectOrWorkspace addUser={addUser} emailError={emailError} setEmailError={setEmailError} isSubmitting={isSubmitting} />

          <ProjectUsersList
            users={users.filter(u => !u.is_owner)}
            promoteUser={openPromoteModal}
            title="Liste des invités"
            subtitle="Pour ajouter un propriétaire, cliquez sur le mail d'un utilisateur ci dessous, puis sélectionnez : 'Rendre propriétaire de la connexion' "
            titleTooltip="Vous pouvez supprimer un utilisateur en cliquant sur l'icône croix à côté de son email"
          />

          <ProjectUsersList
            users={users.filter(u => u.is_owner)}
            demoteUser={demoteUser}
            title="Liste des propriétaires"
            titleTooltip="Vous pouvez supprimer un propriétaire en cliquant sur l'icône croix à côté de son email"
          />

          <Spinner isLoading={isSubmitting} />
        </Box>
      </Box>
      {userInvitedSuccessMessage && (
        <Alert variant="filled" severity="success">
          {userInvitedSuccessMessage}
        </Alert>
      )}
    </BaseModal>
  );
};

export default ManageProjectUsersModal;
