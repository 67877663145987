import {bool, string} from 'prop-types';

import {LOGO_THEME_VARIANTS} from '../const';

const ASSETS_BASE_URL = 'https://drivnb2c.blob.core.windows.net/logos';
const LOGO_FILENAME = 'logo.svg';
const SYMBOL_FILENAME = 'symbol.svg';
const SYMBOL_DARK_FILENAME = 'symbol-dark.png';

const getFileName = (variant, isSymbol) => {
  let filename;

  if (!isSymbol) {
    filename = LOGO_FILENAME;
  } else {
    filename = variant === LOGO_THEME_VARIANTS.dark ? SYMBOL_DARK_FILENAME : SYMBOL_FILENAME;
  }

  return filename;
};

const getFilePath = (variant, isSymbol) => {
  const filename = getFileName(variant, isSymbol);
  const filepath = `${ASSETS_BASE_URL}/${process.env.REACT_APP_COMPANY_NAME}/${filename}`;

  return filepath;
};

const Logo = ({variant, isSymbol, ...props}) => {
  const filePath = getFilePath(variant, isSymbol);

  if (!isSymbol) {
    return <img src={filePath} className="logo" alt={`${process.env.REACT_APP_COMPANY_NAME}`} />;
  }

  if (variant === LOGO_THEME_VARIANTS.dark) {
    return <img {...props} alt={`${process.env.REACT_APP_COMPANY_NAME}`} src={filePath} />;
  }

  return <img src={filePath} alt={`${process.env.REACT_APP_COMPANY_NAME}`} />;
};

Logo.defaultProps = {
  variant: LOGO_THEME_VARIANTS.light,
  isSymbol: false
};

Logo.propTypes = {
  variant: string,
  isSymbol: bool
};

export default Logo;
