import {Typography} from '@mui/material';
import styled from 'styled-components';

import {FONT_FAMILIES} from '../../const';

const TabContainer = styled.div`
  margin-top: 32px;

  h2 {
    text-align: center;
    font: normal normal 300 26px/40px ${FONT_FAMILIES.TITLE};
    opacity: 0.65;
  }
`;

const NoConnectionsFound = () => {
  return (
    <TabContainer>
      <Typography variant="h2">Aucune connexion ne correspond à vos critères de recherche ...</Typography>
    </TabContainer>
  );
};

export default NoConnectionsFound;
