import CloseIcon from '@mui/icons-material/Close';
import {Divider, Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {styled as muiStyled} from '@mui/material/styles';

import {FONT_FAMILIES} from '../../../const';
import useReport from '../../../hooks/providers/useReport';

const TopBarDividerContainer = muiStyled(Box)(() => ({
  width: '95%',
  margin: '0 auto'
}));

const ReportModulesHeader = () => {
  const {setIsReportModulesSidebarOpen: setIsOpen} = useReport();

  return (
    <Grid item>
      <Grid container justifyContent="space-between" alignItems="center" py={0.5}>
        <Box />
        <Typography textAlign="center" fontFamily={FONT_FAMILIES.TITLE} fontSize={20}>
          Boîte à outils
        </Typography>
        <IconButton edge="start" color="inherit" onClick={() => setIsOpen(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Grid>
      <TopBarDividerContainer>
        <Divider />
      </TopBarDividerContainer>
    </Grid>
  );
};

export default ReportModulesHeader;
