import {memo, useEffect, useRef, useState} from 'react';

import {ExpandLess, ExpandMore} from '@mui/icons-material';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {ClickAwayListener, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Popover, Popper, TextField, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {bool} from 'prop-types';

import {FONT_FAMILIES} from '../../../const';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import {getSummariesShareDataColumns, groupEntriesAmountsByDate, SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES, getOldestDate} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import SearchBar from '../../form/SearchBar/SearchBar';
import BaseReactGrid from '../../spreadsheets/BaseReactGrid';
import DeleteCellTemplate from '../../spreadsheets/cell-templates/DeleteCellTemplate';
import HeaderCell from '../../spreadsheets/cell-templates/HeaderCell';
import TooltipTextCellTemplate from '../../spreadsheets/cell-templates/TooltipTextCellTemplate';
import NumberRangeFilter from '../../spreadsheets/filters/NumberRangeFilter';

const formatDataToAccountingCompliantFormat = spreadsheetData => {
  const accountingCompliantFormattedData = spreadsheetData.map(entry => ({
    Mois: entry.date,
    Compte: entry.label,
    Montant: entry.amount,
    Catégorie: entry.category,
    Theme: entry.theme
  }));
  return accountingCompliantFormattedData;
};

const COLUMNS_IDS = {
  month: 'Mois',
  category: 'Catégorie',
  label: 'Libellé',
  amount: 'Montant'
};

const SummaryDataSpreadsheet = ({isAnonymizedData}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [rows, setRows] = useState([]);
  const [filtersModalOpenedId, setFiltersModalOpenedId] = useState(null);

  const {
    dataToShare,
    setDataToShare,
    setFilteredDataToShare: setFilteredData,
    anonymizedDataToShare,
    setAnonymizedDataToShare,
    setIsDataFiltered,
    checkedThemes,
    setCheckedThemes,
    expandedThemes,
    setExpandedThemes,
    checkedCategories,
    setCheckedCategories,
    searchedLabel,
    setSearchedLabel,
    filtersAnchorEl,
    setFiltersAnchorEl,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange
  } = useReportSummary();
  const data = isAnonymizedData ? anonymizedDataToShare : dataToShare;
  const updateData = isAnonymizedData ? setAnonymizedDataToShare : setDataToShare;

  const lowestAmount = data.reduce((min, obj) => (obj.Montant < min.Montant ? obj : min), data[0])?.Montant;
  const highestAmount = data.reduce((max, obj) => (obj.Montant > max.Montant ? obj : max), data[0])?.Montant;
  const categoriesList = Array.from(
    new Map(
      data.map(({Theme, Catégorie}) => [
        Catégorie,
        {
          Theme,
          Catégorie
        }
      ])
    ).values()
  );

  const getOrderedThemesList = () => {
    const themesList = [...new Set([...data.map(e => e.Theme)])];

    // Business need, CA theme must appear first but alphabetically it's the last in the themes array so we manually pop / unshift it
    const caTheme = themesList.pop();
    themesList.unshift(caTheme);

    return themesList;
  };

  const themesList = getOrderedThemesList();

  const allCategoriesAndThemesChecked = themesList.every(t => checkedThemes.includes(t)) && categoriesList.every(c => checkedCategories.includes(c.Catégorie));
  const zeroCategoriesOrThemesChecked = themesList.every(t => !checkedThemes.includes(t)) && categoriesList.every(c => !checkedCategories.includes(c.Catégorie));

  const [amountRange, setAmountRange] = useState([lowestAmount, highestAmount]);

  const deleteRow = rowId => {
    setFormattedData(currentData => {
      const updatedData = [...currentData.filter(entry => entry.id !== rowId)];
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  const hasColumnFiltersApplied = columnId => {
    switch (columnId) {
      case COLUMNS_IDS.category:
        return checkedCategories.length > 0;
      case COLUMNS_IDS.label:
        return searchedLabel !== '';
      case COLUMNS_IDS.amount:
        return amountRange[0] !== lowestAmount || amountRange[1] !== highestAmount;
      case COLUMNS_IDS.month:
        return startDateRange !== null || endDateRange !== null;
      default:
        return false;
    }
  };

  const isAtLeastOneFilterApplied = () => {
    return Object.values(COLUMNS_IDS).some(colId => hasColumnFiltersApplied(colId));
  };

  const handleToggleTheme = value => () => {
    setCheckedThemes(prevCheckedThemes => {
      const currentIndex = prevCheckedThemes.indexOf(value);
      const themeCategories = categoriesList.filter(c => c.Theme === value).map(c => c.Catégorie);

      if (currentIndex === -1) {
        setCheckedCategories(prevCheckedCategories => {
          return [...prevCheckedCategories, ...themeCategories];
        });
        return [...prevCheckedThemes, value];
      }
      setCheckedCategories(prevCheckedCategories => {
        return [...prevCheckedCategories].filter(c => !themeCategories.includes(c));
      });
      return prevCheckedThemes.filter(item => item !== value);
    });
  };

  const handleToggleCategory = value => () => {
    setCheckedCategories(prevCheckedCategories => {
      const currentIndex = prevCheckedCategories.indexOf(value);

      if (currentIndex === -1) {
        return [...prevCheckedCategories, value];
      }
      return prevCheckedCategories.filter(item => item !== value);
    });
  };

  const getOldestAccountingEntry = () => {
    const dates = data.map(entry => entry.Mois);
    const oldestDate = getOldestDate(dates.filter(item => item !== null));

    return dayjs(oldestDate);
  };

  const isEntryWithinDateRange = entry => {
    const formattedEntryDate = dayjs(entry.Mois);
    const startDate = startDateRange || getOldestAccountingEntry();
    const endDate = endDateRange || dayjs();

    return formattedEntryDate.isBetween(startDate, endDate);
  };

  // TODO maybe delocate this inside a hook and instead of using columns ids, use a TYPE prop
  // That determines if we render :
  // A list of items with checkboxes
  // A range, datepicker or something (for dates)
  // An input number with a range again (between XX and XX) (for number)
  // A searchbar allowing to enter a string (for label, all string related things)
  const renderFiltersModal = () => {
    switch (filtersModalOpenedId) {
      case COLUMNS_IDS.month:
        return (
          <Box p={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <Grid container flexWrap="nowrap">
                <DatePicker
                  views={['month', 'year']}
                  label="Début"
                  value={startDateRange}
                  onChange={newValue => {
                    setStartDateRange(newValue);
                  }}
                  renderInput={params => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                />
                <Box ml={2}>
                  <DatePicker
                    views={['month', 'year']}
                    label="Fin"
                    value={endDateRange}
                    onChange={newValue => {
                      setEndDateRange(newValue);
                    }}
                    renderInput={params => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
                  />
                </Box>
              </Grid>
            </LocalizationProvider>
          </Box>
        );
      case COLUMNS_IDS.category:
        return (
          <Box>
            <Grid container flexWrap="nowrap" pt={1}>
              <Button
                disabled={allCategoriesAndThemesChecked}
                sx={{
                  fontSize: 11
                }}
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  setCheckedThemes(themesList);
                  setCheckedCategories(categoriesList.map(c => c.Catégorie));
                }}
              >
                Sélectionner tout
              </Button>
              <Button
                disabled={zeroCategoriesOrThemesChecked}
                sx={{
                  ml: 1,
                  fontSize: 11
                }}
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  setCheckedThemes([]);
                  setCheckedCategories([]);
                }}
              >
                Désélectionner tout
              </Button>
            </Grid>

            <List dense sx={{bgcolor: 'background.paper'}}>
              {themesList.map(value => {
                const labelId = `checkbox-list-label-${value}`;
                const themeCategories = categoriesList.filter(c => c.Theme === value);
                const shouldDisplayCategories = expandedThemes.includes(value);
                return (
                  <>
                    <ListItem key={value} disablePadding>
                      <ListItemButton role={undefined} onClick={handleToggleTheme(value)} dense>
                        <ListItemIcon>
                          <Checkbox edge="start" checked={checkedThemes.includes(value)} tabIndex={-1} disableRipple inputProps={{'aria-labelledby': labelId}} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{fontFamily: FONT_FAMILIES.BODY, fontWeight: 'bold'}} id={labelId} primary={value} />
                      </ListItemButton>
                      <Box mr={1}>
                        {expandedThemes.includes(value) ? (
                          <IconButton onClick={() => setExpandedThemes(prevExpanded => [...prevExpanded].filter(v => v !== value))} edge="end" aria-label="ouvrir">
                            <ExpandLess />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setExpandedThemes(prevExpanded => [...prevExpanded, value])} edge="end" aria-label="fermer">
                            <ExpandMore />
                          </IconButton>
                        )}
                      </Box>
                    </ListItem>
                    {shouldDisplayCategories ? (
                      <Box ml={3}>
                        {themeCategories.map(({Catégorie: category}) => {
                          const labelIdCategory = `checkbox-list-label-${category}`;
                          return (
                            <ListItem key={category} disablePadding>
                              <ListItemButton role={undefined} onClick={handleToggleCategory(category)} dense>
                                <ListItemIcon>
                                  <Checkbox size="small" edge="start" checked={checkedCategories.includes(category)} tabIndex={-1} disableRipple inputProps={{'aria-labelledby': labelIdCategory}} />
                                </ListItemIcon>
                                <ListItemText
                                  primaryTypographyProps={{
                                    fontSize: 13,
                                    fontStyle: category ?? 'italic'
                                  }}
                                  id={labelId}
                                  primary={category ?? '(vides)'}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </Box>
                    ) : null}
                  </>
                );
              })}
            </List>
          </Box>
        );
      case COLUMNS_IDS.label:
        return (
          <Box p={2.5}>
            <Typography mb={2} fontSize={13}>
              Filtrez les résultats par libellé
            </Typography>
            <SearchBar placeholder="Recherchez une ligne" value={searchedLabel} setValue={setSearchedLabel} />
          </Box>
        );
      case COLUMNS_IDS.amount: {
        return (
          <Box p={2.5}>
            <Typography mb={2} fontSize={13}>
              Sélectionnez une fourchette pour filtrer les résultats
            </Typography>
            <NumberRangeFilter min={lowestAmount} max={highestAmount} value={amountRange} setValue={setAmountRange} />

            <Box mt={3}>
              <Button
                sx={{fontSize: 12}}
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  setAmountRange([lowestAmount, highestAmount]);
                }}
              >
                Réinitialiser
              </Button>
            </Box>
          </Box>
        );
      }
      default:
        return null;
    }
  };

  const getHeaderRow = () => {
    const cells = [];
    const columnsHiddenForAnonymizedData = [COLUMNS_IDS.category, COLUMNS_IDS.label];
    Object.values(COLUMNS_IDS).forEach(columnId => {
      if (!(isAnonymizedData && columnsHiddenForAnonymizedData.includes(columnId)))
        cells.push({
          type: 'headerCell',
          text: columnId,
          className: 'header-cell',
          isOpen: filtersModalOpenedId === columnId,
          hasFilters: hasColumnFiltersApplied(columnId),
          onOpen: e => {
            setFiltersModalOpenedId(columnId);
            setFiltersAnchorEl(e.currentTarget);
          }
        });
    });

    return {
      rowId: 'header',
      height: 40,
      cells: [...cells, {type: 'header', text: ' ', className: 'header-cell'}]
    };
  };

  const headerRow = getHeaderRow();

  const getRows = accountingData => {
    const r = [];

    accountingData.forEach(entry => {
      const categoryCell = {nonEditable: true, type: 'text', text: entry.category};
      const labelCell = {
        type: 'tooltipTextCell',
        tooltipContent: 'Double-cliquez pour modifier le libellé',
        text: entry.label
      };

      r.push({
        rowId: entry.id,
        height: 40,
        cells: [
          {
            nonEditable: true,
            type: 'text',
            text: entry.date
          },
          ...(!isAnonymizedData ? [categoryCell, labelCell] : []),
          {
            nonEditable: true,
            type: 'frenchNumber',
            value: entry.amount
          },
          {type: 'deleteRow', onDelete: () => deleteRow(entry.id)}
        ]
      });
    });

    return [headerRow, ...r];
  };

  const applyChangesToEntries = (changes, previousData) => {
    const updatedData = [...previousData];

    changes.forEach(change => {
      const {rowId, columnId} = change;

      const oldValue = change.previousCell.text;
      const newValue = change.newCell.text;

      if (newValue !== oldValue) {
        const itemToUpdateIndex = updatedData.findIndex(item => item.id === rowId);
        const updatedItem = updatedData[itemToUpdateIndex];
        updatedItem[columnId] = newValue;
        updatedData[itemToUpdateIndex] = updatedItem;
      }
    });

    return [...updatedData];
  };

  const handleChanges = changes => {
    setFormattedData(previousData => {
      const updatedData = applyChangesToEntries(changes, previousData);
      // reformat data to an accounting-compliant format before sending to API
      const accountingCompliantFormattedData = formatDataToAccountingCompliantFormat(updatedData);
      updateData(accountingCompliantFormattedData);
      return updatedData;
    });
  };

  // Initial formatting / setting spreadsheet data from props raw data to a cleaner / more usable format
  useEffect(() => {
    let filteredEntries = data;

    // Apply categories filter
    if (checkedCategories.length > 0) {
      filteredEntries = data.filter(entry => checkedCategories.includes(entry.Catégorie));
    }

    // Apply label filter
    if (searchedLabel) {
      filteredEntries = filteredEntries.filter(entry => entry.Compte.toLowerCase().includes(searchedLabel?.toLowerCase()));
    }

    // Apply dates filter
    if (startDateRange || endDateRange) {
      filteredEntries = filteredEntries.filter(entry => isEntryWithinDateRange(entry));
    }

    const dataToFormat = isAnonymizedData ? groupEntriesAmountsByDate(filteredEntries) : filteredEntries;

    // Apply amount range filter
    if (amountRange.length === 2) {
      filteredEntries = dataToFormat.filter(entry => entry.Montant >= amountRange[0] && entry.Montant <= amountRange[1]);
    }

    setFilteredData(filteredEntries);

    const dataAfterFormatting = filteredEntries.map((entry, index) => ({
      date: entry.Mois,
      category: entry.Catégorie || '',
      amount: parseFloat(entry.Montant),
      label: entry.Compte,
      theme: entry.Theme,
      id: index
    }));
    setFormattedData(dataAfterFormatting);
  }, [data, checkedCategories, searchedLabel, startDateRange, endDateRange, amountRange, isAnonymizedData]);

  // This hook tracks changes on all filters, and sets bool to determine is data is currently filtered.
  // We need this information in other components up in the tree so we use the context to handle that.
  useEffect(() => {
    const isDataFiltered = isAtLeastOneFilterApplied();
    setIsDataFiltered(isDataFiltered);
  }, [checkedCategories, searchedLabel, startDateRange, endDateRange, amountRange]);

  // Re-painting rows on every formattedData change
  useEffect(() => {
    const newRows = getRows(formattedData);
    setRows(newRows);
  }, [formattedData]);

  const columns = getSummariesShareDataColumns(isAnonymizedData);

  const containerRef = useRef(null);
  return (
    <div ref={containerRef}>
      <Popper
        placement="bottom"
        sx={{
          top: '10px !important'
        }}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: containerRef?.current
            }
          }
        ]}
        disablePortal
        style={{zIndex: 9999}}
        open={filtersModalOpenedId !== null}
        anchorEl={filtersAnchorEl}
      >
        <Paper
          elevation={2}
          style={{
            overflow: 'scroll',
            maxWidth: 350,
            maxHeight: '70vh'
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setFiltersAnchorEl(null);
              setFiltersModalOpenedId(null);
            }}
          >
            <Box sx={{p: 1}}> {renderFiltersModal()}</Box>
          </ClickAwayListener>
        </Paper>
      </Popper>

      <Grid container justifyContent="flex-end" my={1.5}>
        <Button
          disabled={!isAtLeastOneFilterApplied()}
          startIcon={<FilterListOffIcon />}
          variant="outlined"
          size="small"
          color="error"
          onClick={() => {
            setCheckedThemes([]);
            setCheckedCategories([]);
            setAmountRange([lowestAmount, highestAmount]);
            setSearchedLabel('');
            setStartDateRange(null);
            setEndDateRange(null);
          }}
        >
          Réinitialiser les filtres
        </Button>
      </Grid>

      <BaseReactGrid
        columnIdThatShouldTakeAvailableSpace={isAnonymizedData ? null : SUMMARIES_SHARE_DATA_SPREADSHEET_COLUMNS_NAMES.label}
        containerClassName="centered-spreadsheet"
        onCellsChanged={handleChanges}
        rows={rows}
        columns={columns}
        customCellTemplates={{
          deleteRow: new DeleteCellTemplate(),
          tooltipTextCell: new TooltipTextCellTemplate(),
          headerCell: new HeaderCell()
        }}
      />
    </div>
  );
};

SummaryDataSpreadsheet.propTypes = {
  isAnonymizedData: bool.isRequired
};

export default memo(SummaryDataSpreadsheet);
