import {useEffect, useState} from 'react';

import {TabContext} from '@mui/lab';
import {DialogActions, DialogContent, Grid} from '@mui/material';
import Box from '@mui/material/Box';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import StyledTab from '../../tabs/StyledTab';
import StyledTabs from '../../tabs/StyledTabs';
import SummaryDataSpreadsheet from './SummaryDataSpreadsheet';

const MODAL_TABS = {
  default: 'default',
  custom: 'custom'
};

const SummarySharedDataModal = () => {
  const {
    dataToShare,
    summariesLoading,
    shouldShareCustomData,
    setShouldShareCustomData,
    isSummaryShareDataModalOpen: isOpen,
    setIsSummaryShareDataModalOpen: setIsOpen,
    filteredDataToShare,
    setFilteredDataToShare
  } = useReportSummary();

  const [tabSelected, setTabSelected] = useState(MODAL_TABS.default);

  const isCustomTab = tabSelected === MODAL_TABS.custom;

  const handleTabChange = () => {
    setTabSelected(currentTab => (currentTab === MODAL_TABS.custom ? MODAL_TABS.default : MODAL_TABS.custom));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChoseDataTypeButtonClick = () => {
    setShouldShareCustomData(isCustomTab);
    closeModal();
  };

  // This hook aims to re-set the current selected data type on modal open.
  useEffect(() => {
    if (!isOpen) {
      setTabSelected(shouldShareCustomData ? MODAL_TABS.custom : MODAL_TABS.default);
    }
  }, [isOpen]);

  return (
    <BaseModal overridesPaperProps={{height: '100%'}} shouldMatchEmbedReportWidth maxWidth={false} withoutTitle onClose={closeModal} open={isOpen} canCloseOnBackdropClick={false}>
      <TabContext value={tabSelected}>
        <StyledTabs onChange={handleTabChange} scrollButtons={false} aria-label="Onglets plan général ou plan personnalisé">
          <StyledTab label="Plan général" value={MODAL_TABS.default} />

          <StyledTab disabled label="Plan personnalisé" value={MODAL_TABS.custom} />
        </StyledTabs>
      </TabContext>

      <DialogContent
        sx={{
          px: 3,
          py: 2,
          height: '100%'
        }}
      >
        {summariesLoading ? <Spinner isLoading text="Chargement des données" /> : <SummaryDataSpreadsheet isAnonymizedData={isCustomTab} />}
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: 'column',
          pt: 1,
          width: {
            xs: '100%'
          }
        }}
      >
        <Grid container width="auto" mr={2} alignItems="center" flexWrap="nowrap">
          <Button disabled={filteredDataToShare.length === 0} onClick={handleChoseDataTypeButtonClick} variant="contained" color="secondary">
            Partager les données
          </Button>
          <Box ml={1}>
            <Button
              onClick={() => {
                closeModal();
                setFilteredDataToShare(dataToShare);
              }}
              variant="outlined"
              color="error"
            >
              Annuler
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </BaseModal>
  );
};

export default SummarySharedDataModal;
